import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { PavisFilterLookupModule } from 'src/app/components/lookups/pavis-filter-lookup/pavis-filter-lookup.component';
import { TitleBarModule } from 'src/app/components';
import { RenewalService } from 'src/app/services/renewal/renewal.service';
import DataSource from 'devextreme/data/data_source';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import { HttpErrorResponse } from '@angular/common/http';
import { RenewalPavisInvoiceDetailsImportXmlModule } from 'src/app/components/renewal/renewal-pavis/renewal-pavis-invoice-details-import-xml/renewal-pavis-invoice-details-import-xml.component';

@Component({
  selector: 'renewal-pavis-invoice-details',
  templateUrl: './renewal-pavis-invoice-details.component.html',
  styleUrls: ['./renewal-pavis-invoice-details.component.scss'],
})
export class RenewalPavisInvoiceDetailsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  isImportXmlPopupVisible = false;

  constructor(private renewalService: RenewalService) {
    this.url = this.renewalService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'PavisInvoiceDetailId',
        loadUrl: `${this.url}/Pavis/Invoice/Detail`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  importXml = () => {
    this.isImportXmlPopupVisible = true;
  };

  onClosedImportXmlPopup = (e) => {
    this.isImportXmlPopupVisible = false;
  };

  onImportXmlPopup = (document) => {
    if (document) {
      const formData = new FormData();
      formData.append('PavisXmlFile', document);

      this.renewalService.importXml(formData).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.isImportXmlPopupVisible = false;
          this.refreshData();
        },
      });
    }
  };

  processInvoice = () => {
    this.renewalService.processInvoice().subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });
  };

  unloadXml = () => {
    this.renewalService.unloadXml().subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });
  };

  hasDelta = (data) => {
    const delta = data.row.data.Delta;
    const isDeltaAdded = data.row.data.AddDifferenceToCharges;

    if (delta != 0 && isDeltaAdded == false) {
      return true;
    }

    return false;
  };

  onCellPrepared(cell: CellPreparedEvent) {
    if (cell.rowType === 'data') {
      switch (cell.column.dataField) {
        case 'Delta':
          const chargedAmount = cell.data.ChargedAmount;
          if (chargedAmount == null) {
            cell.cellElement.style.backgroundColor = 'lightgray';
          } else {
            const delta = cell.data.Delta;
            if (delta < -20) {
              cell.cellElement.style.backgroundColor = 'lightcoral';
            } else if (Math.abs(delta) <= 20) {
              cell.cellElement.style.backgroundColor = 'lightgreen';
            } else {
              cell.cellElement.style.backgroundColor = 'lightorange';
            }
          }
          break;

        case 'CurrentPxWfId':
          const match = cell.data.Match;
          if (match == 1) {
            cell.cellElement.style.backgroundColor = 'lightgreen';
          } else {
            cell.cellElement.style.backgroundColor = 'lightcoral';
          }
          break;
      }
    }
  }

  onAddDelta = (e) => {
    const data = e.row.data;
    this.renewalService
      .flagAsAddDelta(data.PavisInvoiceDetailId, true)
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    PavisFilterLookupModule,
    RenewalPavisInvoiceDetailsImportXmlModule,
  ],
  declarations: [RenewalPavisInvoiceDetailsComponent],
  exports: [],
})
export class RenewalPavisInvoiceDetailsModule {}

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#DetailForm' }"
  [(visible)]="reLoadingParameters"
  [showIndicator]="true"
  [showPane]="true"
  [hideOnOutsideClick]="false"
/>

<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    [disabled]="readonly"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    [remoteOperations]="true"
    height="30vh"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserted)="onRowInserted($event)"
    (onRowUpdated)="onRowUpdated($event)"
    (onContentReady)="onContentReady($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onEditingStart)="onEditingStart($event)"
    [repaintChangesOnly]="true"
    [wordWrapEnabled]="true"
  >
    <dxo-toolbar>
      <dxi-item
        location="after"
        locateInMenu="never"
        name="addRowButton"
        [options]="{
          elementAttr: { class: 'add-button' },
        }"
      >
      </dxi-item>
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column
      dataField="CaseRecordId"
      caption="Case"
      [setCellValue]="onCaseChanged"
    >
      <dxo-lookup
        valueExpr="CaseId"
        displayExpr="CaseDetailedDescription"
        [dataSource]="cases.store()"
      />
    </dxi-column>
    <dxi-column dataField="WorkedOn" dataType="date" width="10%" />
    <dxi-column
      dataField="BillingType"
      caption="Billing Type"
      [setCellValue]="onBillingTypeChanged"
      width="10%"
    >
      <dxo-lookup
        valueExpr="BillingTypeId"
        displayExpr="BillingTypeLabel"
        [dataSource]="billingTypes.store()"
      />
    </dxi-column>
    <dxi-column dataField="Description" />
    <dxi-column dataField="Hours" [setCellValue]="onHoursChanged" width="6%" />
    <dxi-column
      dataField="Minutes"
      [setCellValue]="onMinutesChanged"
      width="8%"
    />
    <dxi-column
      dataField="TotalAmount"
      [setCellValue]="onTotalAmountChanged"
      width="8%"
      format="currency"
    />
    <dxi-column
      dataField="HourlyRate"
      [visible]="true"
      [setCellValue]="onHourlyRateChanged"
      width="6%"
      format="currency"
    />
    <dxi-column
      dataField="WorkCodeId"
      [visible]="false"
      [setCellValue]="onWorkCodeSelectionChanged"
      width="6%"
    />

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="allowAdd"
      [allowDeleting]="true"
    >
      <dxo-popup title="Registration Detail" [showTitle]="true" [height]="650">
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form id="DetailForm">
        <dxi-item itemType="group" [colCount]="4" [colSpan]="2">
          <dxi-item itemType="group" [colCount]="1" [colSpan]="4">
            <dxi-item dataField="WorkedOn" />
            <dxi-item
              dataField="CaseRecordId"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: cases,
                displayExpr: 'CaseDetailedDescription',
                valueExpr: 'CaseId',
                searchEnabled: true,
              }"
            >
              <dxi-validation-rule type="required" />
              <dxo-label text="Case" />
            </dxi-item>
            <dxi-item
              editorType="dxButton"
              [disabled]="!selectedCaseId"
              [editorOptions]="{
                text: 'See related charges',
                stylingMode: 'contained',
                style: 'background-color: rgb(255, 100, 044); color: azure',
                onClick: openRelatedChargesPopup
              }"
            />
            <dxi-item
              dataField="BillingType"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: billingTypes,
                displayExpr: 'BillingTypeLabel',
                valueExpr: 'BillingTypeId',
              }"
            >
              <dxi-validation-rule type="required" />
              <dxo-label text="Billing Type" />
            </dxi-item>
            <dxi-item
              dataField="WorkCodeId"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: workcodes,
                displayExpr: 'WorkCodeFullDescription',
                valueExpr: 'WorkCodeId',
                searchMode: 'contains',
                searchExpr: 'WorkCodeFullDescription',
                searchEnabled: true,
                minsSearchLength: 2,
                showDataBeforeSearch: true,
              }"
            >
              <dxi-validation-rule type="required" />
              <dxo-label text="Workcode" />
            </dxi-item>
            <dxi-item dataField="Description">
              <dxi-validation-rule type="required" />
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
              <dxi-item
                dataField="Hours"
                editorType="dxNumberBox"
                [editorOptions]="{
                  format: { type: 'fixedPoint' }
                }"
              />
              <dxi-item
                dataField="Minutes"
                editorType="dxNumberBox"
                [editorOptions]="{
                  format: { type: 'fixedPoint' }
                }"
              >
                <dxi-validation-rule type="required" />
              </dxi-item>
              <dxi-item
                dataField="HourlyRate"
                caption="Hourly Rate"
                editorType="dxNumberBox"
              >
                <dxi-validation-rule type="required" />
              </dxi-item>
              <dxi-item
                dataField="TotalAmount"
                caption="Total Amount"
                editorType="dxNumberBox"
              >
                <dxi-validation-rule type="required" />
              </dxi-item>
            </dxi-item>
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridRegistrationDetailsForm"
    />
  </dx-data-grid>

  <time-registration-charge-by-case-grid
    [caseId]="selectedCaseId"
    [visible]="relatedChargesPopupVisible"
    (visibleChange)="onRelatedChargesPopupVisibleChange($event)"
  />
</div>

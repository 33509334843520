<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    class="grid"
    height="75vh"
    [dataSource]="dataSource"
    [showBorders]="true"
    [remoteOperations]="true"
    (onExporting)="onExporting($event)"
    (onInitialized)="onInitialized($event)"
    (onRowPrepared)="onRowPrepared($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-load-panel [enabled]="true" />
    <dxo-sorting mode="multiple" />
    <dxo-header-filter [visible]="true" />
    <dxo-filter-row [visible]="true" />
    <dxo-selection mode="multiple" />
    <dxo-column-chooser [enabled]="true" />
    <dxo-search-panel [visible]="true" width="20vw" />
    <dxo-export
      [enabled]="true"
      [fileName]="fileExportName"
      [allowExportSelectedData]="true"
    />
    <dxo-toolbar>
      <dxi-item
        location="before"
        widget="dxLookup"
        locateInMenu="never"
        [options]="{
          width: 'auto',
          text: 'Grid',
          stylingMode: 'contained',
          items: gridTypes,
          value: gridType.key,
          valueExpr: 'key',
          displayExpr: 'value',
          onItemClick: onChooseGridType
        }"
      />
      <dxi-item
        location="before"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          width: 'auto',
          text: 'Check',
          stylingMode: 'contained',
          onClick: checkData
        }"
      />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
      <dxi-item
        location="after"
        name="columnChooserButton"
        locateInMenu="auto"
      />
      <dxi-item location="after" name="exportButton" locateInMenu="auto" />
      <dxi-item location="after" name="searchPanel" locateInMenu="never" />
    </dxo-toolbar>
    <dxo-paging [pageSize]="14" />
    <dxo-pager
      [visible]="true"
      displayMode="adaptive"
      [showPageSizeSelector]="false"
      [showInfo]="true"
      [showNavigationButtons]="true"
    />
  </dx-data-grid>

  <data-quality-check-validation
    [(visible)]="confirmationPopupVisible"
    [criteria]="gridType.value"
    [selectedLines]="selectedLines()"
  />
</div>

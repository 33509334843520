import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { CaseService } from 'src/app/services/case/case.service';
import { WorkcodeService } from 'src/app/services/invoicing/workcode/workcode.service';
import { VatService } from 'src/app/services/vat.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import {
  InitNewRowEvent,
  RowInsertedEvent,
  RowRemovedEvent,
  RowUpdatedEvent,
  RowUpdatingEvent,
  RowInsertingEvent,
} from 'devextreme/ui/data_grid';
import { Costline } from 'src/app/types/cost/costline';

@Component({
  selector: 'app-invoices-in-details',
  templateUrl: './invoices-in-details.component.html',
  styleUrls: ['./invoices-in-details.component.scss'],
})
export class InvoicesInDetailsComponent implements OnInit, OnChanges {
  // Input & Output Parameters
  @Input() CostHeaderId: number = null;
  @Input() CostHeader: any;

  @Output() CostLineDetailOutput = new EventEmitter<Costline[]>();

  CostLineDetail: Costline[] = [];

  url = environment.CalystaApiBaseURL + 'api/';

  //Lookups
  caseLookup: any = null;
  employeeLookup: any = null;
  costItemCategoryLookup: any = null;
  workcodeLookup: any = null;
  currencyLookup: any = null;
  vatSelectList: any = null;

  // Parameters
  clientLanguageId: number = 3;

  // DataSources
  detailDataSource: any = null; // The datasource for the detail datagrid.

  constructor(
    private VatSvc: VatService,
    private WorkCodeSvc: WorkcodeService,
    private CaseSvc: CaseService
  ) {
    this.onCaseChanged = this.onCaseChanged.bind(this);
    this.onWorkcodeChanged = this.onWorkcodeChanged.bind(this);
    this.onNonVatAmountChanged = this.onNonVatAmountChanged.bind(this);
    this.onVatRateChanged = this.onVatRateChanged.bind(this);
    this.onRowUpdated = this.onRowUpdated.bind(this);
    this.onRowInserted = this.onRowInserted.bind(this);
    this.onRowRemoved = this.onRowRemoved.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.CostHeaderId && changes.CostHeaderId.currentValue !== null) {
      this.detailDataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'CostLineId',
          loadUrl: `${this.url}Costs/CostLine/${this.CostHeaderId}`,
          insertUrl: `${this.url}Costs/CostLine`,
          updateUrl: `${this.url}Costs/CostLine`,
          deleteUrl: `${this.url}Costs/CostLine`,
          onBeforeSend: (method: string, ajaxOptions: any) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            if (this.CostHeaderId) {
              ajaxOptions.data.CostHeaderId = this.CostHeaderId;
            }
          },
        }),
      });
    } else {
      this.detailDataSource = new DataSource({
        store: this.CostLineDetail,
      });
    }
  }

  ngOnInit() {
    this.loadAllLookups();
  }

  loadAllLookups() {
    this.workcodeLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
      //filter out where workcodelabel start with SC
      filter: ['!', ['WorkCodeLabel', 'startswith', 'SC']],
    });

    this.employeeLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${this.url}Employee/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });

    this.costItemCategoryLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'CostItemId',
        loadUrl: `${this.url}Costs/CostItem/lookup`,
      }),
      sort: 'Label',
    });

    this.caseLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/Lookup`,
      }),
      sort: ['CaseDetailedDescription'],
    });

    this.currencyLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'currencyId',
        loadUrl: `${this.url}Currency/lookup`,
      }),
      sort: 'CurrencyLabel',
    });

    this.vatSelectList = this.VatSvc.getVATPercentageList();
  }

  // Fired before the creation of a new row in the detail datagrid.
  // Here we can set initial values when required.
  onInitNewRow(e: InitNewRowEvent) {
    e.data.NonVatAmount = 0;
    e.data.VatAmount = 0;
    e.data.Amount = 0;
    if (this.CostHeader != null) {
      e.data.BudgetPeriodStart = this.CostHeader.BudgetPeriodStart;
      e.data.BudgetPeriodEnd = this.CostHeader.BudgetPeriodEnd;
      e.data.EmployeeId = this.CostHeader.EmployeeId;
      e.data.CaseId = this.CostHeader.CaseId;
      e.data.CostItemId = this.CostHeader.CostItemId;

      this.getCaseClientLanguagePromise(this.CostHeader.CaseId).then((data) => {
        this.clientLanguageId = data;
      });
    }
  }

  // In case there is no costheaderId yet, we have to send it to the parent component.
  onRowUpdated(e: RowUpdatedEvent) {
    if (this.CostHeaderId == null) {
      this.CostLineDetailOutput.emit(this.CostLineDetail);
    } else if (this.CostHeaderId != null) {
      this.CostLineDetailOutput.emit(this.detailDataSource.items());
    }
  }

  // In case there is no costheaderid, emit the temporary array, otherwise the datasource items.
  onRowInserted(e: RowInsertedEvent) {
    if (this.CostHeaderId == null) {
      this.CostLineDetailOutput.emit(this.CostLineDetail);
    } else if (this.CostHeaderId != null) {
      this.CostLineDetailOutput.emit(this.detailDataSource.items());
    }
  }

  onRowUpdating(e: RowUpdatingEvent) {
    e.newData = { ...e.oldData, ...e.newData };

    //ensure e.newData.BudgetPeriodStart and e.newData.BudgetPeriodEnd are in date only without time
    if (
      e.newData.BudgetPeriodStart != null &&
      e.newData.BudgetPeriodEnd != null
    ) {
      e.newData.BudgetPeriodStart = new Date(e.newData.BudgetPeriodStart)
        .toISOString()
        .split('T')[0];
      e.newData.BudgetPeriodEnd = new Date(e.newData.BudgetPeriodEnd)
        .toISOString()
        .split('T')[0];
    }
  }

  onRowInserting(e: RowInsertingEvent) {
    //ensure e.newData.BudgetPeriodStart and e.newData.BudgetPeriodEnd are in date only without time
    if (e.data.BudgetPeriodStart != null && e.data.BudgetPeriodEnd != null) {
      e.data.BudgetPeriodStart = new Date(e.data.BudgetPeriodStart)
        .toISOString()
        .split('T')[0];
      e.data.BudgetPeriodEnd = new Date(e.data.BudgetPeriodEnd)
        .toISOString()
        .split('T')[0];
    }
  }

  onRowRemoved($event: RowRemovedEvent) {
    if (this.CostHeaderId == null) {
      this.CostLineDetailOutput.emit(this.CostLineDetail);
    } else if (this.CostHeaderId != null) {
      this.CostLineDetailOutput.emit(this.detailDataSource.items());
    }
  }

  // Called when the Non Vat Amount changed in order to update all the other fields as well.
  onNonVatAmountChanged(newData, value, currentRowData) {
    newData.NonVatAmount = value;
    if (currentRowData.VatRate != null) {
      let TotalAmount: number = (+currentRowData.VatRate / 100 + 1) * value;
      let VatAmount: number = TotalAmount - value;
      let VatAmountRounded: number =
        +this.VatSvc.roundToNearestFiveCents(VatAmount).toFixed(2);

      newData.Amount = TotalAmount;
      newData.VatAmount = VatAmountRounded;
    }
  }

  // When the case changes, get the language of the client so that we can get the right workcode descriptions.
  onCaseChanged(newData, value, currentRowData) {
    newData.CaseId = value;

    var selectedCase = this.caseLookup.items().find((x) => x.CaseId == value);

    if (selectedCase == null) {
      this.CaseSvc.getCaseById(value).subscribe((data) => {
        newData.CaseNumber = data.CaseNumber;
      });
    } else {
      newData.CaseNumber = selectedCase.CaseNumber;
    }

    if (value) {
      this.getCaseClientLanguagePromise(value).then((data) => {
        this.clientLanguageId = data;
      });
    }
  }

  // Get's the language of the client currently on the case.
  getCaseClientLanguagePromise(CaseId): Promise<number> {
    return new Promise((resolve) => {
      this.CaseSvc.getAccountAddressLanguage(CaseId).subscribe((data) => {
        resolve(data.LanguageId);
      });
    });
  }

  // When the VAT rate changes adapt the amounts.
  onVatRateChanged(newData, value, currentRowData) {
    newData.VatRate = value;

    if (currentRowData.NonVatAmount != null) {
      let TotalAmount: number =
        (+value / 100 + 1) * currentRowData.NonVatAmount;
      let VatAmount: number = TotalAmount - currentRowData.NonVatAmount;
      let VatAmountRounded: number =
        +this.VatSvc.roundToNearestFiveCents(VatAmount).toFixed(2);
      newData.Amount = TotalAmount;
      newData.VatAmount = VatAmountRounded;
    }
  }

  onWorkcodeChanged(newData, value, currentRowData) {
    return this.getWorkCodeDescriptionPromise(value).then((data: any) => {
      newData.WorkCodeId = value;
      newData.WorkCodeLabel = data.WorkCodeLabel;
      newData.LineDescription = data.WorkCodeDescription;
    });
  }

  getWorkCodeDescriptionPromise(WorkCodeId) {
    return new Promise((resolve) => {
      this.WorkCodeSvc.getWorkCode(WorkCodeId, this.clientLanguageId).subscribe(
        (data) => {
          resolve({
            WorkCodeDescription: data.InvoiceDescription,
            WorkCodeLabel: data.WorkCodeLabel,
          });
        }
      );
    });
  }
}

@NgModule({
  imports: [CommonModule, DxDataGridModule],
  providers: [],
  declarations: [InvoicesInDetailsComponent],
  exports: [InvoicesInDetailsComponent],
})
export class InvoicesInDetailsModule {}

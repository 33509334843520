import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateRangeBoxModule,
  DxDropDownButtonModule,
  DxNumberBoxModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { CostService } from 'src/app/services/financial/cost/cost.service';
import { RangeDatePickerModule } from 'src/app/components/utils/range-date-picker/range-date-picker.component';
import { TitleBarModule } from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

@Component({
  selector: 'finance-reporting-cost-overview',
  templateUrl: './finance-reporting-cost-overview.component.html',
  styleUrls: ['./finance-reporting-cost-overview.component.scss'],
})
export class FinanceReportingCostOverviewComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  thisYear = new Date(new Date().getFullYear(), 0, 1);
  now = new Date();

  constructor(private costService: CostService) {
    this.url = this.costService.getUrl();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData = (filter: any = []) => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/costoverview`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (this.thisYear && this.now) {
            ajaxOptions.data.startDate = this.thisYear.toISOString();
            ajaxOptions.data.endDate = this.now.toISOString();
          }
        },
      }),
      filter: filter,
    });
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onDateChanged = (e: any): void => {
    this.thisYear = e.value[0];
    this.now = e.value[1];

    this.dataGrid.instance.getDataSource().reload();
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Cost Overview');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'CostOverview.xlsx'
        );
      });
    });
  }

  onInvoiceValueChanged = (e) => {
    const filter: any[] = [];
    const value = e.value;

    if (value) {
      filter.push(['InvoiceId', '=', value]);
    }

    this.loadData(filter);
  };

  onLastInvoiceNumberValueChanged = (e) => {
    const filter: any[] = [];
    const value = e.value;

    if (value) {
      filter.push(['InvoiceId', '>=', value]);
    }

    this.loadData(filter);
  };
}

@NgModule({
  declarations: [FinanceReportingCostOverviewComponent],
  exports: [],
  providers: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    DxCheckBoxModule,
    TitleBarModule,
    DxScrollViewModule,
    RangeDatePickerModule,
    DxDateRangeBoxModule,
    DxNumberBoxModule,
  ],
})
export class TimeRegistrationRegisterModule {}

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DxChartComponent, DxChartModule } from 'devextreme-angular';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';

@Component({
  selector: 'encoded-time-graph',
  templateUrl: './encoded-time-graph.component.html',
  styleUrls: ['./encoded-time-graph.component.scss']
})
export class EncodedTimeGraphComponent implements OnInit, OnChanges {
  @ViewChild('EncodedTrackChart') chart: DxChartComponent;

  @Input() EmployeeId: number;
  @Input() StartDate: Date;
  @Input() EndDate: Date;
  @Input() TitlePeriod: string = '';
  @Input() ArgumentField: string = 'Month';

  ChartData: any;

  constructor(private TimeReportingSvc: TimeReportingService) { }

  refreshData() {
    this.chart.instance.showLoadingIndicator();
    this.TimeReportingSvc.getEncodedTimeTrack(
      this.EmployeeId,
      this.getDateOnly(this.StartDate),
      this.getDateOnly(this.EndDate),
    ).subscribe((data) => {
      this.ChartData = data;
    },
    (error) => {
      console.error(error);
    },
    () => {
      this.chart.instance.hideLoadingIndicator();
    });
  }

  getDateOnly(date: Date): string {
    return date.toDateString();
  }

  ngOnInit() {
    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.refreshData();
    }
  }

}

@NgModule({
  declarations: [EncodedTimeGraphComponent],
  exports: [EncodedTimeGraphComponent],
  imports: [CommonModule, DxChartModule],
})
export class EncodedTimeGraphModule {}


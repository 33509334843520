<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <div>
      <dx-toolbar class="toolbar-details theme-dependent">
        <dxi-item location="before">
          <dx-button icon="arrowleft" stylingMode="text" (onClick)="onClickBackButton()"></dx-button>
        </dxi-item>
        <dxi-item *ngIf="BillingMatrix" location="before" text="Billing Matrix: {{ BillingMatrix.Label }}"></dxi-item>
      </dx-toolbar>
    </div>
    <div class="separator"></div>
    <titlebar titleText="General Parameters" />
    <div class="separator"></div>
    <dx-form
      *ngIf="BillingMatrix"
      [formData]="BillingMatrix"
      labelMode="floating"
      [colCount]="2"
      (onFieldDataChanged)="updateGeneralData($event)"
    >
      <dxi-item
        dataField="CaseTypeId"
        editorType="dxLookup"
        [colSpan]="1"
        [editorOptions]="{
          dataSource: CaseTypes,
          displayExpr: 'CaseTypeLabel',
          valueExpr: 'CaseTypeId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Case Type"></dxo-label>
      </dxi-item>
      <dxi-item
        dataField="ServiceId"
        editorType="dxLookup"
        colSpan="1"
        [editorOptions]="{
          dataSource: ServiceTypeLookup,
          displayExpr: 'ServiceTypeLabel',
          valueExpr: 'ServiceTypeId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      ></dxi-item>

      <dxi-item
        dataField="Label"
        editorType="dxTextBox"
        editorOptions="{placeholder: 'Label'}"
        [colSpan]="1"
      >
        <dxo-label text="Description"></dxo-label>
      </dxi-item>
      <dxi-item [colSpan]="1"></dxi-item>
      <dxi-item
        dataField="ClassSelectionRequired"
        [colSpan]="1"
        [editorType]="'dxCheckBox'"
      >
        <dxo-label text="Class Selection Required?"></dxo-label>
      </dxi-item>
      <dxi-item
        dataField="DesignationSelectionRequired"
        [colSpan]="1"
        [editorType]="'dxCheckBox'"
      >
        <dxo-label text="Designation Selection Required?"></dxo-label>
      </dxi-item>
    </dx-form>

    <titlebar titleText="Workcodes" />
    <div class="separator"></div>
    <dx-data-grid
      *ngIf="BillingMatrix.CaseTypeId === 4"
      [dataSource]="BillingMatrix.BillingMatrixWorkCodeLines"
      (onRowInserted)="onRowInserted($event)"
      (onRowUpdated)="onRowUpdated($event)"
      (onRowRemoved)="onRowRemoved($event)"
      keyExpr="Id"
    >
      <dxo-editing
        mode="row"
        [allowUpdating]="true"
        [allowDeleting]="true"
        [allowAdding]="true"
      ></dxo-editing>
      <dxi-column
        dataField="WorkCodeId"
        caption="Work Code"
        calculateDisplayValue="WorkCodeLabel"
      >
        <dxo-lookup
          [dataSource]="WorkCodeLookup.store()"
          displayExpr="WorkCodeLabel"
          valueExpr="WorkCodeId"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column
        dataField="WorkCodeDescription"
        caption="Description"
        allowEditing="false"
      ></dxi-column>
      <dxi-column dataField="IsOptional" caption="Optional"></dxi-column>
      <dxi-column
        dataField="TRMIsIndividual"
        caption="Individual"
        dataType="boolean"
      ></dxi-column>
      <dxi-column
        dataField="TRMIsCollective"
        caption="Collective"
        dataType="boolean"
      ></dxi-column>
      <dxi-column
        dataField="TRMClassFrom"
        caption="From Class"
        dataType="number"
      ></dxi-column>
      <dxi-column
        dataField="TRMClassTo"
        caption="To Class"
        dataType="number"
      ></dxi-column>
      <dxi-column
        dataField="TRMMultiplyByClass"
        caption="Multiply by Class"
        dataType="boolean"
      ></dxi-column>
    </dx-data-grid>
  </div>
</dx-scroll-view>

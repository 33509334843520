import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface FeeRequest {
  ClientId: number;
  ProspectId: number;
  ServiceId: number;
  SubsidyId: number;
  ExistingCases: {
    CaseIds: number[];
    ServiceId: number;
    SubsidyId: number;
  }[];
  NewCases: {
    CaseTypeId: number;
    ApplicationTypeId: number;
    ServiceLevelId: number;
    CountryId: string;
    TrademarkTypeId: number;
    ServiceId: number;
    SubsidyId: number;
  }[];
}

interface ServiceRequest {
  ServiceTypeId: number;
  CaseTypeId: number;
  StateId: string;
}

interface calcQuoteLineRequest {
  ServiceParameters: ServiceParameters;
  BillingMatrixId: number;
  ClientId: number;
  LanguageId: number;
}

interface ServiceParameters {
  ServiceTypeId: number;
  CaseTypeId: number;
  StateId: string;
  NrOfClasses: number;
  DesignatedStates: string[];
}

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class FeeCalculatorService {
  url = apiUrl + 'api/feecalculator';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  CalculateFee(request: FeeRequest) {
    return this.http.post<any>(`${this.url}/CalculateFee`, request);
  }

  DetermineBillingMatrix(request: ServiceRequest) {
    let params = new HttpParams()
      .set('ServiceTypeId', request.ServiceTypeId)
      .set('CaseTypeId', request.CaseTypeId)
      .set('StateId', request.StateId);
    return this.http.get<any>(`${this.url}/BillingMatrix/Determine`, {
      params,
    });
  }

  CreateNewQuote() {
    return this.http.post<any>(`${this.url}/Quote`, {});
  }

  CalculateQuoteLine(request: calcQuoteLineRequest) {
    return this.http.post<any>(`${this.url}/Quote/Create`, request);
  }

  CalculateWorkCodeQuoteLine(request: calcQuoteLineRequest) {
    return this.http.post<any>(`${this.url}/Quote/WorkCodeLines`, request);
  }

  GetQuote(quoteId: number) {
    return this.http.get<any>(`${this.url}/Quote/${quoteId}`);
  }

  UpdateQuote(quote: any) {
    return this.http.put<any>(`${this.url}/Quote`, quote);
  }

  CreateNewQuoteLine(
    quoteId: number,
    caseTypeId: number,
    serviceTypeId: number,
    coverageId: number,
    billingMatrixId: number,
    nbrOfClasses: number,
    designatedCountries: string[],
    workCodeLines: any[]
  ) {
    return this.http.post<any>(`${this.url}/Quote/QuoteLine`, {
      CaseTypeId: caseTypeId,
      ServiceTypeId: serviceTypeId,
      CoverageId: coverageId,
      QuoteId: quoteId,
      BillingMatrixId: billingMatrixId,
      NbrOfClasses: nbrOfClasses,
      DesignatedCountryIds: designatedCountries,
      QuoteWorkCodeLines: workCodeLines,
    });
  }

  UpdateQuoteLine(
    quoteLineId: number,
    caseTypeId: number,
    serviceTypeId: number,
    coverageId: number,
    billingMatrixId: number,
    nbrOfClasses: number,
    designatedCountries: string[],
    workCodeLines: any[]
  ) {
    return this.http.put<any>(`${this.url}/Quote/QuoteLine`, {
      CaseTypeId: caseTypeId,
      ServiceTypeId: serviceTypeId,
      CoverageId: coverageId,
      QuoteLineId: quoteLineId,
      NbrOfClasses: nbrOfClasses,
      BillingMatrixId: billingMatrixId,
      DesignatedCountryIds: designatedCountries,
      QuoteWorkCodeLines: workCodeLines,
    });
  }
}

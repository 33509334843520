import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxLoadPanelModule,
  DxScrollViewModule,
  DxTemplateModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { DxoSearchPanelModule } from 'devextreme-angular/ui/nested';
import { TitleBarModule } from 'src/app/components';
import { ApplyPipeModule } from 'src/app/pipes/apply.pipe';
import { GraphAPIProfileService } from 'src/app/services/graphapi/graphapi-profile.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { RegistrationService } from 'src/app/services/time-registration/registration.service';
import DataSource from 'devextreme/data/data_source';
import { ValueChangedEvent } from 'devextreme/ui/check_box';
import { environment } from 'src/environments/environment';
import { InitNewRowEvent } from 'devextreme/ui/data_grid';
import { TimeRegistrationRegistrationDetailModule } from './time-registration-registration-detail/time-registration-registration-detail.component';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { RegistrationDetail } from 'src/app/types/timeregistration/RegistrationDetail';
import { RegistrationDetailsFormModule } from 'src/app/components/time-registration/registration-details-form/registration-details-form.component';
import { TimeRegistrationRegisterNewModule } from 'src/app/components/time-registration/time-registration-register-new/time-registration-register-new.component';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';

@Component({
  templateUrl: './time-registration-register.component.html',
  styleUrls: ['./time-registration-register.component.scss'],
  providers: [GraphAPIProfileService],
})
export class TimeRegistrationRegisterComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  isLoading: boolean = false;

  dataSource: DataSource;

  cases: DataSource;

  employees: any;

  showProcessed: boolean = false;

  url: string;

  isAddPopupOpened: boolean = false;

  isEditPopupOpened: boolean = false;

  editedRow: any;

  details: RegistrationDetail[] = [];

  RegistrationId: number = null;

  currentEmployeeId: number = null;
  myEmployeeId: number = null;

  constructor(
    private registrationService: RegistrationService,
    private employeeSvc: EmployeeService
  ) {
    this.url = this.registrationService.getUrl();
    this.onEmployeeChanged = this.onEmployeeChanged.bind(this);
    this.onRowInserted = this.onRowInserted.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RegistrationId',
        loadUrl: `${this.url}/Registration`,
        insertUrl: `${this.url}/Registration`,
        updateUrl: `${this.url}/Registration`,
        deleteUrl: `${this.url}/Registration`,
        onBeforeSend: (method: string, ajaxOptions: any) => {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (method === 'load') {
            ajaxOptions.data.ShowProcessed = this.showProcessed;
          }
        },
      }),
    });

    this.employeeSvc.getLookup(true).subscribe((data) => {
      //order by fullname
      this.employees = data.sort((a, b) => {
        return a.FullName.localeCompare(b.FullName);
      });
    });

    this.employeeSvc.getEmployee().subscribe((employee) => {
      if (employee) {
        this.myEmployeeId = employee.EmployeeId;
        this.currentEmployeeId = employee.EmployeeId;
      } else {
        this.employeeSvc.getMyself().subscribe((result: any) => {
          this.myEmployeeId = result.EmployeeId;
          this.currentEmployeeId = result.EmployeeId;
        });
      }
    });

    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseDetailedDescription',
    });
  }

  // Fired when the pop up is created to add a new row.
  // We set the RegistrationId to null because the registrationId is not known yet.
  onInitNewRow(e: InitNewRowEvent) {
    this.RegistrationId = null;
    e.data.EmployeeId = this.myEmployeeId;
    this.currentEmployeeId = this.myEmployeeId;
    e.data.RegisteredOn = new Date();
  }

  // Fired when we start the editing of a row.
  onEditingStart(e: any) {
    if (e.key) {
      this.RegistrationId = e.key;
    }
  }

  onSaveClickNew(e: { registration: any; detailsList: any }): void {
    (this.dataSource.store().insert(e.registration) as any).done((data) => {
      if (e.detailsList.length > 0) {
        const insertPromises = e.detailsList.map((d) =>
          this.registrationService
            .insertDetails(d, data.RegistrationId)
            .toPromise()
        );
        Promise.all(insertPromises)
          .then(() => {
            this.refreshData();
          })
          .catch((err) => {
            const errorMessage: string | null =
              err?.error?.Errors?.GeneralErrors[0];
            notify(errorMessage ?? 'Unknown error', 'error', 3000);
          });
      } else {
        this.refreshData();
      }
    });
  }

  onEmployeeChanged(newData, value, currentRowData) {
    newData.EmployeeId = value;
    this.currentEmployeeId = value;
  }

  onSaveClickEdit(e: any): void {
    this.dataSource
      .store()
      .update(e.registration.RegistrationId, this.editedRow);

    this.dataGrid.instance.refresh();
  }

  onRowInserted(e) {
    this.details.forEach((item) => {
      this.registrationService.insertDetails(item, e.key).subscribe((data) => {
        this.refreshData();
      });
    });
  }

  addNew() {
    this.isAddPopupOpened = true;
  }

  onEdit = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isEditPopupOpened = true;
  };

  onCloseAdd = (e: boolean) => {
    this.isAddPopupOpened = e;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupOpened = e;
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onClickProcess = () => {
    var registrationIds = this.dataGrid.instance.getSelectedRowKeys();
    if (registrationIds.length == 0) return;

    this.isLoading = true;

    this.registrationService
      .processTimeRegistration(registrationIds)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
  };

  onShowProcessedChanged($event: ValueChangedEvent) {
    this.showProcessed = $event.value;
    this.refreshData();
  }

  redirectToWiki() {
    window.open(
      'https://wiki.calysta.eu/en/TimeRegistration/Overview',
      '_blank'
    );
  }

  handleDetailRows(details: RegistrationDetail[]) {
    this.details = details;
  }

  rowNotProcessed(line: any) {
    return line.row.data.TransferedToCharge === false;
  }
}

@NgModule({
  imports: [
    CommonModule,
    ApplyPipeModule,
    DxScrollViewModule,
    DxDataGridModule,
    DxLoadPanelModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxoSearchPanelModule,
    RegistrationDetailsFormModule,
    TitleBarModule,
    TimeRegistrationRegistrationDetailModule,
    TimeRegistrationRegisterNewModule,
  ],
  providers: [],
  exports: [],
  declarations: [TimeRegistrationRegisterComponent],
})
export class TimeRegistrationRegisterModule {}

import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxListComponent,
  DxListModule,
  DxPopupModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import DataSource from 'devextreme/data/data_source';
import { ApplyPipeModule } from 'src/app/pipes/apply.pipe';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'permissions-edit',
  templateUrl: './permissions-edit.component.html',
  styleUrls: ['./permissions-edit.component.scss'],
})
export class PermissionEditComponent implements OnInit {
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;

  @Input() userId: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Input() width: any;

  @Input() userPermissions: any[] = [];

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  permissions: DataSource;

  backedUpPermissions: any[] = [];

  constructor(private administrationService: AdministrationService) {}

  ngOnInit(): void {
    this.permissions = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}Administration/Permission`,
      }),
      sort: 'Label',
      paginate: false,
    });
  }

  onSelectionChanged(e: any): void {
    if (this.userPermissions.length == 0) {
      if (e.addedItems.length > 0) {
        const addedPermission = e.addedItems[0];

        const userId = this.userId;
        const permissionId = addedPermission.Id;

        this.administrationService
          .assignPermission(userId, permissionId)
          .subscribe({
            next: (x) => {},
            error: (err) => {
              const errorMessage: string | null =
                err?.error?.Errors?.GeneralErrors[0];
              notify(errorMessage ?? 'Unknown error', 'error', 3000);
            },
          });
      } else if (e.removedItems.length > 0) {
        const removedPermission = e.removedItems[0];

        this.administrationService
          .removePermission(this.userId, removedPermission.Id)
          .subscribe({
            next: (x) => {},
            error: (err) => {
              const errorMessage: string | null =
                err?.error?.Errors?.GeneralErrors[0];
              notify(errorMessage ?? 'Unknown error', 'error', 3000);
            },
          });
      }
    }
  }

  close() {
    this.visible = false;
    this.userPermissions = this.backedUpPermissions;
    this.visibleChange.emit(this.visible);
  }

  onShown() {
    //empty the selected permissions
    this.list.instance.unselectAll();

    if (this.userPermissions.length > 0) {
      this.backedUpPermissions = this.userPermissions;
      for (let i = 0; i < this.userPermissions.length; i++) {
        this.list.instance.selectItem(this.userPermissions[i]);
      }

      //empty user permissions
      this.userPermissions = [];
    }
  }
}

@NgModule({
  declarations: [PermissionEditComponent],
  exports: [PermissionEditComponent],
  bootstrap: [PermissionEditComponent],
  imports: [
    ApplyPipeModule,
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxListModule,
    DxPopupModule,
  ],
})
export class PermissionEditModule {}

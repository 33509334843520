<div class="view-wrapper">
  <form>
    <dx-form
      [(formData)]="quote"
      [readOnly]="false"
      [showValidationSummary]="true"
      [showColonAfterLabel]="true"
    >
      <dxi-item itemType="group">
        <dxi-item
          itemType="group"
          caption="Select Client/Prospect"
          [colCount]="2"
        >
          <dxi-item
            dataField="ClientId"
            [colSpan]="1"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: clients.store(),
              displayExpr: 'Name',
              searchEnabled: true,
              validationMessageMode: 'always',
              onValueChanged: onClientChanged
            }"
          >
            <dxo-label text="Client" />
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Address Information" [colCount]="2">
          <dxi-item dataField="Address" editorType="dxTextArea" [colSpan]="2">
            <dxo-label text="Address" />
          </dxi-item>
          <dxi-item dataField="City" editorType="dxTextBox">
            <dxo-label text="City" />
          </dxi-item>
          <dxi-item dataField="ZipCode" editorType="dxTextBox">
            <dxo-label text="Zip" />
          </dxi-item>
          <dxi-item dataField="Email" editorType="dxTextBox">
            <dxo-label text="Email" />
          </dxi-item>
          <dxi-item dataField="Country" editorType="dxTextBox">
            <dxo-label text="Country" />
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Parameters" [colCount]="2">
          <dxi-item dataField="ServiceLevelId">
            <dx-lookup
              [dataSource]="services"
              displayExpr="ServiceLevelLabel"
              valueExpr="ServiceId"
              [searchEnabled]="true"
              [value]="quote?.ServiceLevelId"
              (onValueChanged)="onServiceLevelChanged($event)"
            />
            <dxo-label text="Service Level" />
          </dxi-item>
          <dxi-item dataField="ApplicationSubsidyId" editorType="dxSelectBox">
            <dxo-label text="Applicable subsidies" />
          </dxi-item>
        </dxi-item>
      </dxi-item>
    </dx-form>
  </form>
</div>

import { Component, OnInit, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFunnelModule } from 'devextreme-angular/ui/funnel';
import { DxBulletModule } from 'devextreme-angular/ui/bullet';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';

import { CardAnalyticsModule } from 'src/app/components/library/card-analytics/card-analytics.component';
import { Dates } from 'src/app/types/resource';
import { ApplyPipeModule } from 'src/app/pipes/apply.pipe';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { ToolbarAnalyticsModule } from '../../../components/utils/toolbar-analytics/toolbar-analytics.component';
import { CardOperationalCostsModule, TitleBarModule } from 'src/app/components';
import { CustomCurrencyPipeModule } from 'src/app/pipes/customCurrency.pipe';
import {
  DxDateRangeBoxModule,
  DxResponsiveBoxModule,
  DxTabPanelModule,
  DxTabsModule,
} from 'devextreme-angular';
import { ValueChangedEvent } from 'devextreme/ui/date_range_box';
import { BudgetService } from 'src/app/services/budget/budget.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';

interface Budget {
  AmountSpend: number;
  BudgetCategoryId: number;
  BudgetCategoryLabel: string;
  BudgetSubCategoryId: number;
  BudgetSubCategoryLabel: string;
  BudgetedAmount: number;
  Logo: string;
}

@Component({
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss'],
  providers: [],
})
export class AnalyticsDashboardComponent implements OnInit {
  currentPeriod: [Date, Date] = [new Date('2024-01-01'), new Date()];

  isLoading: boolean = true;

  tabPanelItems: string[] = ['Structure costs', 'Salary costs', 'Capex costs'];

  budgetCosts: Budget[] = [];

  structureCosts: Budget[] = [];

  salaryCosts: Budget[] = [];

  capexCosts: Budget[] = [];

  currentBankBalance: number = 0;

  accountReceivableAmount: number = 0;

  accountDeductionsAmount: number = 0;

  constructor(private budgetService: BudgetService, private router: Router) {}

  selectionChange(dates: Dates) {
    const periodStart = new Date(dates.startDate);
    const periodEnd = new Date(dates.endDate);

    this.loadData(periodStart, periodEnd);
  }

  loadData = (periodStart: Date, periodEnd: Date) => {
    this.isLoading = true;

    this.budgetService.getBudgetBankAccount().subscribe({
      next: (data: any) => {
        this.currentBankBalance = data.CurrentBankBalance;
        this.accountReceivableAmount = data.AccountReceivable;
        this.accountDeductionsAmount = data.AccountDeductable;
      },
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
    });

    this.budgetService
      .getBudgetFollowUpAnalysis(periodStart, periodEnd)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data: Budget[]) => {
          this.budgetCosts = data;
          this.structureCosts = data.filter(
            (x: Budget) => x.BudgetCategoryId == 3
          );
          this.salaryCosts = data.filter(
            (x: Budget) => x.BudgetCategoryId == 5
          );
          this.capexCosts = data.filter((x: Budget) => x.BudgetCategoryId == 4);
        },
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
      });
  };

  ngOnInit(): void {
    this.loadData(this.currentPeriod[0], this.currentPeriod[1]);
  }

  currentValueChanged(e: ValueChangedEvent) {
    var values = e.value;

    this.currentPeriod[0] = values[0];
    this.currentPeriod[1] = values[1];

    this.loadData(this.currentPeriod[0], this.currentPeriod[1]);
  }

  categoryAmountSum = (categoryId: number): number => {
    const data = this.budgetCosts.filter(
      (x: Budget) => x.BudgetCategoryId == categoryId
    );

    return data.reduce((acc, val) => acc + val.AmountSpend, 0);
  };

  subCategoryAmountSum = (subCategoryId: number) => {
    const data = this.budgetCosts.filter(
      (x: Budget) => x.BudgetSubCategoryId == subCategoryId
    );

    return data.reduce((acc, val) => acc + val.AmountSpend, 0);
  };

  categoryBudgetedAmountSum = (categoryId: number) => {
    const data = this.budgetCosts.filter(
      (x: Budget) => x.BudgetCategoryId == categoryId
    );

    return data.reduce((acc, val) => acc + val.BudgetedAmount, 0);
  };

  subCategoryBudgetedAmountSum = (subCategoryId: number) => {
    const data = this.budgetCosts.filter(
      (x: Budget) => x.BudgetSubCategoryId == subCategoryId
    );

    return data.reduce((acc, val) => acc + val.BudgetedAmount, 0);
  };

  categoryDescription = (categoryId: number) => {
    //return a description like that : 82 % Spend of the 194 880,00 € Budgetted
    return (
      Math.round(
        (this.categoryAmountSum(categoryId) /
          this.categoryBudgetedAmountSum(categoryId)) *
          100
      ) +
      '% Spend of the ' +
      this.categoryBudgetedAmountSum(categoryId).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }) +
      ' Budgetted'
    );
  };

  subCategoryDescription = (subCategoryId: number) => {
    //return a description like that : 82 % Spend of the 194 880,00 € Budgetted
    return (
      Math.round(
        (this.subCategoryAmountSum(subCategoryId) /
          this.subCategoryBudgetedAmountSum(subCategoryId)) *
          100
      ) +
      '% Spend of the ' +
      this.subCategoryBudgetedAmountSum(subCategoryId).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }) +
      ' Budgetted'
    );
  };

  categoryLogo = (categoryId: number) => {
    const data = this.budgetCosts.filter(
      (x: Budget) => x.BudgetCategoryId == categoryId
    );

    return data[0].Logo;
  };

  categoryLabel = (categoryId: number) => {
    const data = this.budgetCosts.filter(
      (x: Budget) => x.BudgetCategoryId == categoryId
    );

    return data[0].BudgetCategoryLabel;
  };

  gotoLink = (subCategoryId: number) => {
    //navigate to cost-follow-up with param subCategoryId, periodStart, periodEnd

    this.router.navigate(['/cost-follow-up'], {
      queryParams: {
        subCategoryId: subCategoryId,
        periodStart: this.currentPeriod[0],
        periodEnd: this.currentPeriod[1],
      },
    });
  };
}

@NgModule({
  providers: [],
  exports: [],
  declarations: [AnalyticsDashboardComponent],
  imports: [
    DxScrollViewModule,
    DxDataGridModule,
    DxBulletModule,
    DxFunnelModule,
    DxPieChartModule,
    DxChartModule,
    DxDateBoxModule,
    DxButtonModule,
    CardAnalyticsModule,
    DxLoadPanelModule,
    ApplyPipeModule,
    CommonModule,
    ToolbarAnalyticsModule,
    CardOperationalCostsModule,
    CustomCurrencyPipeModule,
    TitleBarModule,
    DxDateRangeBoxModule,
    DxResponsiveBoxModule,
    DxTabsModule,
    DxTabPanelModule,
  ],
})
export class AnalyticsDashboardModule {}

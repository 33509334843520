import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { DataQualityCheckValidationModule } from 'src/app/components/data-quality/data-quality-check-validation/data-quality-check-validation.component';
import { Column } from 'devextreme/ui/data_grid';
import { GridType } from 'src/app/types/data-quality/grid-type';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';

@Component({
  selector: 'data-quality-px-parties',
  templateUrl: './data-quality-px-parties.component.html',
  styleUrls: ['./data-quality-px-parties.component.scss'],
})
export class DataQualityPxPartiesComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'AccountAddress',
      value: 'Account Address',
      description: 'Check that all account address are filed in files',
    },
    {
      key: 'CaseContactEmailAddress',
      value: 'Contact On Case',
      description: 'Check if every case has a case contact email',
    },
    {
      key: 'CasesWithoutApplicant',
      value: 'Cases Without Applicant',
      description: 'Each PAT/DES/TRM case should have at least one applicant',
    },
    {
      key: 'CasesWithoutInventors',
      value: 'Cases Without Inventors',
      description: 'All PAT-cases should have at least one inventor',
    },
    {
      key: 'DoublesInCasting',
      value: 'Doubles Roles',
      description:
        'Identical name is used for identical party - delete the double',
    },
    {
      key: 'EPRepresentative',
      value: 'EP Representative',
      description: 'Cases with wrong PTO.',
    },
    {
      key: 'ForeignAgent',
      value: 'Foreign Agent',
      description: 'Check that all cases have an foreign agent',
    },
    {
      key: 'ForeignPto',
      value: 'Foreign PTO',
      description: 'All PAT/DES/TRM BE-BX/EP-EU/WO/PC files should have a PTO.',
    },
    {
      key: 'OppositionFilesWithoutCounterParty',
      value: 'Opposition Files Without Counter Party',
      description: 'Opposition Files Without Counter Party',
    },
    {
      key: 'PtoAndForeignAgentSameCase',
      value: 'PTO And Foreign Agent Same Case',
      description:
        'No case can contain AND PTO AND foreign agent - delete what is not relevant',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/DataQuality/PXParties`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'CaseId',
        caption: 'Case Id',
        alignment: 'center',
      },
      {
        dataField: 'CaseNumber',
        caption: 'Case Number',
        alignment: 'center',
      },
      {
        dataField: 'Team',
        caption: 'Team',
        alignment: 'center',
      },
      {
        dataField: 'CatchWord',
        caption: 'CatchWord',
        alignment: 'center',
      },
      {
        dataField: 'PatriciaLink',
        caption: 'Patricia Link',
        visible: false,
        showInColumnChooser: false,
      },
    ];

    //create columns depending on the selected grid
    switch (this.selectedGrid.key) {
      case 'AccountAddress':
        columns.push({
          dataField: 'Open By',
          caption: 'OpenBy',
          alignment: 'center',
        });
        break;
      case 'CaseContactEmailAddress':
        columns.push(
          {
            dataField: 'Name',
            caption: 'Name',
            alignment: 'center',
          },
          {
            dataField: 'NameChild',
            caption: 'Contacts',
            alignment: 'center',
          }
        );
        break;
      case 'CasesWithoutApplicant':
        columns.push();
        break;
      case 'CasesWithoutInventors':
        columns.push();
        break;
      case 'DoublesInCasting':
        columns.push({
          dataField: 'RoleType',
          caption: 'Role Type',
          alignment: 'center',
        });
        break;
      case 'EPRepresentative':
        columns.push();
        break;
      case 'ForeignAgent':
        columns.push();
        break;
      case 'ForeignPto':
        columns.push();
        break;
      case 'OppositionFilesWithoutCounterParty':
        columns.push({
          dataField: 'CaseTypeLabel',
          caption: 'Case Type Label',
          alignment: 'center',
        });
        break;
      case 'PtoAndForeignAgentSameCase':
        columns.push({
          dataField: 'Country',
          caption: 'Country',
          alignment: 'center',
        });
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [DataQualityPxPartiesComponent],
  exports: [],
})
export class DataQualityPxPartiesModule {}

<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Work Codes" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="isAdmin"
        [allowUpdating]="hasFinancialPermission"
        [allowDeleting]="isAdmin"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Workcode'"
          height="auto"
          width="auto"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item
            [colSpan]="2"
            dataField="WorkCodeLabel"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxi-validation-rule
              type="required"
              message="Work Code Label is required"
            />
            <dxo-label text="Work Code Label" />
          </dxi-item>
          <dxi-item dataField="WorkCodeDescription" [colSpan]="2">
            <dxo-label text="Work Code Description" />
          </dxi-item>
          <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
            <dxi-item dataField="CategoryId" [colSpan]="2">
              <dxo-label text="Category" />
            </dxi-item>
            <dxi-item dataField="InvoiceLineCategory" [colSpan]="2">
              <dxo-label text="Invoice Line Category" />
            </dxi-item>
            <dxi-item dataField="EstimatedEffort" [colSpan]="1">
              <dxo-label text="Estimated Effort" />
            </dxi-item>
            <dxi-item dataField="ForeignMarkup" [colSpan]="1">
              <dxo-label text="Foreign Markup" />
            </dxi-item>
            <dxi-item dataField="CalculateVat" [colSpan]="1">
              <dxo-label text="Calculate Vat" />
            </dxi-item>
            <dxi-item dataField="IsActive" [colSpan]="1">
              <dxo-label text="Active" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Import from XLS',
            icon: 'exportxlsx',
            stylingMode: 'contained',
            onClick: onOpenImport
          }"
        />
        <dxi-item
          location="before"
          locateInMenu="auto"
          widget="dxButton"
          [options]="{
            text: 'Export as XLS',
            icon: 'fas fa-file-excel',
            stylingMode: 'contained',
            onClick: exportWorkcodes
          }"
        />
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new workcode"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="WorkCodeLabel"
        caption="Work Code Label"
        width="7%"
        alignment="center"
      />
      <dxi-column
        dataField="WorkCodeDescription"
        caption="Work Code Description"
        alignment="center"
      />
      <dxi-column
        dataField="WorkCodeDescriptionEnglish"
        caption="Work Code Description English"
        alignment="center"
        [visible]="false"
      />
      <dxi-column
        dataField="WorkCodeDescriptionFrench"
        caption="Work Code Description French"
        alignment="center"
        [visible]="false"
      />
      <dxi-column
        dataField="WorkCodeDescriptionDutch"
        caption="Work Code Description Dutch"
        alignment="center"
        [visible]="false"
      />
      <dxi-column
        dataField="CategoryId"
        caption="Work Code Category Label"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="categories.store()"
          displayExpr="WorkCodeCategoryLabel"
          valueExpr="WorkCodeCategoryId"
        />
      </dxi-column>
      <dxi-column
        dataField="InvoiceLineCategory"
        caption="Invoice Line Category Label"
        width="10%"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="invoiceLineCategories.store()"
          displayExpr="InvoiceLineCategoryLabel"
          valueExpr="InvoiceLineCategoryId"
        />
      </dxi-column>
      <dxi-column
        dataField="EstimatedEffort"
        caption="Estimated Effort (min)"
        width="6%"
      />
      <dxi-column
        dataField="ForeignMarkup"
        caption="Foreign Markup (%)"
        width="7%"
      />
      <dxi-column
        dataField="CalculateVat"
        caption="Calculate Vat"
        [visible]="false"
        width="3%"
      />
      <dxi-column
        dataField="IsActive"
        caption="Active"
        [visible]="true"
        width="3%"
      />
      <dxi-column type="buttons">
        <dxi-button name="edit" />
        <dxi-button name="delete" />
        <dxi-button icon="fas fa-globe" [onClick]="onLocalize" />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceConfigurationWorkCodes"
      />
    </dx-data-grid>

    <finance-configuration-workcode-localize
      [(visible)]="isLocalizePopupOpened"
      [titleText]="'Localize WorkCode'"
      (visibleChange)="onCloseLocalizePopup($event)"
      [workcode]="editedRow"
    />
    <popup-file-upload
      [visible]="isImportPopupOpened"
      [accept]="'.xls,.xlsx'"
      [titleText]="'Import WorkCodes'"
      [label]="'excel file'"
      (onClose)="onCloseImport()"
      (onSave)="onSaveImport($event)"
    />
  </div>
</dx-scroll-view>

import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTextAreaModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { WorkcodeService } from 'src/app/services/invoicing/workcode/workcode.service';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'finance-configuration-workcode-localize',
  templateUrl: './finance-configuration-workcode-localize.component.html',
  styleUrls: ['./finance-configuration-workcode-localize.component.scss'],
})
export class FinanceConfigurationWorkcodeLocalizeComponent implements OnInit {
  @Input() workcode: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(private workcoderSvc: WorkcodeService) {}

  ngOnInit(): void {}

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.workcoderSvc.updateTranslation(this.workcode).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.visible = false;
        this.visibleChange.emit(false);
      },
    });
  }

  onShow() {
    this.workcoderSvc
      .getWorkCodeTranslation(this.workcode.WorkCodeId)
      .subscribe(
        (data) => {
          this.workcode = data;
        },
        (error) => {
          console.error(error);
        }
      );
  }
}

@NgModule({
  declarations: [FinanceConfigurationWorkcodeLocalizeComponent],
  exports: [FinanceConfigurationWorkcodeLocalizeComponent],
  bootstrap: [FinanceConfigurationWorkcodeLocalizeComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTextAreaModule,
  ],
})
export class FinanceConfigurationWorkcodeLocalizeModule {}

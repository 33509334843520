import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { TitleBarModule } from 'src/app/components';
import { RenewalInternalOverviewEditModule } from 'src/app/components/renewal/renewal-internal/renewal-internal-overview-edit/renewal-internal-overview-edit.component';
import { RenewalService } from 'src/app/services/renewal/renewal.service';

@Component({
  selector: 'renewal-internal-overview',
  templateUrl: './renewal-internal-overview.component.html',
  styleUrls: ['./renewal-internal-overview.component.scss'],
})
export class RenewalInternalOverviewComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  constructor(private renewalService: RenewalService) {
    this.url = this.renewalService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}/InternalRenewalDeadlines`,
        updateUrl: `${this.url}/InternalRenewalDeadlines`,
        deleteUrl: `${this.url}/InternalRenewalDeadlines`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  requestQuote = (e) => {
    //get selected row data
    const rows = this.dataGrid.instance.getSelectedRowsData();

    //if no rows selected display alert
    if (rows.length === 0) {
      alert('Please select at least one row');
      return;
    }
    this.renewalService.calculateInternalRenewalQuote(rows).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    RenewalInternalOverviewEditModule,
  ],
  declarations: [RenewalInternalOverviewComponent],
  exports: [],
})
export class RenewalInternalOverviewModule {}

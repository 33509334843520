import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxScrollViewModule,
  DxToolbarModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { FeeCalculatorConfigurationService } from 'src/app/services/fee-calculator/fee-calculator-configuration.service';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import { BillingMatrix } from 'src/app/types/feecalculator/BillingMatrix';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import notify from 'devextreme/ui/notify';
import { RowInsertedEvent } from 'devextreme/ui/data_grid';
import { RowUpdatedEvent } from 'devextreme/ui/data_grid';
import { RowRemovedEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-billing-matrix-modify',
  templateUrl: './billing-matrix-modify.component.html',
  styleUrls: ['./billing-matrix-modify.component.scss'],
})
export class BillingMatrixModifyComponent implements OnInit, OnChanges {
  @Input() BillingMatrixId: number = null;

  url = environment.CalystaApiBaseURL + 'api/';

  CaseTypes: DataSource = null;
  WorkCodeLookup: DataSource = null;
  ServiceTypeLookup: DataSource = null;

  BillingMatrix: BillingMatrix = null;

  constructor(
    private route: ActivatedRoute,
    private FeeCalcConfSvc: FeeCalculatorConfigurationService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.BillingMatrixId = +params.get('id');
      this.FeeCalcConfSvc.GetBillingMatrix(this.BillingMatrixId).subscribe({
        next: (x) => {
          this.BillingMatrix = x;
        },
        error: (err) => {
          console.log(err);
        },
      });
    });

    this.CaseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${this.url}Case/CaseType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'CaseTypeLabel',
    });

    this.ServiceTypeLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceTypeId',
        loadUrl: `${this.url}FeeCalculator/ServiceType`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'ServiceTypeLabel',
    });

    this.WorkCodeLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/Workcode/lookup`,
      }),
      sort: 'WorkCodeLabel',
    });
  }

  onClickBackButton() {
    this.router.navigate(['/fee-calculator-configuration-billing-matrix']);
  }

  updateGeneralData($event: FieldDataChangedEvent) {
    console.log($event);
    console.log(this.BillingMatrix);

    this.FeeCalcConfSvc.updateBillingMatrix(this.BillingMatrix).subscribe({
      next: (x) => {
        notify('Billing Matrix updated successfully', 'success', 3000);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  onRowInserted($event: RowInsertedEvent) {
    console.log($event);
  }
  onRowUpdated($event: RowUpdatedEvent) {
    console.log($event);
  }
  onRowRemoved($event: RowRemovedEvent) {
    console.log($event);
  }
}

@NgModule({
  imports: [
    CommonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxFormModule,
    DxDataGridModule,
    DxToolbarModule,
    DxButtonModule,
  ],
  providers: [],
  declarations: [BillingMatrixModifyComponent],
  exports: [],
})
export class BillingMatrixModifyModule {}

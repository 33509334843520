<dx-popup
  [(visible)]="visible"
  titleText="Preapre mail"
  (onShowing)="onShow()"
  [showCloseButton]="true"
  (onHidden)="onHide()"
  height="70vh"
  width="70vw"
>
  <dx-scroll-view class="view-wrapper-scroll">
    <div class="view-wrapper">
      <titlebar [titleText]="titleInfo" />
      <div>
        <dx-tab-panel (onSelectionChanged)="onTabSelectionChanged($event)">
          <dxi-item title="1. Recipients" icon="fas fa-user" [tabIndex]="1">
            <dx-data-grid
              #recipientDataGrid
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [dataSource]="recipientsDataSource"
              [showBorders]="true"
              [remoteOperations]="true"
              width="100%"
              height="30em"
              [showBorders]="true"
              [repaintChangesOnly]="true"
              [wordWrapEnabled]="true"
              [(selectedRowKeys)]="selectedRecipients"
              (onSelectionChanged)="onRecipientSelectionChange($event)"
            >
              <dxo-load-panel [enabled]="true" />
              <dxo-sorting mode="multiple" />
              <dxo-scrolling mode="virtual" [showScrollbar]="true" />
              <dxo-selection mode="multiple" />
              <dxi-column dataField="NameId" caption="Name Id" width="10%" />
              <dxi-column dataField="FullName" caption="Name" />
              <dxi-column dataField="Email" caption="Email" />
              <dxi-column
                dataField="RoleTypeIds"
                caption="Role Types Ids"
                [visible]="false"
              />
              <dxi-column dataField="RoleType" caption="Role Types" />
            </dx-data-grid>
          </dxi-item>
          <dxi-item title="2. CC" icon="fas fa-users" [tabIndex]="2">
            <dx-data-grid
              #ccDataGrid
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [dataSource]="ccDataSource"
              [showBorders]="true"
              [remoteOperations]="true"
              width="100%"
              height="30em"
              [showBorders]="true"
              [repaintChangesOnly]="true"
              [wordWrapEnabled]="true"
            >
              <dxo-load-panel [enabled]="true" />
              <dxo-sorting mode="multiple" />
              <dxo-selection mode="multiple" />
              <dxo-scrolling mode="virtual" [showScrollbar]="true" />
              <dxi-column dataField="NameId" caption="Name Id" width="10%" />
              <dxi-column dataField="FullName" caption="Name" />
              <dxi-column dataField="Email" caption="Email" />
              <dxi-column
                dataField="RoleTypeId"
                caption="Role Types Id"
                [visible]="false"
              />
              <dxi-column dataField="RoleType" caption="Role Types" />
            </dx-data-grid>
          </dxi-item>
          <dxi-item title="3. Summary" icon="fas fa-envelope" [tabIndex]="3">
            <div class="summary">
              <div class="summary-item">
                <div class="summary-item-label">Recipients :</div>
                <div class="summary-item-value">{{ getRecipientData() }}</div>
              </div>
              <div class="summary-item">
                <div class="summary-item-label">CC :</div>
                <div class="summary-item-value">{{ getCCData() }}</div>
              </div>
            </div>
          </dxi-item>
        </dx-tab-panel>

        <dx-button
          text="Confirm"
          class="confirm-button"
          type="default"
          stylingMode="contained"
          [disabled]="confirmLoading"
          (onClick)="onConfirm()"
        />
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="'center'"
  [(visible)]="confirmLoading"
  [showIndicator]="true"
  [showPane]="true"
  [hideOnOutsideClick]="false"
/>

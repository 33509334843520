<dx-chart [dataSource]="ChartData" #RealizedTargetChart>
  <dxi-series valueField="TargetedHours" name="Target" color="grey" />
  <dxi-series valueField="BilledHours" name="Realized" color="orange" />
  <dxo-common-series-settings
    #commonSeries
    [argumentField]="ArgumentField"
    [type]="'area'"
  >
    <dxo-point [visible]="true" />
  </dxo-common-series-settings>
  <dxo-margin [bottom]="20" />
  <dxo-argument-axis [valueMarginsEnabled]="false" />
  <dxo-export [enabled]="true" />
  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" />
  <dxo-tooltip [enabled]="true" />
</dx-chart>

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
  DxTextAreaModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { EmployeeNameLookupModule } from 'src/app/components/lookups/employee-name-lookup/employee-name-lookup.component';
import { TitleBarModule } from 'src/app/components';
import { CRMService } from 'src/app/services/prospection/crm.service';
import DataSource from 'devextreme/data/data_source';
import { CrmToDoMarkAsDoneModule } from 'src/app/components/crm/crm-todo-mark-as-done-form/crm-todo-mark-as-done-form.component';
import { environment } from 'src/environments/environment';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-crm-todo',
  templateUrl: './crm-todo.component.html',
  styleUrls: ['./crm-todo.component.scss'],
})
export class CrmTodoComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  employees: DataSource;

  actions: DataSource;

  prospects: DataSource;

  url: string;

  employeeId: number;

  isMarkAsDonePopupOpened: boolean = false;

  todoToMarkAsDone: any;

  includeDone: boolean = false;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor(private crmService: CRMService) {
    this.url = this.crmService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProspectActionId',
        loadUrl: `${this.url}/todo`,
        insertUrl: `${this.url}/todo`,
        updateUrl: `${this.url}/todo`,
        deleteUrl: `${this.url}/todo`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
            if (this.includeDone) {
              ajaxOptions.data.includeDone = this.includeDone;
            }
            if (this.employeeId) {
              ajaxOptions.data.employee = this.employeeId;
            }
          }
        },
      }),
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Employee/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });

    this.prospects = new DataSource({
      store: AspNetData.createStore({
        key: 'ProspectId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Prospection/CRM/Prospect/Lookup`,
      }),
      sort: 'ProspectName',
    });

    this.actions = new DataSource({
      store: AspNetData.createStore({
        key: 'ActionId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Prospection/Action/Lookup`,
      }),
      sort: 'Name',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEmployeeSelectionChanged = (e) => {
    this.employeeId = e;
    this.dataGrid.instance.getDataSource().reload();
  };

  onCloseMarkAsDone = (e: boolean) => {
    this.isMarkAsDonePopupOpened = e;
  };

  includeDoneChanged = (e) => {
    this.includeDone = e.value;
    this.dataGrid.instance.getDataSource().reload();
  };

  markAsDone = (e) => {
    this.todoToMarkAsDone = { ...e.row.data };
    this.isMarkAsDonePopupOpened = e;
  };

  onTodoMarkAsDoneComplete = (e) => {
    this.crmService
      .markTodoAsDone(
        e.ProspectActionId,
        e.IsOutcomePositive,
        e.OutcomeComments,
        e.DoneDate
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
  };

  isMarkableAsDone = (e) => {
    return e.row.data.Done === false;
  };

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.parentType === 'dataRow' && e.dataField === 'Comments') {
      e.editorName = 'dxTextArea';
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    EmployeeNameLookupModule,
    CrmToDoMarkAsDoneModule,
    DxTextAreaModule,
  ],
  exports: [],
  declarations: [CrmTodoComponent],
  providers: [],
})
export class CrmTodoModule {}

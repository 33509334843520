import { Component, NgModule, OnInit } from '@angular/core';
import { TitleBarModule } from 'src/app/components';
import { CommonModule } from '@angular/common';
import {
  DxResponsiveBoxModule,
  DxScrollViewModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import { DxiItemModule } from 'devextreme-angular/ui/nested';
import { CrmTodoModule } from '../crm/crm-todo/crm-todo.component';
import { HomeTodoSectionModule } from 'src/app/components/home/home-todo-section/home-todo-section.component';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { HomePatriciaDeadlinesSectionModule } from 'src/app/components/home/home-patricia-deadlines-section/home-patricia-deadlines-section.component';
import { HomeSalesReportSectionModule } from 'src/app/components/home/home-sales-report-section/home-sales-report-section.component';
import { MonthlyGoalsCardModule } from '../../components/utils/monthly-goals-card/monthly-goals-card.component';
import { HomeWipSectionModule } from 'src/app/components/home/home-wip-section/home-wip-section.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  todos: DataSource;

  deadlines: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    //if the user is a guest (EY) send them to invoice in
    if (this.authService.isUserGuest()) {
      window.location.href = '/#/finance-invoices-in';
    }

    this.todos = new DataSource({
      store: AspNetData.createStore({
        key: 'ProspectActionId',
        loadUrl: `${this.url}Prospection/Todo/Me`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
          }
        },
      }),
    });

    this.deadlines = new DataSource({
      store: AspNetData.createStore({
        key: 'EventId',
        loadUrl: `${this.url}Patricia/Event/Deadline/Me`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
          }
        },
      }),
    });

    this.todos.load();

    this.deadlines.load();
  }
}

@NgModule({
  imports: [
    CommonModule,
    TitleBarModule,
    DxScrollViewModule,
    HomeTodoSectionModule,
    DxiItemModule,
    CrmTodoModule,
    HomePatriciaDeadlinesSectionModule,
    DxTabPanelModule,
    DxResponsiveBoxModule,
    HomeSalesReportSectionModule,
    MonthlyGoalsCardModule,
    HomeWipSectionModule,
  ],
  providers: [],
  exports: [],
  declarations: [HomeComponent],
})
export class HomeModule {}

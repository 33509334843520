import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { PavisFilterLookupModule } from 'src/app/components/lookups/pavis-filter-lookup/pavis-filter-lookup.component';
import { TitleBarModule } from 'src/app/components';
import { RenewalService } from 'src/app/services/renewal/renewal.service';
import DataSource from 'devextreme/data/data_source';
import { PavisService } from 'src/app/services/renewal/pavis/pavis.service';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';

@Component({
  selector: 'renewal-pavis-import-export',
  templateUrl: './renewal-pavis-import-export.component.html',
  styleUrls: ['./renewal-pavis-import-export.component.scss'],
})
export class RenewalPavisImportExportComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  type: string;

  status: number;

  isAllCases: boolean = false;

  selectedRows: any[] = [];

  constructor(
    private renewalService: RenewalService,
    private pavisService: PavisService
  ) {
    this.url = this.renewalService.getUrl();
    this.openPatricia = this.openPatricia.bind(this);
    this.sendToPavis = this.sendToPavis.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}/Pavis/PavisHeader`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (this.type) {
            ajaxOptions.data.type = this.type;
          }

          if (this.status) {
            ajaxOptions.data.filterId = this.status;
          }

          if (this.isAllCases) {
            ajaxOptions.data.isAllCases = this.isAllCases;
          }
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onTypeChanged = (e) => {
    this.type = e.value;
    this.refreshData();
  };

  onStatusChanged = (e) => {
    this.status = e;
    this.refreshData();
  };

  onAllCasesChanged = (e) => {
    this.isAllCases = e.value;
    this.refreshData();
  };

  openPatricia(e) {
    const caseNumber = e.row.data.CaseNumber;
    window.open(`http://patricia.calysta.eu/pat/#!case-view/${caseNumber}`);
    e.event.preventDefault();
  }

  sendToPavis = (e) => {
    const charges = this.selectedRows;
    this.dataGrid.instance.beginCustomLoading('Sending to Pavis');
    this.pavisService
      .importCases(charges)
      .pipe(
        finalize(() => {
          this.dataGrid.instance.endCustomLoading();
        })
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    PavisFilterLookupModule,
  ],
  declarations: [RenewalPavisImportExportComponent],
  exports: [],
})
export class RenewalPavisImportExportModule {}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxDateRangeBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { CostStatusLookupModule } from 'src/app/components/lookups/cost-status-lookup/cost-status-lookup.component';
import { RangeDatePickerModule } from 'src/app/components/utils/range-date-picker/range-date-picker.component';
import { environment } from 'src/environments/environment';
import { PDFPreviewModule } from 'src/app/components/library/pdf-preview/pdf-preview.component';
import { EpoService } from 'src/app/services/epo/epo.service';
import { saveAs } from 'file-saver-es';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'epo-communications',
  templateUrl: './epo-communications.component.html',
  styleUrls: ['./epo-communications.component.scss'],
})
export class EpoCommunicationsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  folderId: number;

  showHandledCommunications: boolean = false;

  folders: DataSource;

  constructor(private epoService: EpoService) {
    this.url = `${environment.CalystaApiBaseURL}api/EPO`;
  }

  ngOnInit(): void {
    this.loadDatasource();

    this.epoService.getFolders().subscribe((data) => {
      this.folders = new DataSource({
        store: data,
        sort: 'FolderName',
      });
    });
  }

  loadDatasource = () => {
    var url = `${this.url}/Communications/Unhandled`;

    if (this.showHandledCommunications) {
      url = `${this.url}/Communications`;
    }

    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: url,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
          ajaxOptions.data = ajaxOptions.data || {};

          if (this.folderId) {
            ajaxOptions.data.folderId = this.folderId;
          }
        },
      }),
    });
  };

  folderChanged = (e: any) => {
    this.folderId = e.value;
    this.dataGrid.instance.refresh();
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  seeHandledCommunications = (e) => {
    this.showHandledCommunications = e.value;
    this.loadDatasource();
    this.dataGrid.instance.refresh();
  };

  Download = (e) => {
    var id = e.row.data.Id;

    this.epoService.download(id).subscribe({
      next: (blob) => {
        saveAs(blob, 'file.zip');
      },
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
    });
  };

  Handle = (e) => {
    var id = e.row.data.Id;
    var userRef = e.row.data.UserReference;

    const entity = {
      CommunicationId: id,
      UserReference: userRef,
    };

    this.epoService.markAsHandled([entity]).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.dataGrid.instance.refresh();
      },
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    TitleBarModule,
    DxScrollViewModule,
    RangeDatePickerModule,
    CostStatusLookupModule,
    PDFPreviewModule,
    DxLoadPanelModule,
    DxDateRangeBoxModule,
    DxLookupModule,
  ],
  providers: [],
  declarations: [EpoCommunicationsComponent],
  exports: [],
})
export class EpoCommunicationsModule {}

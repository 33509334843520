<div class="cards kpi">
  <financial-valuta-kpi
    [total]="totals.TotalAmountToPay"
    [title]="'Total Amount To Pay (VAT incl.)'"
    [detailTitle]="'(excl.)'"
    [detailTotal]="totals.TotalAmountToPayNonVat"
  />
  <financial-valuta-kpi
    [total]="totals.TotalAmountOverdueToPay"
    [title]="'Total Amount Overdue To Pay (VAT incl.)'"
    [detailTitle]="'(excl.)'"
    [detailTotal]="totals.TotalAmountOverdueToPayNonVat"
  />
  <financial-number-kpi
    [total]="totals.InvoicesToPay"
    [title]="'# Invoices Due'"
  />
  <financial-number-kpi
    [total]="totals.InvoicesToVerify"
    [title]="'# Invoices Paid To Verify'"
  />
  <financial-number-kpi
    [total]="totals.InvoicesOverdue"
    [title]="'# Invoices Overdue'"
  />
</div>
<div class="separator"></div>
<div class="amountdueGrid">
  <dx-data-grid
    [dataSource]="amountDueDataSource"
    height="80vh"
    [rowAlternationEnabled]="true"
  >
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxi-column dataField="ProviderId" caption="Provider ID" [visible]="false">
    </dxi-column>
    <dxi-column
      dataField="Provider"
      caption="Provider"
      [sortIndex]="1"
      sortOrder="asc"
    >
    </dxi-column>
    <dxi-column dataField="InvoicesToPay" caption="# Invoices To Pay" />
    <dxi-column
      dataField="InvoicesToVerify"
      caption="# Invoices Paid To Verify"
    />
    <dxi-column dataField="InvoicesOverdue" caption="# Invoices Overdue" />
    <dxi-column dataField="AmountToPayNonVat" caption="Amount To Pay (excl.)">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column dataField="AmountToPay1" caption="Amount To Pay">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column
      dataField="AmountOverDueToPayNonVat"
      caption="Amount Overdue To Pay (excl.)"
    >
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column dataField="AmountOverDueToPay" caption="Amount Overdue To Pay">
      <dxo-format type="currency" precision="2" />
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detail" />
    <div *dxTemplate="let details of 'detail'">
      <finance-creditcollect-topay-details
        [providerId]="details.data.ProviderId"
        [amountOverdue]="details.data.AmountOverDue"
      />
    </div>
  </dx-data-grid>
</div>

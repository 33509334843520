import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AuthPermissionService } from '../services/auth/permission.service';
import { AppPermissions } from '../routes/permissions';
import { AuthService } from '../services/auth/auth.service';

export const permissionsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const permissionsService = inject(AuthPermissionService);
  const authService = inject(AuthService);

  // Get the required permissions for the route
  const requiredPermissions: any[] = route.data['requiredPermissions'];

  // Step 1: If user is an admin, allow access immediately
  if (permissionsService.hasPermission(AppPermissions.ADMIN)) {
    return true;
  }

  // Step 2: Handle guest users
  if (authService.isUserGuest()) {
    // If the user is a guest and no permissions for external are set, dont allow access
    if (requiredPermissions === undefined || requiredPermissions.length === 0) {
      return false;
    }

    // If the user is a guest and external permissions are required, allow access
    if (
      requiredPermissions?.some(
        (permission) => permission === AppPermissions.EXTERNAL
      )
    ) {
      return true;
    }

    // If the user is a guest and any non-external permissions are required, deny access
    if (
      requiredPermissions?.some(
        (permission) => permission !== AppPermissions.EXTERNAL
      )
    ) {
      return false;
    }
  }

  // Step 3: Handle cases where no permissions are defined
  if (requiredPermissions === undefined || requiredPermissions.length === 0) {
    // If no permissions are required for the route, allow access
    return true;
  }

  // Step 4: Check if the user has any of the required permissions
  const hasRequiredPermissions = requiredPermissions.some((permission) =>
    permissionsService.hasPermission(permission)
  );

  // Step 5: If the user lacks the required permissions, deny access
  if (!hasRequiredPermissions) {
    return false;
  }

  // Step 6: If none of the above conditions block access, allow the route to be activated
  return true;
};

<div class="amountdueGrid">
  <dx-data-grid
    [dataSource]="dataSource"
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    height="80vh"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="true"
    (onEditorPreparing)="onEditorPreparing($event)"
  >
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
    <dxo-filter-row visible="true" applyFilter="auto" />

    <dxo-column-chooser [enabled]="true" />
    <dxo-search-panel [visible]="true" width="20vw" />

    <dxo-toolbar>
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
      <dxi-item
        location="after"
        name="columnChooserButton"
        locateInMenu="auto"
      />
      <dxi-item location="after" name="searchPanel" locateInMenu="never" />
    </dxo-toolbar>

    <dxo-editing
      mode="popup"
      [allowAdding]="false"
      [allowUpdating]="true"
      [allowDeleting]="false"
    >
      <dxo-popup
        [showTitle]="true"
        [title]="'Reminder Outcome'"
        height="auto"
        width="30vw"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="Outcome">
            <dxo-label text="Outcome" />
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxi-column dataField="Id" caption="Id" [visible]="false" />
    <dxi-column dataField="InvoiceNumber" caption="Invoice Number" />
    <dxi-column dataField="ClientName" caption="Client" />
    <dxi-column dataField="Recipient" caption="Recipient" />
    <dxi-column dataField="CreatedBy" caption="Created By" />
    <dxi-column
      dataField="PreparedDate"
      caption="Prepared Date"
      dataType="date"
      sortIndex="1"
      sortOrder="desc"
    />
    <dxi-column dataField="Outcome" caption="Outcome" />
    <dxi-column dataField="MailType" caption="Mail Type" />
    <dxi-column dataField="SentDate" caption="SentDate" [visible]="false" />
    <dxi-column dataField="IsSent" caption="Is Sent" [visible]="false" />
    <dxi-column dataField="IsDraft" caption="Is Draft" [visible]="false" />
  </dx-data-grid>
</div>

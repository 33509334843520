import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';
import { TitleBarModule } from 'src/app/components';
import { PavisHandleInstructionFormModule } from 'src/app/components/renewal/renewal-pavis/pavis-handle-instruction-form/pavis-handle-instruction-form.component';
import { PavisService } from 'src/app/services/renewal/pavis/pavis.service';
import { RenewalService } from 'src/app/services/renewal/renewal.service';

@Component({
  selector: 'renewal-pavis-instruction',
  templateUrl: './renewal-pavis-instruction.component.html',
  styleUrls: ['./renewal-pavis-instruction.component.scss'],
})
export class RenewalPavisInstructionComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  type: string;

  url: string;

  instructionsTypes: any[];

  isHandleInstructionPopupVisible = false;

  isHandleInstructionMultiPopupVisible = false;

  caseToHandle: any;

  constructor(
    private renewalService: RenewalService,
    private pavisService: PavisService
  ) {
    this.url = this.renewalService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}/Pavis/PavisCasesToRenew`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (this.type) {
            ajaxOptions.data.type = this.type;
          }
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onTypeChanged = (e) => {
    this.type = e.value;
    this.refreshData();
  };

  handleInstructions = (e) => {
    const rows = this.dataGrid.instance.getSelectedRowsData();

    //if no rows selected display alert
    if (rows.length === 0) {
      notify('Please select at least one row', 'info', 3000);
      return;
    }

    this.isHandleInstructionMultiPopupVisible = true;
  };

  requestQuote = (e) => {
    const rows = this.dataGrid.instance.getSelectedRowsData();

    //if no rows selected display alert
    if (rows.length === 0) {
      notify('Please select at least one row', 'warning', 3000);
      return;
    }

    const convertedRows: any[] = rows.map((row) => {
      return {
        Applicants: row.Applicants,
        CalystaFee: row.CalystaFee,
        Catchword: row.CaseCatchword,
        CaseId: row.CaseId,
        CaseNumber: row.CaseNumber,
        CaseTypeId: row.CaseTypeId,
        Deadline: row.Deadline,
        OfficialFee: row.OfficialFee,
        PavisId: row.PavisId,
        ServiceFee: row.ServiceFee,
        EventSchemeId: row.EventSchemeId,
      };
    });

    this.dataGrid.instance.beginCustomLoading('Refreshing quotes...');

    this.pavisService
      .calculateInternalRenewalQuote(convertedRows)
      .pipe(
        finalize(() => {
          this.dataGrid.instance.endCustomLoading();
        })
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.dataGrid.instance.refresh();
        },
      });
  };

  startHandleInstruction = (e) => {
    this.caseToHandle = e.row.data;
    this.isHandleInstructionPopupVisible = true;
  };

  cancelHandleInstruction = (e) => {
    this.caseToHandle = null;
    this.isHandleInstructionPopupVisible = false;
  };

  cancelHandleInstructions = (e) => {
    this.caseToHandle = null;
    this.isHandleInstructionMultiPopupVisible = false;
  };

  confirmHandleInstruction = (e) => {
    this.caseToHandle.InstructionId = e;
    this.pavisService.handleInstruction(this.caseToHandle).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.isHandleInstructionPopupVisible = false;
        this.dataGrid.instance.refresh();
      },
    });
  };

  confirmHandleInstructions = (e) => {
    var selectedRows = this.dataGrid.instance.getSelectedRowsData();

    this.pavisService.handleInstructions(selectedRows, e).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.isHandleInstructionMultiPopupVisible = false;
        this.dataGrid.instance.refresh();
      },
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    PavisHandleInstructionFormModule,
  ],
  declarations: [RenewalPavisInstructionComponent],
  exports: [],
})
export class RenewalPavisInstructionModule {}

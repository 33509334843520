<dx-data-grid [dataSource]="detailsDataSource">
  <dxi-column dataField="InvoiceNumber" caption="Invoice" alignment="left" />
  <dxi-column dataField="InvoiceDate" caption="Invoiced On" />
  <dxi-column dataField="DueDate" caption="To Be Payed Before" />
  <dxi-column dataField="Status" caption="Invoice Status" />
  <dxi-column dataField="AmountNonVat" caption="Amount (excl.)">
    <dxo-format type="currency" precision="2" />
  </dxi-column>
  <dxi-column dataField="AmountVat" caption="VAT Amount">
    <dxo-format type="currency" precision="2" />
  </dxi-column>
  <dxi-column dataField="Amount" caption="Amount">
    <dxo-format type="currency" precision="2" />
  </dxi-column>
  <dxi-column type="buttons">
    <dxi-button
      icon="fa-solid fa-piggy-bank"
      [onClick]="openPaidPopup"
      hint="Set as paid"
    />
    <dxi-button icon="fa-solid fa-file-pdf" [onClick]="openDocument" />
  </dxi-column>
</dx-data-grid>

<dx-popup
  #warningPopup
  [(visible)]="showPaidPopup"
  width="400"
  height="auto"
  title="Set invoice as paid"
>
  <dx-form [(formData)]="paidData">
    <dxi-item itemType="group" colCount="2">
      <dxi-item
        dataField="Status"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          validationMessageMode: 'always',
          dataSource: costStatusDataSource,
          displayExpr: 'StatusLabel',
          valueExpr: 'CostStatusId',
        }"
      >
        <dxi-validation-rule type="required" message="Status is required" />
        <dxo-label text="Status" />
      </dxi-item>
      <dxi-item
        itemType="button"
        [colSpan]="1"
        [buttonOptions]="{
          text: 'Cancel',
          type: 'normal',
          onClick: CancelPaid,
          useSubmitBehavior: true
        }"
      />
      <dxi-item
        itemType="button"
        [colSpan]="1"
        [buttonOptions]="{
          text: 'Confirm',
          type: 'default',
          onClick: ConfirmPaid,
          useSubmitBehavior: true
        }"
      />
    </dxi-item>
  </dx-form>
</dx-popup>

import { Component, ViewChild, NgModule, OnInit } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDataGridComponent,
  DxDropDownButtonModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { ContactStatusModule, TitleBarModule } from 'src/app/components';
import { CommonModule } from '@angular/common';
import { CRMService } from 'src/app/services/prospection/crm.service';
import { CrmFieldStatusLookupModule } from 'src/app/components/lookups/crm-field-status-lookup/crm-field-status-lookup.component';
import { EmployeeNameLookupModule } from 'src/app/components/lookups/employee-name-lookup/employee-name-lookup.component';
import { FormPopupModule } from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { CrmIpAssetsModule } from 'src/app/components/crm/crm-ip-assets/crm-ip-assets.component';
import notify from 'devextreme/ui/notify';

@Component({
  templateUrl: './crm-clients.component.html',
  styleUrls: ['./crm-clients.component.scss'],
})
export class CrmClientsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  employeeId: number;

  isViewIpAssetPopupOpened: boolean = false;

  selectClient: any = null;

  constructor(private crmService: CRMService) {
    this.url = this.crmService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'PxNameId',
        loadUrl: `${this.url}/crm`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            if (this.employeeId) {
              ajaxOptions.data.employee = this.employeeId;
            }
          }
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEmployeeSelectionChanged = (e) => {
    this.employeeId = e;
    this.dataGrid.instance.getDataSource().reload();
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Clients');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Clients.xlsx'
        );
      });
    });
  }

  openIpAssets = (e: any) => {
    this.isViewIpAssetPopupOpened = true;
    this.selectClient = e.row.data;
  };

  openClientInPortal = (e: any) => {
    const clientId = e.row.data.PxNameId;

    this.crmService.transfertToPortal(clientId).subscribe({
      next: (x) => {
        window.open(x.Url, '_blank');
      },
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
    });
  };
}

@NgModule({
  imports: [
    DxButtonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    FormPopupModule,
    ContactStatusModule,
    CommonModule,
    TitleBarModule,
    DxScrollViewModule,
    CrmFieldStatusLookupModule,
    EmployeeNameLookupModule,
    CrmIpAssetsModule,
  ],
  providers: [],
  exports: [],
  declarations: [CrmClientsComponent],
})
export class CrmClientsModule {}

import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import {
  DxDataGridComponent,
  DxDataGridModule,
} from 'devextreme-angular/ui/data-grid';
import { DxFunnelModule } from 'devextreme-angular/ui/funnel';
import { DxBulletModule } from 'devextreme-angular/ui/bullet';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';

import { CardAnalyticsModule } from 'src/app/components/library/card-analytics/card-analytics.component';
import { ToolbarAnalyticsModule } from 'src/app/components/utils/toolbar-analytics/toolbar-analytics.component';
import { ApplyPipeModule } from 'src/app/pipes/apply.pipe';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { SalesAnalyticsService } from 'src/app/services';
import { RealizedTargetChartModule } from '../../../components/utils/realized-target-chart/realized-target-chart.component';
import { MonthlyGoalsCardModule } from '../../../components/utils/monthly-goals-card/monthly-goals-card.component';

import { WipCardModule } from '../../../components/utils/wip-card/wip-card.component';
import { FinancialValutaKpiModule } from 'src/app/components/utils/financial-valuta-kpi/financial-valuta-kpi.component';
import { FinancialTotalKpiModule } from 'src/app/components/utils/financial-total-kpi/financial-total-kpi.component';
import { TitleBarModule } from '../../library/titlebar/titlebar.component';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';
import { DxSplitterModule, DxTabsModule } from 'devextreme-angular';

@Component({
  selector: 'home-sales-report-section',
  templateUrl: './home-sales-report-section.component.html',
  styleUrls: ['./home-sales-report-section.component.scss'],
  providers: [SalesAnalyticsService],
})
export class HomeSalesReportSectionComponent implements OnInit {
  @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
  Data: any;

  selectedTab = 0;
  currentDate: Date = new Date();

  thisMonthStartDate: string = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth(),
    1
  ).toDateString();

  thisMonthLastDay: string = getLastDayOfCurrentMonth(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth()
  ).toDateString();

  TargetTabs: TargetTab[] = [
    {
      id: 1,
      text: 'This Month',
      startDate: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      ).toDateString(),
      endDate: getLastDayOfCurrentMonth(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth()
      ).toDateString(),
    },
    {
      id: 2,
      text: 'Last Month',
      startDate: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1,
        1
      ).toDateString(),
      endDate: getLastDayOfCurrentMonth(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1
      ).toDateString(),
    },
    {
      id: 3,
      text: 'YTD',
      startDate: new Date(this.currentDate.getFullYear(), 0, 1).toDateString(),
      endDate: getLastDayOfCurrentMonth(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth()
      ).toDateString(),
    },
  ];

  constructor(private salesSvc: SalesAnalyticsService) {}

  ngOnInit(): void {
    this.salesSvc
      .getTargetsByBusiness(this.thisMonthStartDate, this.thisMonthLastDay)
      .subscribe({
        next: (data: any) => {
          this.Data = data;
        },
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
      });
  }

  onTabClick($event: any) {
    this.dataGrid.instance.beginCustomLoading('Loading data...');
    this.salesSvc
      .getTargetsByBusiness($event.itemData.startDate, $event.itemData.endDate)
      .pipe(
        finalize(() => {
          this.dataGrid.instance.endCustomLoading();
        })
      )
      .subscribe({
        next: (data: any) => {
          this.Data = data;
        },
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
      });
  }

  onClickRedirectTargets() {
    window.open('/#/analytics-sales-report');
  }
}

function getLastDayOfCurrentMonth(year, month) {
  // Create a Date object for the first day of next month
  let date = new Date(year, month + 1, 1);
  // Subtract one day to get the last day of the current month
  const lastDayOfCurrentMonth = new Date(date.getTime() - 1);
  // Return the day of the month
  return lastDayOfCurrentMonth;
}

export class TargetTab {
  id: number;
  text?: string;
  startDate?: string;
  endDate?: string;
}

@NgModule({
  providers: [],
  declarations: [HomeSalesReportSectionComponent],
  exports: [HomeSalesReportSectionComponent],
  imports: [
    DxScrollViewModule,
    DxDataGridModule,
    DxBulletModule,
    DxFunnelModule,
    DxPieChartModule,
    DxChartModule,
    CardAnalyticsModule,
    ToolbarAnalyticsModule,
    DxLoadPanelModule,
    ApplyPipeModule,
    FinancialNumberKpiModule,
    FinancialValutaKpiModule,
    CommonModule,
    RealizedTargetChartModule,
    MonthlyGoalsCardModule,
    WipCardModule,
    FinancialTotalKpiModule,
    TitleBarModule,
    DxTabsModule,
    DxSplitterModule,
  ],
})
export class HomeSalesReportSectionModule {}

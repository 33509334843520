import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxNumberBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { WorkcodeService } from 'src/app/services/invoicing/workcode/workcode.service';
import { RegistrationService } from 'src/app/services/time-registration/registration.service';
import {
  ContentReadyEvent,
  EditorPreparingEvent,
  RowInsertedEvent,
  RowUpdatedEvent,
} from 'devextreme/ui/data_grid';
import { CaseService } from 'src/app/services/case/case.service';
import { v4 as uuidv4 } from 'uuid';
import { TimeRegistrationChargeByCaseModule } from '../time-registration-charge-by-case-grid/time-registration-charge-by-case-grid.component';

@Component({
  selector: 'registration-details-form',
  templateUrl: './registration-details-form.component.html',
  styleUrls: ['./registration-details-form.component.scss'],
})
export class RegistrationDetailsFormComponent implements OnChanges, OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() entity: any;

  @Input() datasource?: any;
  @Input() EmployeeId?: number;
  @Input() allowAdd: boolean = true;
  @Input() readonly: boolean = false;

  @Output() timesChange = new EventEmitter<{
    totalHours: number;
    totalMinutes: number;
    nonBillableHours: number;
    nonBillableMinutes: number;
    billableHours: number;
    billableMinutes: number;
    totalBillableAmount: number;
  }>();

  url = environment.CalystaApiBaseURL + 'api/';

  temporaryKey: number = 1;

  workCodeCategoryId: number;

  clientLanguageId: number;

  selectedCaseId: number;

  cases: DataSource;

  workcodes: DataSource;

  billingTypes: DataSource;

  registrationId: any;

  registrationDetail: any;

  detailId: number;

  reLoadingParameters: boolean = false;

  relatedChargesPopupVisible: boolean = false;

  description: string;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor(
    private WorkCodeSvc: WorkcodeService,
    private TimeRegistrationSvc: RegistrationService,
    private CaseSvc: CaseService
  ) {
    this.onWorkCodeSelectionChanged =
      this.onWorkCodeSelectionChanged.bind(this);
    this.onBillingTypeChanged = this.onBillingTypeChanged.bind(this);
    this.onCaseChanged = this.onCaseChanged.bind(this);

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseDetailedDescription',
    });

    this.billingTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'BillingTypeId',
        loadUrl: `${this.url}TimeRegistration/BillingType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'BillingTypeLabel',
    });

    this.loadWorkCodeLookup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.datasource && changes.entity && this.entity?.RegistrationId) {
      this.registrationId = this.entity.RegistrationId;
      this.EmployeeId = this.entity.EmployeeId;

      this.datasource = new DataSource({
        store: AspNetData.createStore({
          key: 'RegistrationDetailId',
          loadUrl: `${this.url}TimeRegistration/Registration/Detail/${this.registrationId}`,
          insertUrl: `${this.url}TimeRegistration/Registration/Detail`,
          updateUrl: `${this.url}TimeRegistration/Registration/Detail`,
          deleteUrl: `${this.url}TimeRegistration/Registration/Detail`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            if (this.registrationId) {
              ajaxOptions.data.RegistrationId = this.registrationId;
            }
          },
        }),
      });
    }
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEditingStart = (e) => {
    const data = e.data;

    //get client language id
    this.CaseSvc.getAccountAddressLanguage(data.CaseRecordId).subscribe(
      (data) => {
        this.clientLanguageId = data.LanguageId;
      }
    );

    //get workcode category id
    switch (data.BillingType) {
      // 1 = Billable
      case 1:
        this.workCodeCategoryId = 5;
        break;
      // 3 = Billable through forfait - Admin
      case 3:
        this.workCodeCategoryId = 14;
        break;
      // 4 = Billable through forfait - Intel
      case 4:
        this.workCodeCategoryId = 12;
        break;
      // 6 = Non-Billable
      case 6:
        this.workCodeCategoryId = 13;
        break;
      default:
        this.workCodeCategoryId = 5;
        break;
    }

    this.loadWorkCodeLookup(this.workCodeCategoryId, this.clientLanguageId);
  };

  onInitNewRow(e) {
    e.promise = this.TimeRegistrationSvc.getHourlyRate(this.EmployeeId, null)
      .toPromise()
      .then((hourlyRate) => {
        e.data.HourlyRate = hourlyRate;
        e.data.WorkedOn = new Date().toISOString();
        e.data.Hours = 0;
        e.data.Minutes = 0;
        e.data.TotalAmount = 0;
        //generate random unique guid
        e.key = uuidv4();
      });

    this.resetLookups();
  }

  resetLookups() {
    this.billingTypes.searchValue('');
    this.billingTypes.reload();

    this.cases.searchValue('');
    this.cases.reload();

    this.loadWorkCodeLookup();
  }

  onBillingTypeChanged(newData, value, currentRowData) {
    newData.BillingType = value;
    switch (value) {
      // 1 = Billable
      case 1:
        this.workCodeCategoryId = 5;
        break;
      // 3 = Billable through forfait - Admin
      case 3:
        this.workCodeCategoryId = 14;
        break;
      // 4 = Billable through forfait - Intel
      case 4:
        this.workCodeCategoryId = 12;
        break;
      // 6 = Non-Billable
      case 6:
        this.workCodeCategoryId = 13;
        break;
      default:
        this.workCodeCategoryId = 5;
        break;
    }

    this.loadWorkCodeLookup(this.workCodeCategoryId, this.clientLanguageId);
  }

  onWorkCodeSelectionChanged(newData, value, currentRowData) {
    return this.getWorkCodeDescriptionPromise(value).then((data) => {
      newData.WorkCodeId = value;
      newData.Description = data;
    });
  }

  onCaseChanged(newData, value, currentRowData) {
    this.reLoadingParameters = true;

    this.selectedCaseId = value;

    const promises = [];

    if (this.EmployeeId && value !== undefined && value !== null) {
      promises.push(
        this.TimeRegistrationSvc.getHourlyRate(
          this.EmployeeId,
          value
        ).toPromise()
      );
    }

    if (value !== undefined && value !== null) {
      promises.push(
        new Promise<void>((resolve, reject) => {
          this.CaseSvc.getAccountAddressLanguage(value).subscribe((data) => {
            this.clientLanguageId = data.LanguageId;
            this.loadWorkCodeLookup(
              this.workCodeCategoryId,
              this.clientLanguageId
            );
            resolve();
          }, reject);
        })
      );
    }

    return Promise.all(promises)
      .then(([hourlyRate, _]) => {
        newData.HourlyRate = hourlyRate;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        newData.CaseRecordId = value;
        this.reLoadingParameters = false;
      });
  }

  onHoursChanged(newData, value, currentRowData) {
    newData.Hours = value;

    if (currentRowData.HourlyRate != null) {
      let TotalTime: number = +value + +currentRowData.Minutes / 60;
      newData.TotalAmount = Math.trunc(TotalTime * currentRowData.HourlyRate);
    } else if (currentRowData.TotalAmount != null) {
      newData.Hours = currentRowData.TotalAmount / value;
    }
  }

  onMinutesChanged(newData, value, currentRowData) {
    newData.Minutes = value;

    if (currentRowData.HourlyRate != null) {
      let TotalTime: number = +currentRowData.Hours + +value / 60;
      newData.TotalAmount = Math.trunc(TotalTime * currentRowData.HourlyRate);
    } else if (currentRowData.TotalAmount != null) {
      newData.Hours = currentRowData.TotalAmount / value;
    }
  }

  onTotalAmountChanged(newData, value, currentRowData) {
    newData.TotalAmount = value;

    const hours = Math.floor(value / currentRowData.HourlyRate);

    const remainingMinutes = value % currentRowData.HourlyRate;
    const minutes = Math.round(
      (remainingMinutes / currentRowData.HourlyRate) * 60
    );

    newData.Hours = hours;
    newData.Minutes = minutes;
  }

  onHourlyRateChanged(newData, value, currentRowData) {
    newData.HourlyRate = value;

    if (currentRowData.Hours != null) {
      let TotalTime: number =
        +currentRowData.Hours + +currentRowData.Minutes / 60;
      newData.TotalAmount = Math.trunc(TotalTime * value);
    } else if (currentRowData.TotalAmount != null) {
      newData.Hours = currentRowData.TotalAmount / value;
    }
  }

  // Load the lookup for the workcodes
  // The workcode category is determined by the billing type
  loadWorkCodeLookup(
    workcodeCategory: number | null = null,
    workcodeLanguageId: number | null = null
  ) {
    this.workcodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/WorkCode/lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
          if (workcodeCategory) {
            ajaxOptions.data.WorkCodeCategoryId = workcodeCategory;
          }

          if (workcodeLanguageId) {
            ajaxOptions.data.WorkCodeLangId = workcodeLanguageId;
          }
        },
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });

    this.workcodes.load();
  }

  getWorkCodeDescriptionPromise(WorkCodeId) {
    return new Promise((resolve, reject) => {
      this.WorkCodeSvc.getWorkCode(WorkCodeId, this.clientLanguageId).subscribe(
        (data) => {
          resolve(data.InvoiceDescription);
        }
      );
    });
  }

  onContentReady(e: ContentReadyEvent) {
    this.sendNewTimes();
  }

  onRowUpdated(e: RowUpdatedEvent) {
    this.sendNewTimes();
  }

  onRowInserted(e: RowInsertedEvent) {
    this.sendNewTimes();
  }

  sendNewTimes() {
    if (this.datasource?.items().length > 0) {
      const totalHours = this.datasource
        .items()
        .reduce((acc, item) => acc + item.Hours, 0);

      const totalMinutes = this.datasource
        .items()
        .reduce((acc, item) => acc + item.Minutes, 0);

      const nonBillableHours = this.datasource
        .items()
        .filter((item) => item.BillingType == 6)
        .reduce((acc, item) => acc + item.Hours, 0);

      const nonBillableMinutes = this.datasource
        .items()
        .filter((item) => item.BillingType == 6)
        .reduce((acc, item) => acc + item.Minutes, 0);

      const billableHours = this.datasource
        .items()
        .filter((item) => item.BillingType != 6)
        .reduce((acc, item) => acc + item.Hours, 0);

      const billableMinutes = this.datasource
        .items()
        .filter((item) => item.BillingType != 6)
        .reduce((acc, item) => acc + item.Minutes, 0);

      const totalBillableAmount = this.datasource
        .items()
        .filter((item) => item.BillingType != 6)
        .reduce((acc, item) => acc + item.TotalAmount, 0);

      const times = {
        totalHours: totalHours,
        totalMinutes: totalMinutes,
        nonBillableHours: nonBillableHours,
        nonBillableMinutes: nonBillableMinutes,
        billableHours: billableHours,
        billableMinutes: billableMinutes,
        totalBillableAmount: totalBillableAmount,
      };

      this.timesChange.emit(times);
    }
  }

  openRelatedChargesPopup = () => {
    this.relatedChargesPopupVisible = true;
  };

  onRelatedChargesPopupVisibleChange(e: any) {
    this.relatedChargesPopupVisible = e;
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.parentType == 'dataRow' && e.dataField == 'Description') {
      e.editorName = 'dxTextArea';
      e.editorOptions = {
        validationMessageMode: 'always',
        autoResizeEnabled: true,
        minHeight: '100px',
        value: e.value,
        onValueChanged: (args) => {
          e.setValue(args.value);
        },
      };
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxNumberBoxModule,
    DxLoadPanelModule,
    DxButtonModule,
    DxLookupModule,
    TimeRegistrationChargeByCaseModule,
  ],
  declarations: [RegistrationDetailsFormComponent],
  exports: [RegistrationDetailsFormComponent],
})
export class RegistrationDetailsFormModule {}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { PermissionEditModule } from 'src/app/components/permissions/permissions-edit/permissions-edit.component';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'portal-admin-team-hierarchy',
  templateUrl: './portal-admin-team-hierarchy.component.html',
  styleUrls: ['./portal-admin-team-hierarchy.component.scss'],
})
export class AdminPortalTeamHierarchyComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: DataSource;
  employees: DataSource;

  formData: any = {};

  saveButtonOptions: any;
  cancelButtonOptions: any;

  url: string;

  constructor() {
    this.url = environment.CalystaApiBaseURL + 'api';

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
        (
          this.dataSource.store().update(this.formData.Id, this.formData) as any
        ).done(() => {
          this.formData = {};
          this.refreshData();
        });
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/Employee/Hierarchy`,
        updateUrl: `${this.url}/Employee/Hierarchy`,
      }),
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Employee/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEdit = (e) => {
    this.formData.Id = e.row.data.Id;
    this.formData.EmployeesId = e.row.data.Children.map((x) => x.EmployeeId);

    var rowIndex = e.row.rowIndex;

    this.dataGrid.instance.editRow(rowIndex);
  };

  onEmployeesChanged = (e) => {
    this.formData.EmployeesId = e.value;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    PermissionEditModule,
  ],
  declarations: [AdminPortalTeamHierarchyComponent],
  exports: [],
})
export class AdminPortalTeamHierarchyModule {}

<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Work Code Tarifs" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
      [showColumnLines]="true"
    >
      <dxo-grouping #expand [autoExpandAll]="false" />
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="true" />
      <dxo-editing
        mode="popup"
        [allowAdding]="isPricesMaintainer"
        [allowUpdating]="isPricesMaintainer"
        [allowDeleting]="isPricesMaintainer"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Work Code Tarif'"
          height="auto"
          width="35vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
            <dxi-item dataField="WorkCodeId">
              <dxi-validation-rule
                type="required"
                message="WorkCode is required"
              />
            </dxi-item>
            <dxi-item dataField="AgentId" />
            <dxi-item
              dataField="ClientId"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: clients.store(),
                displayExpr: 'LegalName',
                valueExpr: 'NameId',
                searchMode: 'contains',
                searchExpr: 'LegalName',
                minSearchLength: 3,
                showDataBeforeSearch: false,
                searchEnabled: true,
                validationMessageMode: 'always',
              }"
            />
          </dxi-item>
          <dxi-item
            itemType="group"
            [colCount]="2"
            [colSpan]="2"
            caption="Parameters"
          >
            <dxi-item dataField="CaseTypeId" />
            <dxi-item dataField="CountryId" />
            <dxi-item dataField="ApplicationTypeId" />
            <dxi-item dataField="ServiceLevelId" />
          </dxi-item>
          <dxi-item
            itemType="group"
            [colCount]="2"
            [colSpan]="2"
            caption="Validity & Tarif"
          >
            <dxi-item
              dataField="ValidFrom"
              editorType="dxDateBox"
              [editorOptions]="{
                validationMessageMode: 'always',
                acceptCustomValue: false,
                openOnFieldClick: true
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Valid From is required"
              />
            </dxi-item>
            <dxi-item
              dataField="ValidUntil"
              editorType="dxDateBox"
              [editorOptions]="{
                validationMessageMode: 'always',
                acceptCustomValue: false,
                openOnFieldClick: true
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Valid Until is required"
              />
            </dxi-item>
            <dxi-item dataField="Amount" [colSpan]="1">
              <dxi-validation-rule
                type="required"
                message="Unit Price is required"
              />
              <dxo-label text="Unit Price" />
            </dxi-item>
            <dxi-item dataField="CurrencyId" [colSpan]="1">
              <dxi-validation-rule
                type="required"
                message="Currency is required"
              />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="10vw" />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <case-type-lookup [valueChange]="onCaseTypeChanged" />
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <country-lookup [valueChange]="onCountryChanged" />
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <service-level-lookup [valueChange]="onServiceLevelChanged" />
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <dx-button
            [text]="isGrouped ? 'Ungroup' : 'Group'"
            type="default"
            stylingMode="contained"
            (onClick)="switchGroup()"
          />
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <dx-check-box
            [value]="showExpiredOne"
            text="Show expired"
            (onValueChanged)="switchShowExpired($event)"
          />
        </dxi-item>

        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new workcode tarif"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        *ngIf="!isGrouped"
        dataField="WorkCodeSummary"
        caption="WorkCode"
        sortOrder="asc"
        [allowSearch]="true"
        dataType="string"
      />
      <dxi-column
        dataField="WorkCodeId"
        caption="Work Code"
        alignment="center"
        width="5%"
        [visible]="false"
      >
        <dxo-lookup
          [dataSource]="workCodes.store()"
          displayExpr="WorkCodeLabel"
          valueExpr="WorkCodeId"
        />
      </dxi-column>
      <dxi-column
        dataField="CaseTypeId"
        calculateDisplayValue="CaseType"
        caption="Case Type"
        alignment="center"
        width="6%"
      >
        <dxo-lookup
          [dataSource]="caseTypes.store()"
          displayExpr="CaseTypeLabel"
          valueExpr="CaseTypeId"
        />
      </dxi-column>
      <dxi-column
        dataField="CountryId"
        calculateDisplayValue="CountryLabel"
        caption="Country Label"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="countries.store()"
          displayExpr="Label"
          valueExpr="CountryId"
          [allowClearing]="true"
        />
      </dxi-column>
      <dxi-column dataField="ServiceLevelId" caption="Service Level">
        <dxo-lookup
          [dataSource]="serviceLevels.store()"
          displayExpr="ServiceLevelLabel"
          valueExpr="ServiceId"
          [allowClearing]="true"
        />
      </dxi-column>
      <dxi-column dataField="ClientId" caption="Client">
        <dxo-lookup
          [dataSource]="clients.store()"
          displayExpr="LegalName"
          valueExpr="NameId"
          [allowClearing]="true"
        />
      </dxi-column>
      <dxi-column dataField="Amount" caption="Unit Price" width="5%" />
      <dxi-column dataField="CurrencyId" caption="Valuta" width="5%">
        <dxo-lookup
          [dataSource]="currencies.store()"
          displayExpr="CurrencyLabel"
          valueExpr="CurrencyId"
        />
      </dxi-column>
      <dxi-column
        dataField="ValidFrom"
        caption="Valid From"
        dataType="date"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="ValidUntil"
        caption="Valid Until"
        dataType="date"
        alignment="center"
        width="7%"
      />
      <dxi-column dataField="AgentId" caption="Agent" [visible]="false">
        <dxo-lookup
          [dataSource]="agents.store()"
          displayExpr="Name"
          valueExpr="nameId"
          [allowClearing]="true"
        />
      </dxi-column>
      <dxi-column
        dataField="ApplicationTypeId"
        caption="Application Type"
        [visible]="false"
      >
        <dxo-lookup
          [dataSource]="applicationTypes.store()"
          displayExpr="ApplicationTypeLabel"
          valueExpr="applicationTypeId"
          [allowClearing]="true"
        />
      </dxi-column>
      <dxi-column
        *ngIf="isGrouped"
        dataField="WorkCodeSummary"
        caption="WorkCode"
        [visible]="false"
        groupCellTemplate="workCodeGroupCellTemplate"
        groupIndex="0"
        sortOrder="asc"
        dataType="string"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />
      <div *dxTemplate="let data of 'workCodeGroupCellTemplate'">
        <div>
          {{ data.value }}
        </div>
      </div>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceConfigurationPricelist"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>

export enum AppPermissions {
  // Base permissions used currently
  ADMIN = 'Admin',
  INVOICING = 'Invoicing',
  COST = 'Cost',
  FINANCIAL = 'Financial',
  MANAGER = 'Manager',
  DATA_QUALITY = 'DataQuality',
  RENEWAL = 'Renewal',
  TIME_REGISTRATION = 'TimeRegistration',
  BUDGET_MANAGEMENT = 'Budget_Management',
  CLIENT_PORTAL = 'ClientPortal',
  PROSPECTION = 'Prospection',
  EPO = 'EPO',
  FINANCIAL_PRICEMAINTAINER = 'Financial_PricesMaintainer',
  HR = 'HR',

  //new permissions to be set
  ANALYTICS_FULL = 'Analytics_Full',
  STATS_REPORTING = 'StatsReporting',
  INVOICING_BASIC = 'Invoicing_Basic',
  SUBSIDIES_BASIC = 'Subsidies_Basic',
  BADPAYERS_MANAGEMENT = 'BadPayers_Management',
  INVOICING_REPORTING = 'Invoicing_Reporting',
  INVOICING_FULL = 'Invoicing_Full',
  INVOICING_CONFIGURATION = 'Invoicing_Configuration',
  WORKCODES_CONFIGURATION = 'Workcodes_Configuration',
  BANK_REPORTING = 'Bank_Reporting',
  PAVIS = 'PAVIS',
  RENEWALS_CONFIGURATION = 'Renewals_Configuration',
  TIMEREGISTRATION_CONFIGURATION = 'TimeRegistration_Configuration',
  TEAMS_MANAGER = 'TeamsManager',
  GENERAL_MANAGEMENT = 'General_Management',
  TEMPLATE_ENGINE_CONFIGURATION = 'TemplateEngine_Configuration',
  COMPANY_MANAGEMENT_FULL = 'Company_Management_Full',
  EMPLOYEE_MANAGEMENT = 'Employee_Management',
  COMPANY_MANAGEMENT_BASIC = 'Company_Management_Basic',
  ClIENT_PORTAL_MANAGEMENT_FULL = 'ClientPortal_Management_Full',
  CASESCONVERSION_INFO = 'CasesConversion_Info',
  CRM_BASIC = 'CRM_Basic',
  CRM_CONFIGURATION = 'CRM_Configuration',
  TIMEREGISTRATION_MANAGEMENT = 'TimeRegistration_Management',
  EXTERNAL = 'External',
}

<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Costs Overview" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
      [columnAutoWidth]="true"
      width="100%"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-filter-row [visible]="true" />

      <dxo-scrolling useNative="false" />
      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Costs Overview'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <div class="number-container">
            <div class="number-title">Invoice Number</div>
            <dx-number-box
              (onValueChanged)="onInvoiceValueChanged($event)"
              width="200px"
              [value]="undefined"
            />
          </div>
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Invoice Date Range</div>
            <dx-date-range-box
              [startDate]="thisYear"
              [endDate]="now"
              (onValueChanged)="onDateChanged($event)"
              [showClearButton]="false"
              [width]="300"
              [max]="now"
            />
          </div>
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <div class="number-container">
            <div class="number-title">Last InvoiceNumber</div>
            <dx-number-box
              (onValueChanged)="onLastInvoiceNumberValueChanged($event)"
              width="200px"
              [value]="undefined"
            />
          </div>
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Id" caption="Id" [visible]="false" />
      <dxi-column dataField="InvoiceId" caption="Invoice Number" />
      <dxi-column
        dataField="BookingDate"
        caption="Booking Date"
        dataType="date"
      />
      <dxi-column
        dataField="InvoiceDate"
        caption="Invoice Date"
        dataType="date"
      />
      <dxi-column dataField="NonVatAmount" caption="Amount Non Vat" />
      <dxi-column dataField="VatAmount" caption="Vat Amount" />
      <dxi-column dataField="VatRate" caption="Vat Rate" />
      <dxi-column dataField="TotalAmount" caption="Total Amount" />
      <dxi-column
        dataField="Currency"
        caption="Currency Code"
        alignment="center"
      />
      <dxi-column dataField="ExchangeRate" caption="Exchange Rate" />
      <dxi-column dataField="NonVatAmountEUR" caption="Amount non VAT EUR" />
      <dxi-column dataField="VatAmountEUR" caption="VAT Amount EUR" />
      <dxi-column dataField="AmountEUR" caption="Amount EUR" />
      <dxi-column dataField="Description" caption="Description" />
      <dxi-column
        dataField="BudgetPeriodStart"
        caption="Budget Period Start"
        dataType="date"
      />
      <dxi-column
        dataField="BudgetPeriodEnd"
        caption="Budget Period End"
        dataType="date"
      />
      <dxi-column
        dataField="FinancialDeductablePerc"
        caption="Financial Deductable Perc"
      />
      <dxi-column dataField="ProviderReference" caption="Provider Reference" />
      <dxi-column dataField="ProviderId" caption="Provider Id" />
      <dxi-column dataField="ProviderAccount" caption="Provider Account" />
      <dxi-column dataField="ProviderSwiftCode" caption="Provider Swift Code" />
      <dxi-column dataField="ProviderVatNumber" caption="Provider VAT Number" />
      <dxi-column dataField="ProviderName" caption="Provider Name" />
      <dxi-column dataField="ProviderStreet" caption="Provider Street" />
      <dxi-column
        dataField="ProviderPostalCode"
        caption="Provider Postal Code"
      />
      <dxi-column dataField="CostItemId" caption="Cost Item Id" />
      <dxi-column dataField="CostItem" caption="Cost Item" alignment="center" />
      <dxi-column
        dataField="EmployeeId"
        caption="Employee Id"
        [visible]="false"
      />
      <dxi-column dataField="EmployeeName" caption="Employee Name" />
      <dxi-column
        dataField="EmployeeCarLicensePlate"
        caption="Employee License Plate"
      />
      <dxi-column dataField="CalystaReference" caption="Calysta Reference" />
      <dxi-column dataField="CreditDebit" caption="CreditDebit" />
      <dxi-column
        dataField="PaymentStatus"
        caption="Payement Status"
        [visible]="false"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceReportingCostOverview"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>

<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="InvoiceDetailsDataSource"
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
  [remoteOperations]="true"
>
  <dxo-editing mode="row" [allowUpdating]="!ReadOnly" />
  <dxo-load-panel [showPane]="true" />

  <dxi-column
    dataField="CalystaReference"
    [width]="200"
    [allowEditing]="false"
  />
  <dxi-column dataField="Description" />
  <dxi-column
    dataField="NonVatAmount"
    [width]="100"
    caption="Amount"
    [allowEditing]="false"
  >
    <dxo-format type="currency" [precision]="2" />
  </dxi-column>
  <dxi-column
    dataField="VatAmount"
    [width]="100"
    caption="VAT"
    [allowEditing]="false"
  >
    <dxo-format type="currency" [precision]="2" />
  </dxi-column>
  <dxi-column
    dataField="Vatrate"
    [width]="75"
    caption="VAT(%)"
    [allowEditing]="false"
  >
  </dxi-column>
  <dxi-column
    dataField="Amount"
    [width]="100"
    caption="Total"
    [allowEditing]="false"
  >
    <dxo-format type="currency" [precision]="2" />
  </dxi-column>
  <dxi-column dataField="WorkCodeLabel" caption="Work Code" />

  <dxo-paging [pageSize]="5" />
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 20]"
    [displayMode]="'compact'"
    [visible]="true"
  />
  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridFinanceDraftInvoiceDetails"
  />
</dx-data-grid>

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxDateRangeBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxLoadPanelModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { CostStatusLookupModule } from 'src/app/components/lookups/cost-status-lookup/cost-status-lookup.component';
import { RangeDatePickerModule } from 'src/app/components/utils/range-date-picker/range-date-picker.component';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { CostService } from 'src/app/services/financial/cost/cost.service';
import { environment } from 'src/environments/environment';
import { InvoicesInDetailsModule } from './invoices-in-details/invoices-in-details.component';
import { PDFPreviewModule } from 'src/app/components/library/pdf-preview/pdf-preview.component';
import {
  EditCanceledEvent,
  EditorPreparingEvent,
  InitNewRowEvent,
  RowUpdatedEvent,
} from 'devextreme/ui/data_grid';
import { Costline } from 'src/app/types/cost/costline';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'finance-invoices-in',
  templateUrl: './finance-invoices-in.component.html',
  styleUrls: ['./finance-invoices-in.component.scss'],
})
export class FinanceInvoicesInComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  blobFile: any;
  fileLoading: boolean = false;
  documentLinked: boolean = false;

  dataSource: any;
  providersDataSource: any;
  costStatusDataSource: any;
  costItemsDataSource: any;

  url: string;

  CostHeaderId: number = null;
  CostHeader: any = null;

  thisYear = new Date(new Date().getFullYear(), 0, 1);
  now = new Date();

  statusId: number;

  documentLoadPaneVisible: boolean = false;

  costLines: Costline[] = [];

  currencies: DataSource;

  employees: DataSource;

  cases: DataSource;

  AmountInstance: any;
  VatInstance: any;
  TotalInstance: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor(
    private costService: CostService,
    private authService: AuthService
  ) {
    this.url = costService.getUrl();
    this.onRowInserted = this.onRowInserted.bind(this);
    this.onDetailsChanged = this.onDetailsChanged.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
    this.onShownEditPopup = this.onShownEditPopup.bind(this);

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CostHeaderId',
        loadUrl: `${this.url}/costheader`,
        insertUrl: `${this.url}/costheader`,
        updateUrl: `${this.url}/costheader`,
        deleteUrl: `${this.url}/costheader`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
          ajaxOptions.data = ajaxOptions.data || {};

          if (this.statusId) {
            ajaxOptions.data.statusId = this.statusId;
          }

          if (this.thisYear && this.now) {
            ajaxOptions.data.startDate = this.thisYear.toISOString();
            ajaxOptions.data.endDate = this.now.toISOString();
          }
        },
      }),
    });

    this.providersDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProviderId',
        loadUrl: `${environment.CalystaApiBaseURL}api/provider/lookup`,
      }),
      sort: 'Name',
    });

    this.costStatusDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CostStatusId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Costs/CostStatus/Lookup`,
      }),
    });

    this.costItemsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CostItemId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Costs/CostItem/Lookup`,
      }),
    });

    this.currencies = new DataSource({
      store: AspNetData.createStore({
        key: 'CurrencyId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Currency/lookup`,
      }),
      sort: 'CurrencyLabel',
    });

    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'caseId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'employeeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Employee/lookup`,
      }),
      sort: ['FirstName', 'LastName'],
    });
  }

  onInitNewRow(e: InitNewRowEvent) {
    e.data.BookingDate = new Date();
    this.CostHeaderId = null;
    this.CostHeader = {};
    this.documentLinked = false;
    this.blobFile = null;
  }

  onEditingStart(e: any) {
    if (e.key) {
      this.CostHeaderId = e.key;
    } else {
      this.CostHeaderId = null;
    }

    if (e.data.DocumentLink != null && e.data.DocumentLink != '') {
      this.documentLinked = true;
      this.costService
        .downloadInvoiceDocument(e.key)
        .pipe(
          finalize(() => {
            this.documentLoadPaneVisible = false;
          })
        )
        .subscribe({
          next: (x) => {
            this.blobFile = x;
          },
          error: (err) => {
            const errorMessage: string | null =
              err?.error?.Errors?.GeneralErrors[0];
            notify(errorMessage ?? 'Unknown error', 'error', 3000);
          },
        });
    } else {
      this.blobFile = null;
    }
  }

  onRowInserted(e) {
    this.costService.insertCostLines(this.costLines, e.key).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });

    if (this.blobFile) {
      this.uploadDocument(this.blobFile, e.key).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
    }

    this.blobFile = null;
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  // Set the blobFile back to null so we don't show the old file.
  onEditCanceled($event: EditCanceledEvent) {
    this.blobFile = null;
  }

  // Set the blobFile back to null so we don't show the old file.
  onRowUpdated($event: RowUpdatedEvent) {
    this.blobFile = null;
    this.refreshData();
  }

  // Editor Options.
  // Needed to update the amounts when the details change.
  NonVatEditorOptions = {
    onInitialized: (e: any) => {
      this.AmountInstance = e.component;
    },
    format: { precision: 2, type: 'fixedPoint' },
    disabled: true,
  };

  VatEditorOptions = {
    onInitialized: (e: any) => {
      this.VatInstance = e.component;
    },
    format: { precision: 2, type: 'fixedPoint' },
    disabled: true,
  };

  TotalEditorOptions = {
    onInitialized: (e: any) => {
      this.TotalInstance = e.component;
    },
    format: { precision: 2, type: 'fixedPoint' },
    disabled: true,
  };

  // Update the Invoice when the details have been adapted.
  // Linked to the output of the details form.
  onDetailsChanged(e: Costline[]) {
    this.costLines = e;
    this.updateTotals();
  }

  onFileChanged = (e: any) => {
    if (this.fileLoading) return;

    this.blobFile = e.value[0];

    if (this.CostHeaderId) {
      this.fileLoading = true;
      this.uploadDocument(this.blobFile, this.CostHeaderId).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.documentLinked = true;
          this.fileLoading = false;
          this.refreshData();
        },
      });
    }
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onStatusChanged = (e: number): void => {
    this.statusId = e;
    this.dataGrid.instance.getDataSource().reload();
  };

  onDateChanged = (e: any): void => {
    this.thisYear = e.value[0];
    this.now = e.value[1];

    this.dataGrid.instance.getDataSource().reload();
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Invoices In');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'InvoicesIn.xlsx'
        );
      });
    });
  }

  uploadDocument(document: any, costHeaderId: number) {
    const formData = new FormData();
    formData.append('Document', document);
    return this.costService.uploadInvoiceDocument(formData, costHeaderId);
  }

  hasDocument = (e: any) => {
    return e.row.data.DocumentLink ? true : false;
  };

  openDocument = (e: any) => {
    //open document from link in a new tab (document is a pdf)
    window.open(e.row.data.DocumentLink, '_blank');
  };

  updateTotals() {
    let AmountNonVat = 0;
    let VatAmount = 0;
    let TotalAmount = 0;

    this.costLines.forEach((x) => {
      AmountNonVat += x.NonVatAmount;
      VatAmount += x.VatAmount;
      TotalAmount += x.Amount;
    });

    this.AmountInstance.option('value', AmountNonVat);
    this.VatInstance.option('value', VatAmount);
    this.TotalInstance.option('value', TotalAmount);
  }

  onShownEditPopup(e: any) {
    if (this.documentLinked) this.documentLoadPaneVisible = true;
  }

  onClickRedirectToAction(): void {
    //redirect to page named provider-list in another tab
    window.open('/#/provider-list', '_blank');
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.parentType === 'dataRow' && e.dataField === 'Description') {
      e.editorName = 'dxTextArea';
    }

    if (this.CostHeader !== null) {
      if (e.parentType === 'dataRow' && e.dataField === 'BudgetPeriodStart') {
        this.CostHeader.BudgetPeriodStart = e.row.data.BudgetPeriodStart;
      }

      if (e.parentType === 'dataRow' && e.dataField === 'BudgetPeriodEnd') {
        this.CostHeader.BudgetPeriodEnd = e.row.data.BudgetPeriodEnd;
      }

      if (e.parentType === 'dataRow' && e.dataField === 'EmployeeId') {
        this.CostHeader.EmployeeId = e.row.data.EmployeeId;
      }

      if (e.parentType === 'dataRow' && e.dataField === 'CaseId') {
        this.CostHeader.CaseId = e.row.data.CaseId;
      }

      if (e.parentType === 'dataRow' && e.dataField === 'CostItemId') {
        this.CostHeader.CostItemId = e.row.data.CostItemId;
      }
    }
  }

  customizeItem = (item) => {
    if (item && item.itemType === 'group' && item.caption === 'Document') {
      const gridInstance = this.dataGrid.instance;
      const editRowKey = gridInstance.option('editing.editRowKey');
      const rowIndex = gridInstance.getRowIndexByKey(editRowKey);
      const row = gridInstance.getVisibleRows()[rowIndex]?.data;

      if (row.InvoiceId === undefined) {
        item.caption = 'Document';
      } else {
        //change the text of the group item
        item.caption = row.InvoiceId.toString();
      }
    }
  };

  setCellValue(newData, value) {
    let column = <any>this;
    column.defaultSetCellValue(newData, value);
  }

  isUserGuest(): boolean {
    return this.authService.isUserGuest();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    TitleBarModule,
    DxScrollViewModule,
    RangeDatePickerModule,
    CostStatusLookupModule,
    InvoicesInDetailsModule,
    PDFPreviewModule,
    DxLoadPanelModule,
    DxDateRangeBoxModule,
    DxFileUploaderModule,
  ],
  providers: [],
  declarations: [FinanceInvoicesInComponent],
  exports: [],
})
export class FinanceInvoicesInModule {}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { TitleBarModule } from 'src/app/components';
import { PavisService } from 'src/app/services/renewal/pavis/pavis.service';
import { RenewalService } from 'src/app/services/renewal/renewal.service';

@Component({
  selector: 'renewal-pavis-send-to-pavis',
  templateUrl: './renewal-pavis-send-to-pavis.component.html',
  styleUrls: ['./renewal-pavis-send-to-pavis.component.scss'],
})
export class RenewalPavisSendToPavisComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  constructor(
    private renewalService: RenewalService,
    private pavisService: PavisService
  ) {
    this.url = this.renewalService.getUrl();
    this.openPatricia = this.openPatricia.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'PavisInstructionId',
        loadUrl: `${this.url}/Pavis/PavisInstruction`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  transferToPavis = () => {
    const selectedRowKeys = this.dataGrid.instance.getSelectedRowKeys();
    if (selectedRowKeys.length === 0) {
      alert('Please select at least one row');
      return;
    }

    const data = selectedRowKeys;

    this.pavisService.transferToPavis(data).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  openPatricia(e) {
    //open new tab on url http://patricia.calysta.eu/pat/#!case-view/ {data.CaseNumber}
    const caseNumber = e.row.data.CaseNumber;
    window.open(`http://patricia.calysta.eu/pat/#!case-view/${caseNumber}`);
    e.event.preventDefault();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
  ],
  declarations: [RenewalPavisSendToPavisComponent],
  exports: [],
})
export class RenewalPavisSendToPavisModule {}

<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Invoices Out" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      [rowAlternationEnabled]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onEditorPreparing)="onEditorPreparing($event)"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      (onSelectionChanged)="selectionChangedHandler($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-filter-row [visible]="true" />
      <dxo-selection mode="multiple" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="!isUserGuest()"
        [allowDeleting]="false"
      />
      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Invoices Out'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Invoice Date Range</div>
            <dx-date-range-box
              title="Invoice Date"
              [startDate]="thisYear"
              [endDate]="now"
              (onValueChanged)="onDateChanged($event)"
              [showClearButton]="true"
              [width]="300"
              [max]="now"
            />
          </div>
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <dx-button
            text="Download Selected Invoice's PDFs"
            (onClick)="Download()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column type="buttons" cellTemplate="buttons" />
      <div *dxTemplate="let cellInfo of 'buttons'">
        <dx-button
          icon="fas fa-file-pdf"
          (onClick)="onLinkPdf(cellInfo.data)"
          [disabled]="!cellInfo.data.PDFFileLink"
        />
        <dx-button
          icon="fas fa-file-excel"
          (onClick)="onLinkExcel(cellInfo.data)"
          [disabled]="!cellInfo.data.ExcelFileLink"
        />
      </div>
      <dxi-column
        dataField="InvoiceHeaderId"
        [visible]="false"
        caption="Id"
        [allowSearch]="false"
        dataType="number"
      />
      <dxi-column
        dataField="InvoiceNumber"
        caption="Invoice"
        alignment="center"
        [allowSearch]="true"
        dataType="string"
      />
      <dxi-column
        dataField="InvoiceDate"
        dataType="date"
        caption="Invoice Date"
        sortOrder="desc"
        [sortIndex]="0"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="PayerName"
        caption="Payer"
        alignment="center"
        dataType="string"
        [allowSearch]="true"
      />
      <dxi-column
        dataField="Responsible"
        caption="Client Responsible"
        alignment="center"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="NonVatAmount"
        caption="Amount w/o VAT"
        [allowSearch]="false"
      >
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="VatAmount" caption="VAT" [allowSearch]="false">
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="AmountTotal" caption="Total" [allowSearch]="false">
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="CurrencyCode"
        caption="Currency"
        [allowSearch]="false"
      />
      <dxi-column dataField="IsPayed" caption="Paid" [allowSearch]="false" />
      <dxi-column
        dataField="PaymentDaysOutstanding"
        caption="Payment Days Outstanding"
        [visible]="true"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="IsBadPayer"
        caption="Bad Payer"
        [allowSearch]="false"
      />
      <dxi-column dataField="Subsidy" caption="Subsidy" [allowSearch]="false" />
      <dxi-column
        dataField="IsFinalized"
        caption="Finalized"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="CreatedBy"
        caption="Created BY"
        [visible]="false"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="CreationDate"
        caption="Creation Date"
        dataType="date"
        [visible]="false"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="CaseReferencesList"
        caption="Cases"
        [allowSearch]="false"
        [visible]="false"
      />
      <dxi-column
        dataField="TemplateId"
        caption="Template"
        [allowSearch]="false"
        [visible]="false"
      >
        <dxo-lookup
          [dataSource]="templates.store()"
          valueExpr="TemplateId"
          displayExpr="TemplateLabel"
        />
      </dxi-column>
      <dxi-column type="buttons">
        <dxi-button icon="edit" [onClick]="onEdit" [visible]="!isUserGuest()" />
        <dxi-button
          icon="fa-solid fa-business-time"
          [onClick]="openRelatedTimesheets"
          [visible]="!isUserGuest()"
        />
      </dxi-column>

      <dxo-paging [pageSize]="30" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[10, 30, 50]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />
      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <dx-tab-panel>
          <dxi-item title="Details" icon="detailslayout">
            <div class="tab-panel-item">
              <finance-invoices-out-details
                [invoiceHeaderId]="details.data.InvoiceHeaderId"
              />
            </div>
          </dxi-item>
          <dxi-item title="Contact(s)" icon="group">
            <crm-contacts-list [nameId]="details.data.PayerId" />
          </dxi-item>
        </dx-tab-panel>
      </div>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceInvoicesOut"
      />
    </dx-data-grid>
    <finance-draft-invoice
      [InvoiceHeaderId]="editedRow?.InvoiceHeaderId"
      [(visible)]="isEditPopupOpened"
      (visibleChange)="onDraftClosed($event)"
    />
    <finance-invoices-out-time-encoded
      [(visible)]="isViewRelatedTimesheetsPopupOpened"
      [invoiceHeaderId]="editedRow?.InvoiceHeaderId"
    />
  </div>
</dx-scroll-view>
<dx-load-panel [visible]="loading" [position]="{ of: '#view-port' }" />

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { RowUpdatedEvent } from 'devextreme/ui/data_grid';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'instruction-grid',
  templateUrl: './instruction-grid.component.html',
  styleUrls: ['./instruction-grid.component.scss'],
})
export class InstructionGridComponent implements OnInit, OnChanges {
  url = environment.CalystaApiBaseURL + 'api/';

  @Input() EventConfigurationId: number = null;

  @Output() InstructionsUpdated = new EventEmitter<DataSource>();

  dataSource: any;
  InstructionTypesDataSource: DataSource;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.InstructionTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InstructionTypeId',
        loadUrl: `${this.url}Renewal/InstructionType`,

        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.EventConfigurationId != null) {
      // this.dataSource = new DataSource({
      //   store: AspNetData.createStore({
      //     key: 'EventConfigurationLineId',
      //     loadUrl: `${this.url}Renewal/EventConfigurationLine/${this.EventConfigurationId}`,
      //     onBeforeSend: (method, ajaxOptions) => {
      //       ajaxOptions.xhrFields = { withCredentials: false };
      //     },
      //   }),
      // });

      // this.dataSource = new AspNetData.CustomStore({
      //   key: 'EventConfigurationLineId',
      //   load: (loadOptions) => {
      //     return lastValueFrom(this.http.get(`${this.url}Renewal/EventConfigurationLine/${this.EventConfigurationId}`));
      //   },
      //   insert: (values) => {
      //     this.InstructionsUpdated.emit(this.dataSource);
      //     return;
      //   },
      //   update: (key, values) => {
      //     return AspNetData.update(key, values, `${this.url}Renewal/EventConfigurationLine`);
      //   },
      //   remove: (key) => {
      //     return AspNetData.remove(key, `${this.url}Renewal/EventConfigurationLine`);
      //   },
      // });
    }
  }

  onRowUpdated($event: RowUpdatedEvent) {
    this.InstructionsUpdated.emit(this.dataSource);
    }
}

@NgModule({
  imports: [CommonModule, DxDataGridModule],
  declarations: [InstructionGridComponent],
  exports: [InstructionGridComponent],
})
export class InstructionGridModule {}

import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxFormModule,
  DxDataGridModule,
  DxSelectBoxModule,
  DxDataGridComponent,
  DxPopupModule,
  DxButtonModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { InvoiceDetailService } from 'src/app/services/invoicing/Invoice/InvoiceDetailService.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'credit-lines-selection-form',
  templateUrl: './credit-lines-selection-form.component.html',
  styleUrls: ['./credit-lines-selection-form.component.scss'],
})
export class CreditLinesSelectionFormComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() invoiceHeaderId: number;

  @Input() visible: boolean = false;

  @Input() isReopened: boolean = false;

  @Output() save = new EventEmitter<any>();

  invoiceDetailsDataSource: DataSource;
  url: string;

  constructor(private InvoiceDetailSvc: InvoiceDetailService) {
    this.url = InvoiceDetailSvc.getUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.invoiceHeaderId.previousValue !=
      changes.invoiceHeaderId.currentValue
    ) {
      this.loadDataSource();
    }
  }

  ngOnInit() {
    this.loadDataSource();
  }

  loadDataSource() {
    this.invoiceDetailsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceDetailId',
        loadUrl: `${this.url}/all`,
        updateUrl: `${this.url}`,
        loadParams: { invoiceHeaderId: this.invoiceHeaderId },
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  onSaveClick() {
    var rows = this.dataGrid.instance.getSelectedRowKeys();

    this.InvoiceDetailSvc.creditInvoiceLines(rows, this.isReopened).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'error', 3000);
      },
      complete: () => {
        notify('Credit lines saved successfully', 'success');
        this.save.emit();
      },
    });

    this.visible = false;
  }

  onClose() {
    this.visible = false;
    this.save.emit();
  }

  hasRowsSelected() {
    if (this.dataGrid) {
      return this.dataGrid.instance.getSelectedRowKeys().length > 0;
    }

    return false;
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onShowing() {
    this.loadDataSource();
  }
}

@NgModule({
  declarations: [CreditLinesSelectionFormComponent],
  exports: [CreditLinesSelectionFormComponent],
  bootstrap: [CreditLinesSelectionFormComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    DxDataGridModule,
    FormPopupModule,
    DxSelectBoxModule,
    DxPopupModule,
    DxButtonModule,
  ],
})
export class CreditLinesSelectionFormModule {}

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FinanceCreditcollectAmountdueModule } from './finance-creditcollect-amountdue/finance-creditcollect-amountdue.component';
import {
  DxScrollViewModule,
  DxTabPanelModule,
  DxTabsModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { FinanceCreditcollectToPayModule } from './finance-creditcollect-topay/finance-creditcollect-topay.component';
import { FinanceCreditcollectReminderHistoryModule } from './finance-creditcollect-reminder-history/finance-creditcollect-reminder-history.component';

@Component({
  selector: 'finance-creditcollect',
  templateUrl: './finance-creditcollect.component.html',
  styleUrls: ['./finance-creditcollect.component.scss'],
})
export class FinanceCreditcollectComponent implements OnInit {
  selectedIndex = 0;

  constructor() {}

  ngOnInit() {}
}

@NgModule({
  imports: [
    CommonModule,
    FinanceCreditcollectAmountdueModule,
    DxScrollViewModule,
    TitleBarModule,
    DxTabPanelModule,
    DxTabsModule,
    FinanceCreditcollectToPayModule,
    FinanceCreditcollectReminderHistoryModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectComponent],
  exports: [],
})
export class FinanceCreditcollectModule {}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { SideNavOuterToolbarComponent } from './layouts/side-nav-outer-toolbar/side-nav-outer-toolbar.component';

import { CrmClientsComponent } from './pages/crm/crm-clients/crm-clients.component';
import { AnalyticsDashboardComponent } from './pages/analytics/analytics-dashboard/analytics-dashboard.component';
import { AnalyticsSalesDashboardComponent } from './pages/analytics/analytics-sales-report/analytics-sales-report.component';
import { MsalGuard } from '@azure/msal-angular';
import { DocketMailListComponent } from './pages/docket-mail-list/docket-mail-list.component';
import { DocketFileComponent } from './pages/docket-file/docket-file.component';
import { TimeRegistrationRegisterComponent } from './pages/time-registration/time-registration-register/time-registration-register.component';
import { FinanceInvoicesInComponent } from './pages/finance/finance-invoices-in/finance-invoices-in.component';
import { FinanceInvoicesOutComponent } from './pages/finance/finance-invoices-out/finance-invoices-out.component';
import { FinanceRwFollowUpComponent } from './pages/finance/finance-rw-follow-up/finance-rw-follow-up.component';
import { CrmTodoComponent } from './pages/crm/crm-todo/crm-todo.component';
import { FinanceReportingBadPayersComponent } from './pages/finance/finance-reporting-bad-payers/finance-reporting-bad-payers.component';
import { FinanceReportingBadPayersPEBUComponent } from './pages/finance/finance-reporting-bad-payers-pebu/finance-reporting-bad-payers-pebu.component';
import { FinanceReportingCostOverviewComponent } from './pages/finance/finance-reporting-cost-overview/finance-reporting-cost-overview.component';
import { FinanceReportingCostItemOverviewComponent } from './pages/finance/finance-reporting-cost-item-overview/finance-reporting-cost-item-overview.component';
import { FinanceReportingInvoiceOverviewComponent } from './pages/finance/finance-reporting-invoice-overview/finance-reporting-invoice-overview.component';
import { FinanceReportingAmadeusInvoiceOverviewComponent } from './pages/finance/finance-reporting-amadeus-invoice-overview/finance-reporting-amadeus-invoice-overview.component';
import { FinanceReportingSpadelInvoiceOverviewComponent } from './pages/finance/finance-reporting-spadel-invoice-overview/finance-reporting-spadel-invoice-overview.component';
import { FinanceConfigurationInvoiceCategoriesComponent } from './pages/finance/finance-configuration-invoice-categories/finance-configuration-invoice-categories.component';
import { FinanceConfigurationInvoiceLineCategoriesComponent } from './pages/finance/finance-configuration-invoiceline-categories/finance-configuration-invoiceline-categories.component';
import { FinanceConfigurationPricelistComponent } from './pages/finance/finance-configuration-pricelist/finance-configuration-pricelist.component';
import { FinanceConfigurationWorkcodeComponent } from './pages/finance/finance-configuration-workcode/finance-configuration-workcode.component';
import { FinanceConfigurationWorkCodeCategoriesComponent } from './pages/finance/finance-configuration-workcode-categories/finance-configuration-workcode-categories.component';
import { FinanceCostItemBudgetComponent } from './pages/finance/finance-cost-item-budget/finance-cost-item-budget.component';
import { BecameClientOrProspectOnComponent } from './pages/patricia/patricia-clients-and-prospects/patricia-clients-and-prospects.component';
import { PatriciaDocumentValidationComponent } from './pages/patricia/patricia-document-validation/patricia-document-validation.component';
import { ProviderListComponent } from './pages/providers-list/providers-list.component';
import { RenewalInternalOverviewComponent } from './pages/renewal/renewal-internal-overview/renewal-internal-overview.component';
import { RenewalPavisImportExportComponent } from './pages/renewal/renewal-pavis-import-export/renewal-pavis-import-export.component';
import { RenewalPavisSendToPavisComponent } from './pages/renewal/renewal-pavis-send-to-pavis/renewal-pavis-send-to-pavis.component';
import { RenewalPavisInstructionComponent } from './pages/renewal/renewal-pavis-instructions/renewal-pavis-instruction.component';
import { RenewalPavisInstructionLogComponent } from './pages/renewal/renewal-instruction-log/renewal-instruction-log.component';
import { RenewalPavisResponseLogComponent } from './pages/renewal/renewal-response-log/renewal-response-log.component';
import { RenewalConfigurationEventComponent } from './pages/renewal/renewal-configuration-event/renewal-configuration-event.component';
import { TimeRegistrationConfigurationBillingTypesComponent } from './pages/time-registration/time-registration-configuration-billing-types/time-registration-configuration-billing-types.component';
import { TimeRegistrationConfigurationEmployeeTargetComponent } from './pages/time-registration/time-registration-configuration-employee-target/time-registration-configuration-employee-target.component';
import { TimeRegistrationConfigurationTeamTargetComponent } from './pages/time-registration/time-registration-configuration-team-target/time-registration-configuration-team-target.component';
import { AdminPortalLanguageComponent } from './pages/portal-admin/portal-admin-language/portal-admin-language.component';
import { AdminPortalUserComponent } from './pages/portal-admin/portal-admin-user/portal-admin-user.component';
import { AdminPortalTemplateEngineBookmarkComponent } from './pages/portal-admin/portal-admin-template-engine-bookmarks/portal-admintemplate-engine-bookmarks.component';
import { AdminPortalTemplateComponent } from './pages/portal-admin/portal-admin-template-engine-template/portal-admin-template-engine-template.component';
import { AdminPortalTemplateTypeComponent } from './pages/portal-admin/portal-admin-template-engine-template-type/portal-admin-template-engine-template-type.component';
import { AdminPortalTemplateBookmarkComponent } from './pages/portal-admin/portal-admin-template-engine-template-bookmark/portal-admin-template-engine-template-bookmark.component';
import { BudgetConfigurationAdministrationCategoryComponent } from './pages/budget/budget-configuration-administration-category/budget-configuration-administration-category.component';
import { BudgetConfigurationAdministrationSubCategoryComponent } from './pages/budget/budget-configuration-administration-subcategory/budget-configuration-administration-subcategory.component';
import { BudgetConfigurationFigureSalesTargetComponent } from './pages/budget/budget-configuration-figures-sales-target/budget-configuration-figures-sales-target.component';
import { AdministrationCarOverviewComponent } from './pages/administration/administration-car-overview/administration-car-overview.component';
import { AdministrationCarBrandComponent } from './pages/administration/administration-car-brand/administration-car-brand.component';
import { AdministrationCarFuelTypeComponent } from './pages/administration/administration-car-fuelType/administration-car-fuelType.component';
import { AdministrationCarInsuranceCompanyComponent } from './pages/administration/administration-car-insurance-company/administration-car-insurance-company.component';
import { AdministrationCarLeasingCompanyComponent } from './pages/administration/administration-car-leasing-companies/administration-car-leasing-companies.component';
import { AdministrationEmployeeComponent } from './pages/administration/administration-employee/administration-employee.component';
import { AdministrationEmployeeSalariesComponent } from './pages/administration/administration-employee-salaries/administration-employee-salaries.component';
import { AdministrationCountriesComponent } from './pages/administration/administration-countries/administration-countries.component';
import { AdministrationCurrencyComponent } from './pages/administration/administration-currency/administration-currency.component';
import { AdministrationComputerComponent } from './pages/administration/administration-computers/administration-computers.component';
import { AccountFollowUpPatriciaComponent } from './pages/client-portal/client-portal-account-px-follow-up/client-portal-account-px-follow-up.component';
import { ClientPortalCaseTypeMappingComponent } from './pages/client-portal/client-portal-case-type-mapping/client-portal-case-type-mapping.component';
import { ClientPortalInstructionMappingComponent } from './pages/client-portal/client-portal-instructions-mapping/client-portal-instructions-mapping.component';
import { ClientPortalInstructionActionTextComponent } from './pages/client-portal/client-portal-instructions-action-text/client-portal-instructions-action-text.component';
import { ClientPortalInstructionWorkFlowCategoryComponent } from './pages/client-portal/client-portal-instructions-workflow-categories/client-portal-instructions-workflow-categories.component';
import { ClientPortalInstructionResponseTypeComponent } from './pages/client-portal/client-portal-instructions-response-type/client-portal-instructions-response-type.component';
import { ClientPortalInstructionStatusTextComponent } from './pages/client-portal/client-portal-instructions-status-text/client-portal-instructions-status-text.component';
import { ClientPortalCaseSegmentsComponent } from './pages/client-portal/client-portal-segments-case-segments/client-portal-segments-case-segments.component';
import { ClientPortalClientSegmentsComponent } from './pages/client-portal/client-portal-segments-client-segments/client-portal-segments-client-segments.component';
import { CrmConfigurationSegmentationComponent } from './pages/crm/configuration/crm-configuration-segmentation/crm-configuration-segmentation.component';
import { CrmConfigurationFieldStatusComponent } from './pages/crm/configuration/crm-configuration-field-status/crm-configuration-field-status.component';
import { CrmConfigurationProspectActionComponent } from './pages/crm/configuration/crm-configuration-prospect-action/crm-configuration-prospect-action.component';
import { RenewalViewCaseNotImportedInPavisComponent } from './pages/renewal/renewal-views-case-not-imported-in-pavis/renewal-views-case-not-imported-in-pavis.component';
import { FinanceBankAccountTrackComponent } from './pages/finance/finance-bank-account-track/finance-bank-account-track.component';
import { FinanceBankAccountValidationComponent } from './pages/finance/finance-bank-account-validation/finance-bank-account-validation.component';
import { ConversionBasicInfoComponent } from './pages/conversion/case/conversion-case-basic-info/conversion-case-basic-info.component';
import { ConversionCaseDiaryComponent } from './pages/conversion/case/conversion-case-diary/conversion-case-diary.component';
import { ConversionCasePartiesComponent } from './pages/conversion/case/conversion-case-parties/conversion-case-parties.component';
import { ConversionCaseTrmClassComponent } from './pages/conversion/case/conversion-case-trm-class/conversion-case-trm-class.component';
import { CrmConfigurationActionComponent } from './pages/crm/configuration/crm-configuration-actions/crm-configuration-actions.component';
import { CrmConfigurationOriginComponent } from './pages/crm/configuration/crm-configuration-origin/crm-configuration-origin.component';
import { CrmConfigurationPmeComponent } from './pages/crm/configuration/crm-configuration-pme/crm-configuration-pme.component';
import { CrmConfigurationPoleComponent } from './pages/crm/configuration/crm-configuration-pole/crm-configuration-pole.component';
import { permissionsGuard } from './guards/permission-guard';
import { CrmProspectsComponent } from './pages/crm/crm-prospects/crm-prospects.component';
import { TimeRegistrationReportingPersonalComponent } from './pages/time-registration/time-registration-reporting-personal/time-registration-reporting-personal.component';
import { TimeRegistrationReportingTeamComponent } from './pages/time-registration/time-registration-reporting-team/time-registration-reporting-team.component';
import { FeeCalculatorComponent } from './pages/fee-calculator/fee-calculator.component';
import { FeeCalculatorConfigurationBillingMatrixComponent } from './pages/fee-calculator/fee-calculator-configuration/billing-matrix/billing-matrix.component';
import { AdminPortalServiceComponent } from './pages/portal-admin/portal-admin-service/portal-admin-service.component';
import { HomeComponent } from './pages/home/home.component';
import { PatriciaEventDeadlinesComponent } from './pages/patricia/patricia-events-deadlines/patricia-events-deadlines.component';
import { AnalyticsCostFollowUpComponent } from './pages/analytics/analytics-cost-follow-up/analytics-cost-follow-up.component';
import { RenewalPavisInvoiceDetailsComponent } from './pages/renewal/renewal-pavis-invoice-details/renewal-pavis-invoice-details.component';
import { AnalyticsVolumeReportComponent } from './pages/boardroom/boardroom-volume-report/boardroom-volume-report.component';
import { TimeAnalysisComponent } from './pages/boardroom/time-analysis/time-analysis/time-analysis.component';
import { FinanceChargesComponent } from './pages/finance/finance-charges/finance-charges.component';
import { FinanceReportingInvoiceSubsidiesComponent } from './pages/finance/finance-reporting-invoice-subsidies/finance-reporting-invoice-subsidies.component';
import { EpoCommunicationsComponent } from './pages/epo-communications/epo-communications.component';
import { FeeCalculatorWizardComponent } from './pages/fee-calculator/fee-calculator-wizard/fee-calculator-wizard.component';
import { AppPermissions } from './routes/permissions';
import { DataQualityPxPartiesComponent } from './pages/consistency-checks/data-quality/data-quality-px-parties/data-quality-px-parties.component';
import { DataQualityPxDiaryComponent } from './pages/consistency-checks/data-quality/data-quality-px-diary/data-quality-px-diary.component';
import { DataQualityPxAnnuityComponent } from './pages/consistency-checks/data-quality/data-quality-px-annuity/data-quality-px-annuity.component';
import { DataQualityPxNamesComponent } from './pages/consistency-checks/data-quality/data-quality-px-names/data-quality-px-names.component';
import { DataQualityPxActionsComponent } from './pages/consistency-checks/data-quality/data-quality-px-actions/data-quality-px-actions.component';
import { ConsistencyChecksUserLogsComponent } from './pages/consistency-checks/consistency-checks-user-logs/data-quality-user-logs.component';
import { SecurityPxActionsComponent } from './pages/consistency-checks/security/security-px-actions/security-px-actions.component';
import { SecurityPxAnnuityComponent } from './pages/consistency-checks/security/security-px-annuity/security-px-annuity.component';
import { SecurityPxNamesComponent } from './pages/consistency-checks/security/security-px-names/security-px-names.component';
import { SecurityPxPartiesComponent } from './pages/consistency-checks/security/security-px-parties/security-px-parties.component';
import { SecurityPxDiaryComponent } from './pages/consistency-checks/security/security-px-diary/security-px-diary.component';
import { FinanceCreditcollectComponent } from './pages/finance/finance-creditcollect/finance-creditcollect.component';
import { AdminPortalTeamHierarchyComponent } from './pages/portal-admin/portal-admin-team-hierarchy/portal-admin-team-hierarchy.component';
import { BillingMatrixModifyComponent } from './pages/fee-calculator/fee-calculator-configuration/billing-matrix-modify/billing-matrix-modify.component';

const routes: Routes = [
  {
    path: '',
    component: SideNavOuterToolbarComponent,
    children: [
      {
        path: 'time-analysis',
        component: TimeAnalysisComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.STATS_REPORTING],
        },
      },
      {
        path: 'crm-prospects',
        component: CrmProspectsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.PROSPECTION,
            AppPermissions.CRM_BASIC,
          ],
        },
      },
      {
        path: 'crm-clients',
        component: CrmClientsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.PROSPECTION,
            AppPermissions.CRM_BASIC,
          ],
        },
      },
      {
        path: 'crm-todo',
        component: CrmTodoComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.PROSPECTION,
            AppPermissions.CRM_BASIC,
          ],
        },
      },
      {
        path: 'analytics-budget-dashboard',
        component: AnalyticsDashboardComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ANALYTICS_FULL],
        },
      },
      {
        path: 'analytics-sales-report',
        component: AnalyticsSalesDashboardComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'analytics-volume-reporting',
        component: AnalyticsVolumeReportComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.STATS_REPORTING],
        },
      },
      {
        path: 'cost-follow-up',
        component: AnalyticsCostFollowUpComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ANALYTICS_FULL],
        },
      },
      {
        path: 'docket-mail-list',
        component: DocketMailListComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'docket-file',
        component: DocketFileComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'time-registration-register',
        component: TimeRegistrationRegisterComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'time-registration-reporting-personal',
        component: TimeRegistrationReportingPersonalComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'time-registration-reporting-team',
        component: TimeRegistrationReportingTeamComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'finance-charges-v2',
        component: FinanceChargesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.MANAGER,
            AppPermissions.MANAGER,
            AppPermissions.INVOICING_BASIC,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-invoices-in',
        component: FinanceInvoicesInComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.COST,
            AppPermissions.MANAGER,
            AppPermissions.INVOICING_FULL,
            AppPermissions.EXTERNAL,
          ],
        },
      },
      {
        path: 'finance-invoices-out',
        component: FinanceInvoicesOutComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.MANAGER,
            AppPermissions.INVOICING_BASIC,
            AppPermissions.INVOICING_FULL,
            AppPermissions.EXTERNAL,
          ],
        },
      },
      {
        path: 'finance-rw-follow-up',
        component: FinanceRwFollowUpComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.SUBSIDIES_BASIC,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-creditcollect',
        component: FinanceCreditcollectComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-bad-payers',
        component: FinanceReportingBadPayersComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.BADPAYERS_MANAGEMENT,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-bad-payers-pebu',
        component: FinanceReportingBadPayersPEBUComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.BADPAYERS_MANAGEMENT,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-cost-overview',
        component: FinanceReportingCostOverviewComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-cost-item-overview',
        component: FinanceReportingCostItemOverviewComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-invoice-overview',
        component: FinanceReportingInvoiceOverviewComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.INVOICING_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-invoice-subsidies',
        component: FinanceReportingInvoiceSubsidiesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.INVOICING_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-amadeus-invoice-overview',
        component: FinanceReportingAmadeusInvoiceOverviewComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.INVOICING_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-reporting-spadel-invoice-overview',
        component: FinanceReportingSpadelInvoiceOverviewComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.INVOICING_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-configuration-invoice-categories',
        component: FinanceConfigurationInvoiceCategoriesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING_CONFIGURATION,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-configuration-invoiceline-categories',
        component: FinanceConfigurationInvoiceLineCategoriesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING_CONFIGURATION,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-configuration-pricelist',
        component: FinanceConfigurationPricelistComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.FINANCIAL,
            AppPermissions.INVOICING_CONFIGURATION,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-configuration-workcode',
        component: FinanceConfigurationWorkcodeComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.FINANCIAL,
            AppPermissions.WORKCODES_CONFIGURATION,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-configuration-workcode-categories',
        component: FinanceConfigurationWorkCodeCategoriesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.FINANCIAL,
            AppPermissions.WORKCODES_CONFIGURATION,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-bank-account-track',
        component: FinanceBankAccountTrackComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.BANK_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-bank-account-validation',
        component: FinanceBankAccountValidationComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.BANK_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'finance-cost-item-budget',
        component: FinanceCostItemBudgetComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING_REPORTING,
            AppPermissions.INVOICING_FULL,
          ],
        },
      },
      {
        path: 'patricia-client-and-prospects',
        component: BecameClientOrProspectOnComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'patricia-events-deadlines',
        component: PatriciaEventDeadlinesComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'patricia-document-validation',
        component: PatriciaDocumentValidationComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'provider-list',
        component: ProviderListComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'renewals-internal-overview',
        component: RenewalInternalOverviewComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWAL],
        },
      },
      {
        path: 'renewals-pavis-import-export',
        component: RenewalPavisImportExportComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
        },
      },
      {
        path: 'renewals-pavis-send-to-pavis',
        component: RenewalPavisSendToPavisComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
        },
      },
      {
        path: 'renewals-pavis-instructions',
        component: RenewalPavisInstructionComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
        },
      },
      {
        path: 'renewals-pavis-invoice',
        component: RenewalPavisInvoiceDetailsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
        },
      },
      {
        path: 'renewals-pavis-log-instruction-log',
        component: RenewalPavisInstructionLogComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
        },
      },
      {
        path: 'renewals-pavis-log-response-log',
        component: RenewalPavisResponseLogComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
        },
      },
      {
        path: 'renewals-pavis-configuration-event',
        component: RenewalConfigurationEventComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.RENEWALS_CONFIGURATION],
        },
      },
      {
        path: 'time-registration-configuration-billing-type',
        component: TimeRegistrationConfigurationBillingTypesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.TIMEREGISTRATION_CONFIGURATION],
        },
      },
      {
        path: 'time-registration-configuration-employee-target',
        component: TimeRegistrationConfigurationEmployeeTargetComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.TIMEREGISTRATION_MANAGEMENT],
        },
      },
      {
        path: 'time-registration-configuration-team-target',
        component: TimeRegistrationConfigurationTeamTargetComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.TIMEREGISTRATION_MANAGEMENT,
            AppPermissions.TEAMS_MANAGER,
          ],
        },
      },
      {
        path: 'admin-portal-languages',
        component: AdminPortalLanguageComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.GENERAL_MANAGEMENT],
        },
      },
      {
        path: 'admin-portal-user-management',
        component: AdminPortalUserComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ADMIN],
        },
      },
      {
        path: 'admin-portal-team-hierarchy-management',
        component: AdminPortalTeamHierarchyComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ADMIN],
        },
      },
      {
        path: 'admin-portal-service',
        component: AdminPortalServiceComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.GENERAL_MANAGEMENT],
        },
      },
      {
        path: 'admin-portal-template-engine-bookmark',
        component: AdminPortalTemplateEngineBookmarkComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
          ],
        },
      },
      {
        path: 'admin-portal-template-engine-templates',
        component: AdminPortalTemplateComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
          ],
        },
      },
      {
        path: 'admin-portal-template-engine-template-type',
        component: AdminPortalTemplateTypeComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
          ],
        },
      },
      {
        path: 'admin-portal-template-engine-template-bookmark',
        component: AdminPortalTemplateBookmarkComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.INVOICING,
            AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
          ],
        },
      },
      {
        path: 'budget-configuration-administration-category',
        component: BudgetConfigurationAdministrationCategoryComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
        },
      },
      {
        path: 'budget-configuration-administration-subcategory',
        component: BudgetConfigurationAdministrationSubCategoryComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
        },
      },
      {
        path: 'budget-configuration-figures-sales-target',
        component: BudgetConfigurationFigureSalesTargetComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
        },
      },
      {
        path: 'administration-car-overview',
        component: AdministrationCarOverviewComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
        },
      },
      {
        path: 'administration-car-brands',
        component: AdministrationCarBrandComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
        },
      },
      {
        path: 'administration-car-fuel-types',
        component: AdministrationCarFuelTypeComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
        },
      },
      {
        path: 'administration-car-insurance-companies',
        component: AdministrationCarInsuranceCompanyComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
        },
      },
      {
        path: 'administration-car-leasing-companies',
        component: AdministrationCarLeasingCompanyComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
        },
      },
      {
        path: 'administration-employee-employees',
        component: AdministrationEmployeeComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.COMPANY_MANAGEMENT_FULL,
            AppPermissions.EMPLOYEE_MANAGEMENT,
          ],
        },
      },
      {
        path: 'administration-employee-employee-salary',
        component: AdministrationEmployeeSalariesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.COMPANY_MANAGEMENT_FULL,
            AppPermissions.EMPLOYEE_MANAGEMENT,
          ],
        },
      },
      {
        path: 'administration-countries',
        component: AdministrationCountriesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.COMPANY_MANAGEMENT_FULL,
            AppPermissions.COMPANY_MANAGEMENT_BASIC,
          ],
        },
      },
      {
        path: 'administration-currency',
        component: AdministrationCurrencyComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.COMPANY_MANAGEMENT_FULL,
            AppPermissions.COMPANY_MANAGEMENT_BASIC,
          ],
        },
      },
      {
        path: 'administration-computers',
        component: AdministrationComputerComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.COMPANY_MANAGEMENT_FULL,
            AppPermissions.COMPANY_MANAGEMENT_BASIC,
          ],
        },
      },
      {
        path: 'client-portal-account-px-follow-up',
        component: AccountFollowUpPatriciaComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'client-portal-case-type-mapping',
        component: ClientPortalCaseTypeMappingComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL],
        },
      },
      {
        path: 'client-portal-instructions-mapping',
        component: ClientPortalInstructionMappingComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'client-portal-instructions-action-text',
        component: ClientPortalInstructionActionTextComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'client-portal-instructions-categories',
        component: ClientPortalInstructionWorkFlowCategoryComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'client-portal-instructions-response-types',
        component: ClientPortalInstructionResponseTypeComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'client-portal-instructions-status-text',
        component: ClientPortalInstructionStatusTextComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'client-portal-segments-case-segments',
        component: ClientPortalCaseSegmentsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'client-portal-segments-client-segments',
        component: ClientPortalClientSegmentsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.CLIENT_PORTAL,
            AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
          ],
        },
      },
      {
        path: 'conversion-case-basic-info',
        component: ConversionBasicInfoComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
        },
      },
      {
        path: 'conversion-case-diary',
        component: ConversionCaseDiaryComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
        },
      },
      {
        path: 'conversion-case-trm-class',
        component: ConversionCaseTrmClassComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
        },
      },
      {
        path: 'conversion-case-party',
        component: ConversionCasePartiesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
        },
      },
      {
        path: 'crm-configuration-action',
        component: CrmConfigurationActionComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
        },
      },
      {
        path: 'crm-configuration-origin',
        component: CrmConfigurationOriginComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
        },
      },
      {
        path: 'crm-configuration-pme',
        component: CrmConfigurationPmeComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
        },
      },
      {
        path: 'crm-configuration-pole',
        component: CrmConfigurationPoleComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
        },
      },
      {
        path: 'crm-configuration-segmentation',
        component: CrmConfigurationSegmentationComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
        },
      },
      {
        path: 'crm-configuration-prospect-action',
        component: CrmConfigurationProspectActionComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [
            AppPermissions.PROSPECTION,
            AppPermissions.CRM_CONFIGURATION,
          ],
        },
      },
      {
        path: 'crm-configuration-field-status',
        component: CrmConfigurationFieldStatusComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
        },
      },
      {
        path: 'views-cases-not-imported-in-pavis',
        component: RenewalViewCaseNotImportedInPavisComponent,
        canActivate: [permissionsGuard],
      },
      {
        path: 'fee-calculator',
        component: FeeCalculatorComponent,
        //canActivate: [MsalGuard],
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ADMIN],
        },
      },
      {
        path: 'fee-calculator-wizard/:id',
        component: FeeCalculatorWizardComponent,
        //canActivate: [MsalGuard],
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ADMIN],
        },
      },
      {
        path: 'fee-calculator-configuration-billing-matrix',
        component: FeeCalculatorConfigurationBillingMatrixComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ADMIN],
        },
      },
      {
        path: 'fee-calculator-configuration-billing-matrix/modify/:id',
        component: BillingMatrixModifyComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.ADMIN],
        },
      },
      {
        path: 'epo-communications',
        component: EpoCommunicationsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.EPO],
        },
      },
      {
        path: 'data-quality-px-parties',
        component: DataQualityPxPartiesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'data-quality-px-diary',
        component: DataQualityPxDiaryComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'data-quality-px-actions',
        component: DataQualityPxActionsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'security-px-actions',
        component: SecurityPxActionsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'security-px-annuity',
        component: SecurityPxAnnuityComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'security-px-names',
        component: SecurityPxNamesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'security-px-parties',
        component: SecurityPxPartiesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'security-px-diary',
        component: SecurityPxDiaryComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'data-quality-px-names',
        component: DataQualityPxNamesComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'data-quality-px-annuity',
        component: DataQualityPxAnnuityComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'consistency-checks-user-logs',
        component: ConsistencyChecksUserLogsComponent,
        canActivate: [permissionsGuard],
        data: {
          requiredPermissions: [AppPermissions.DATA_QUALITY],
        },
      },
      {
        path: 'home',
        component: HomeComponent,
        //todo should be chnaged to permission guard later
        canActivate: [MsalGuard],
      },
      {
        path: '**',
        redirectTo: '/home',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), BrowserModule],
  providers: [],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}

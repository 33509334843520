<card-analytics
  [titleText]="title"
  [isLoading]="total === null"
  [contentClass]="contentClass"
>
  <div class="total">
    {{ total | currency: "EUR" : "€" : "1.0-0" }}
  </div>
  <div class="detailamount" *ngIf="detailTotal !== 0">
    <span>{{ detailTotal | currency: "EUR" : "€" : "1.0-0" }} {{ detailTitle }}</span>
  </div>
</card-analytics>

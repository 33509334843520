import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFormModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import notify from 'devextreme/ui/notify';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'finance-charges-split-form',
  templateUrl: './finance-charges-split-form.component.html',
  styleUrls: ['./finance-charges-split-form.component.scss'],
})
export class FinanceChargesSplitFormComponent {
  @Input() charge: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  splitAmount: number = 0;

  splitPercent: number = 0;

  constructor() {}

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSplitClick(): void {
    if (this.charge.NonVatAmount == this.splitAmount) {
      notify(
        'Split amount cannot be the same as the original amount',
        'error',
        2000
      );
      return;
    }

    if (this.splitAmount == 0) {
      notify('Split amount cannot be zero', 'error', 2000);
      return;
    }

    this.save.emit(this.splitAmount);
  }

  onChooseSplitPercent(e): void {
    this.splitAmount = this.charge.NonVatAmount * e;

    // Calculate split percent
    this.splitPercent = e;
  }

  onSplitAmountChange(e): void {
    this.splitAmount = e.value;

    // Calculate split percent
    this.splitPercent = this.splitAmount / this.charge.NonVatAmount;
  }

  onSplitPercentChange(e): void {
    this.splitPercent = e.value;

    // Calculate split amount
    this.splitAmount = this.charge.NonVatAmount * this.splitPercent;
  }

  onShown($event: any) {
    this.splitAmount = 0;
  }
}

@NgModule({
  declarations: [FinanceChargesSplitFormComponent],
  exports: [FinanceChargesSplitFormComponent],
  bootstrap: [FinanceChargesSplitFormComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxPopupModule,
    DxButtonModule,
    DxNumberBoxModule,
  ],
})
export class FinanceChargesSplitFormModule {}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormComponent,
  DxFormModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import { InvoiceInDetailsFormModule } from '../../invoice-in/invoice-in-details-form/invoice-in-details-form.component';
import { CostHeader } from 'src/app/types/cost/costheader';
import { CostService } from 'src/app/services/financial/cost/cost.service';
import { PDFPreviewModule } from 'src/app/components/library/pdf-preview/pdf-preview.component';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'finance-cost-header-edit',
  templateUrl: './finance-cost-header-edit.component.html',
  styleUrls: ['./finance-cost-header-edit.component.scss'],
})
export class FinanceCostHeaderEditComponent implements OnInit {
  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent;
  @Input() charge: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<CostHeader>();

  @Output() visibleChange = new EventEmitter<boolean>();

  blobFile: any;

  description: string;

  invoice: any;

  currencies: any;

  invoiceHeaderCategories: DataSource;

  clients: any;

  cases: any;

  url = environment.CalystaApiBaseURL + 'api/';

  maxDate = new Date();

  statusSource: any;

  employees: any;

  providers: any;

  costItemCategories: any;

  constructor(private costService: CostService) {}

  ngOnInit(): void {
    this.currencies = new DataSource({
      store: AspNetData.createStore({
        key: 'currencyId',
        loadUrl: `${this.url}Currency/lookup`,
      }),
      sort: 'CurrencyLabel',
    });

    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'caseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });

    this.statusSource = new DataSource({
      store: AspNetData.createStore({
        key: 'costStatusId',
        loadUrl: `${this.url}Costs/CostStatus/lookup`,
      }),
      sort: 'StatusLabel',
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'employeeId',
        loadUrl: `${this.url}Employee/lookup`,
      }),
      sort: ['FirstName', 'LastName'],
    });

    this.providers = new DataSource({
      store: AspNetData.createStore({
        key: 'providerId',
        loadUrl: `${this.url}Provider/lookup`,
      }),
      sort: 'Name',
    });

    this.costItemCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'CostItemId',
        loadUrl: `${this.url}Costs/CostItem/lookup`,
      }),
      sort: 'Label',
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(this.visible);
  }

  onSaveClick(): void {
    this.save.emit(this.invoice);
  }

  onClickRedirectToInvoiceCategory(): void {
    //redirect to page named provider-list in another tab
    window.open('/#/finance-configuration-invoice-categories', '_blank');
  }

  onFileChanged = (e: any) => {
    this.blobFile = e.value[0];
  };

  onShow() {
    this.costService.getCostHeader(this.charge.ChargeId).subscribe((data) => {
      this.invoice = data.CostHeader;

      this.blobFile = null;
      if (
        this.invoice.DocumentLink != null &&
        this.invoice.DocumentLink != ''
      ) {
        this.costService
          .downloadInvoiceDocument(this.invoice.CostHeaderId)
          .subscribe({
            next: (e) => {
              this.blobFile = e;
            },
            error: (err) => {
              const errorMessage: string | null =
                err?.error?.Errors?.GeneralErrors[0];
              notify(errorMessage ?? 'Unknown error', 'error', 3000);
            },
            complete: () => {},
          });
      }

      this.description = this.invoice.Description;
    });
  }

  onDescriptionChanged = (e: any) => {
    this.invoice.Description = e.value;
  };

  getTotalAmount = (): number => {
    return this.invoice ? this.invoice.Amount : 0;
  };

  getVatAmount = (): number => {
    return this.invoice ? this.invoice.VatAmount : 0;
  };

  getNonVatAmount = (): number => {
    return this.invoice ? this.invoice.NonVatAmount : 0;
  };

  updateAmounts(e: { amount: number; vat: number; total: number }): void {
    this.invoice.Amount = e.total;
    this.invoice.NonVatAmount = e.amount;
    this.invoice.VatAmount = e.vat;
  }

  onClickRedirectToProvider(): void {
    //redirect to page named provider-list in another tab
    window.open('/#/provider-list', '_blank');
  }
}

@NgModule({
  declarations: [FinanceCostHeaderEditComponent],
  exports: [FinanceCostHeaderEditComponent],
  bootstrap: [FinanceCostHeaderEditComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    InvoiceInDetailsFormModule,
    DxLookupModule,
    DxTextBoxModule,
    PDFPreviewModule,
    DxTextAreaModule,
    DxNumberBoxModule,
  ],
})
export class FinanceCostHeaderEditModule {}

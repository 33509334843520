import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncodedTime } from 'src/app/types/timeregistration/EncodedTimeByWeek';
import { Observable, map } from 'rxjs';
import { Analytics } from 'src/app/types/timeregistration/Analytics';
import { MarginReporting } from 'src/app/types/timeregistration/MarginReporting';
import { InvoiceReporting } from 'src/app/types/timeregistration/InvoiceReporting';
import { TeamKPI } from 'src/app/types/timeregistration/TeamKPI';
import { TeamInvolvement } from 'src/app/types/timeregistration/TeamInvolvement';
import { BilledTimeTrack } from 'src/app/types/timeregistration/BilledTimeTrack';
import { BilledTimeByClient } from 'src/app/types/timeregistration/BilledTimeByClient';
import { BilledTimeByCaseType } from 'src/app/types/timeregistration/BilledTimeByCaseType';
import { TimeReporting } from 'src/app/types/timeregistration/TimeReporting';
import { EncodedTimeTrack } from 'src/app/types/timeregistration/EncodedTimeTrack';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class TimeReportingService {
  url = apiUrl + 'api/timeregistration';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  getEncodedTime(StartDate: string, EndDate: string, EmployeeId: number) {
    let params = new HttpParams()
      .set('StartDate', StartDate)
      .set('EndDate', EndDate)
      .set('EmployeeId', EmployeeId);

    return this.http
      .get<any>(`${this.url}/reporting/EncodedTime`, {
        params,
      })
      .pipe<EncodedTime[]>(map((data) => data.TimeEncoded));
  }

  getAnalytics(EmployeeId: number) {
    let params = new HttpParams().set('EmployeeId', EmployeeId);

    return this.http
      .get<any>(`${this.url}/reporting/Analytics`, {
        params,
      })
      .pipe<Analytics[]>(map((data) => data.TimeData));
  }

  getMarginReporting(TeamId: number, Year: number) {
    let params = new HttpParams().set('TeamId', TeamId).set('Year', Year);

    return this.http
      .get<any>(`${this.url}/TeamReporting/GetMarginReporting`, {
        params,
      })
      .pipe<MarginReporting[]>(map((data) => data.Data));
  }

  getTimeReporting(TeamId: number, Year: number) {
    let params = new HttpParams().set('TeamId', TeamId).set('Year', Year);

    return this.http
      .get<any>(`${this.url}/TeamReporting/TeamTimeTrack`, {
        params,
      })
      .pipe<TimeReporting[]>(map((data) => data.Data));
  }

  getInvoiceReporting(TeamId: number, MonthNumber: number) {
    let params = new HttpParams()
      .set('TeamId', TeamId)
      .set('MonthNumber', MonthNumber);

    return this.http
      .get<any>(`${this.url}/TeamReporting/GetInvoiceReporting`, {
        params,
      })
      .pipe<InvoiceReporting[]>(map((data) => data.InvoiceData));
  }

  getKPI(TeamId: number, Year: number) {
    let params = new HttpParams().set('TeamId', TeamId).set('Year', Year);

    return this.http.get<TeamKPI>(`${this.url}/TeamReporting/GetKPI`, {
      params,
    });
  }

  getPrincipalTeam() {
    return this.http
      .get<any>(`${this.url}/TeamReporting/GetPrincipalTeam`)
      .pipe<TeamInvolvement>(map((data) => data.Data));
  }

  getBilledTimeByClient(
    EmployeeId: number,
    Year: number,
    Month: number
  ): Observable<BilledTimeByClient[]> {
    let params = new HttpParams()
      .set('EmployeeId', EmployeeId)
      .set('Year', Year)
      .set('Month', Month);

    return this.http
      .get<any>(`${this.url}/Reporting/BilledTimeByClient`, {
        params,
      })
      .pipe<BilledTimeByClient[]>(map((data) => data.Data));
  }

  getBilledTimeByCaseType(
    EmployeeId: number,
    Year: number,
    Month: number
  ): Observable<BilledTimeByCaseType[]> {
    let params = new HttpParams()
      .set('EmployeeId', EmployeeId)
      .set('Year', Year)
      .set('Month', Month);

    return this.http
      .get<any>(`${this.url}/Reporting/BilledTimeByCaseType`, {
        params,
      })
      .pipe<BilledTimeByCaseType[]>(map((data) => data.Data));
  }

  getBillableTimeInPeriod(
    EmployeeId: number,
    StartDate: string,
    EndDate: string
  ) {
    let params = new HttpParams()
      .set('EmployeeId', EmployeeId)
      .set('StartDate', StartDate)
      .set('EndDate', EndDate);

    return this.http.get<any>(`${this.url}/Reporting/BillableTimeInPeriod`, {
      params,
    });
  }

  getBilledTimeInPeriod(
    EmployeeId: number,
    StartDate: string,
    EndDate: string
  ) {
    let params = new HttpParams()
      .set('EmployeeId', EmployeeId)
      .set('StartDate', StartDate)
      .set('EndDate', EndDate);

      console.log(StartDate);

    return this.http.get<any>(`${this.url}/Reporting/BilledTimeInPeriod`, {
      params,
    });
  }

  getBillableTimeToBeInvoicedInPeriod(
    EmployeeId: number,
    StartDate: string,
    EndDate: string
  ) {
    let params = new HttpParams()
      .set('EmployeeId', EmployeeId)
      .set('StartDate', StartDate)
      .set('EndDate', EndDate);

    return this.http.get<any>(
      `${this.url}/Reporting/BillableTimeToBeInvoicedInPeriod`,
      {
        params,
      }
    );
  }

  getBilledTimeTrack(EmployeeId: number, startDate: string, endDate: string) {
    let params = new HttpParams()
      .set('EmployeeId', EmployeeId)
      .set('StartDate', startDate)
      .set('EndDate', endDate);

    return this.http
      .get<any>(`${this.url}/Reporting/BilledTimeTrack`, {
        params,
      })
      .pipe<BilledTimeTrack[]>(map((data) => data.Data));
  }

  getEncodedTimeTrack(EmployeeId: number, startDate: string, endDate: string) {
    let params = new HttpParams()
      .set('EmployeeId', EmployeeId)
      .set('StartDate', startDate)
      .set('EndDate', endDate);

    return this.http
      .get<any>(`${this.url}/Reporting/EncodedTimeTrack`, {
        params,
      })
      .pipe<EncodedTimeTrack[]>(map((data) => data.Data));
  }

  getSalesBudgetByTeam(TeamId: number, Year: number) {
    let params = new HttpParams().set('TeamId', TeamId).set('Year', Year);

    return this.http.get<any>(`${this.url}/TeamReporting/GetBudget`, {
      params,
    });
  }
}

<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <div class="dashboard-header">
      <toolbar-analytics
        titleText="Time Dashboard"
        [panelItems]="analyticsPanelItems"
        [selectedItems]="[4]"
        (selectionChanged)="selectionChange($event)"
      />
      <dx-select-box
        *ngIf="this.PermissionSvc.hasPermission('Manager')"
        [dataSource]="Employees"
        [value]="CurrentEmployeeId"
        displayExpr="FullName"
        valueExpr="EmployeeId"
        (onValueChanged)="onEmployeeChanged($event)"
        [searchEnabled]="true"
      />
    </div>
    <div class="cards grey">
      <time-followup-card
        style="margin-left: 20px"
        title="Billable hours encoded"
        summaryPhrase="billable hours worked in this period"
        [realized]="BillableHoursInPeriod"
      />
      <time-followup-card
        title="Hours invoiced in this period."
        [realized]="BilledTimeInPeriod"
      />
      <time-followup-card
        title="Billed time invoiced in €"
        [realized]="BilledTimeInEuroInPeriod"
      />
      <time-followup-card
        title="Billable time to be invoiced"
        [realized]="BillableTimeToBeInvoicedInPeriod"
        summaryPhrase="Billable hours encoded but not yet invoiced."
      />
    </div>

    <div class="halfwidth-reporting">
      <div class="card">
        <dx-tab-panel [selectedIndex]="0" id="tabPanel">
          <dxi-item title="Invoiced Time" id="1">
            <time-realized-to-target-graph
              [TitlePeriod]="ReportingPeriod"
              [ArgumentField]="GraphArgumentField"
              [EmployeeId]="this.CurrentEmployeeId"
              [StartDate]="this.currentStartDate"
              [EndDate]="this.currentEndDate"
          /></dxi-item>
          <dxi-item title="Encoded Time" id="2">
            <encoded-time-graph
              [TitlePeriod]="ReportingPeriod"
              [ArgumentField]="GraphArgumentField"
              [EmployeeId]="this.CurrentEmployeeId"
              [StartDate]="this.currentStartDate"
              [EndDate]="this.currentEndDate"></encoded-time-graph>
          </dxi-item>
        </dx-tab-panel>
      </div>
      <div class="TargetCard card">
        <dx-tabs (onItemClick)="onTabClick($event)" [selectedIndex]="0">
          <dxi-item badge="General" id="1" />
          <dxi-item badge="Client" id="2" />
          <dxi-item badge="CaseType" id="3" />
        </dx-tabs>
        <!-- General Tab -->
        <div *ngIf="currentReportingTabId == 1"></div>
        <!--  Client Tab -->
        <div *ngIf="currentReportingTabId == 2">
          <client-reporting
            [Employee]="this.Employee"
            [ReportingYear]="this.Year"
          />
        </div>
        <!-- Case Type Tab -->
        <div *ngIf="currentReportingTabId == 3">
          <casetype-reporting
            [Employee]="this.Employee"
            [ReportingYear]="this.Year"
          />
        </div>
      </div>
    </div>

    <!-- Div for the WIP Reporting. -->
    <div class="halfwidth-reporting">
      <div class="card" style="height: 50vh">
        <wip-reporting [EmployeeId]="this.CurrentEmployeeId" />
      </div>
    </div>

    <!-- <time-analytics-pivot/> -->

    <!-- <time-encodation-track-card/> -->
  </div>
</dx-scroll-view>

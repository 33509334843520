import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular';
import { RegistrationService } from 'src/app/services/time-registration/registration.service';
import { TimeRegistrationRegisterNewModule } from '../../time-registration/time-registration-register-new/time-registration-register-new.component';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'time-registration-shortcut',
  templateUrl: './time-registration-shortcut.component.html',
  styleUrls: [],
})
export class TimeRegistrationShortcutComponent {
  isAddPopupOpened: boolean = false;

  constructor(public registrationService: RegistrationService) {}

  onButtonClick() {
    this.isAddPopupOpened = true;
  }

  onCloseAdd = (e) => {
    this.isAddPopupOpened = false;
  };

  onSaveClickNew = (e) => {
    this.isAddPopupOpened = false;

    this.registrationService
      .insertRegistration(e.registration)
      .subscribe((data) => {
        if (e.detailsList.length > 0) {
          e.detailsList.forEach((d: any) => {
            this.registrationService
              .insertDetails(d, data.RegistrationId)
              .subscribe({
                next: (x) => {},
                error: (err) => {
                  const errorMessage: string | null =
                    err?.error?.Errors?.GeneralErrors[0];
                  notify(errorMessage ?? 'Unknown error', 'error', 3000);
                },
                complete: () => {},
              });
          });
        }
      });
  };
}

@NgModule({
  imports: [CommonModule, DxButtonModule, TimeRegistrationRegisterNewModule],
  declarations: [TimeRegistrationShortcutComponent],
  exports: [TimeRegistrationShortcutComponent],
})
export class TimeRegistrationShortcutModule {}

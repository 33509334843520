import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingMatrix } from 'src/app/types/feecalculator/BillingMatrix';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class FeeCalculatorConfigurationService {
  url = apiUrl + 'api/feecalculator';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  GetBillingMatrix(id: number) {
      const params = new HttpParams().set('id', id.toString());

      return this.http.get<any>(`${this.url}/BillingMatrix`, { params });
    }

  updateBillingMatrix(billingMatrix: BillingMatrix) {
    return this.http.put(`${this.url}/BillingMatrix`, billingMatrix);
  }
}

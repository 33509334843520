<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Users Hierarchy" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="false"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Hierarchy'"
          height="auto"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form [(formData)]="formData">
          <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
            <dxi-item
              dataField="EmployeesId"
              editorType="dxTagBox"
              [editorOptions]="{
                dataSource: employees,
                displayExpr: 'FullName',
                valueExpr: 'EmployeeId',
                searchEnabled: true,
                validationMessageMode: 'always',
                value: formData.EmployeesId,
                onValueChanged: onEmployeesChanged,
              }"
            >
              <dxo-label text="Members" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="MasterName"
        caption="Employee"
        sortIndex="0"
        sortOrder="asc"
      />
      <dxi-column dataField="Id" caption="Id" [visible]="false" />
      <dxi-column type="buttons">
        <dxi-button icon="edit" [onClick]="onEdit" />
      </dxi-column>

      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <dx-data-grid
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [dataSource]="details.data.Children"
          [wordWrapEnabled]="true"
        >
          <dxi-column dataField="EmployeeName" caption="Employee" />
          <dxi-column dataField="CanSee" caption="Can See" />
        </dx-data-grid>
      </div>
    </dx-data-grid>
  </div>
</dx-scroll-view>

import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTabPanelModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components/library/titlebar/titlebar.component';
import { SelectionChangedEvent } from 'devextreme/ui/tab_panel';
import { SelectionChangedEvent as DxGridSelectionChanged } from 'devextreme/ui/data_grid';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'prepare-reminder-contacts-popup',
  templateUrl: './prepare-reminder-contacts-popup.component.html',
  styleUrls: ['./prepare-reminder-contacts-popup.component.scss'],
})
export class PrepareReminderContactsPopupComponent {
  @ViewChild('recipientDataGrid', { static: false })
  recipientDataGrid: DxDataGridComponent;
  @ViewChild('ccDataGrid', { static: false }) ccDataGrid: DxDataGridComponent;

  @Input() visible: boolean = false;

  @Input() invoiceHeaderId: number | undefined;
  @Input() clientId: number | undefined;

  @Input() confirmLoading = false;
  @Output() confirmLoadingChange = new BehaviorSubject<boolean>(false);

  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() confirm = new EventEmitter<{
    recipientId: number;
    ccIds: number[];
  }>();

  recipientsDataSource: DataSource;
  ccDataSource: DataSource;

  selectedRecipients: any[] = [];

  caseRoleTypes: DataSource;

  titleInfo = 'Choose one recipient';

  selectedRecipient: any = null;

  recipientMail: string = '';

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {
    this.caseRoleTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'RoleTypeId',
        loadUrl: `${this.url}Patricia/CaseRoleType/Lookup`,
        onBeforeSend: (method, ajaxOptions) => {},
      }),
    });
    this.onRecipientSelectionChange = this.onRecipientSelectionChange.bind(this);
  }

  onShow() {
    let sourceUrl = '';

    if (this.invoiceHeaderId !== undefined && this.invoiceHeaderId !== null) {
      sourceUrl = `${this.url}CreditCollect/Invoice/${this.invoiceHeaderId}/Contacts`;
    } else if (this.clientId !== undefined && this.clientId !== null) {
      sourceUrl = `${this.url}CreditCollect/Client/${this.clientId}/Contacts`;
    }

    this.recipientsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'NameId',
        loadUrl: sourceUrl,
        onLoaded: (items) => {
          this.selectItemsForRecipient(items);
        },
        onBeforeSend: (method, ajaxOptions) => {},
      }),
    });

    this.ccDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'NameId',
        loadUrl: sourceUrl,
        onLoaded: (items) => {
          this.selectItemsForCC(items);
        },
        onBeforeSend: (method, ajaxOptions) => {},
      }),
    });
  }

  onHide() {
    this.visibleChange.emit(false);
  }

  onTabSelectionChanged($event: SelectionChangedEvent) {
    var tabPanel = $event.component;
    var selectedTab = tabPanel.option('selectedIndex');

    if (selectedTab === 0) {
      this.titleInfo = 'Choose one recipient';
    } else if (selectedTab === 1) {
      this.titleInfo = 'Choose mails for cc';
    } else if (selectedTab === 2) {
      this.titleInfo = 'Summary';
    }
  }

  // Handle checkbox change
  onRecipientSelectionChange(event: DxGridSelectionChanged) {
    if (event.selectedRowKeys.length  === 0) {
      this.selectedRecipient = null
      this.selectedRecipients = [];
    } else if(event.selectedRowKeys.length === 1) {
      this.selectedRecipient = event.selectedRowsData[0];
    } else if (event.selectedRowKeys.length > 1) {
      this.selectedRecipient = event.selectedRowsData[1];
      this.selectedRecipients = this.selectedRecipients.splice(1,1);
    }
  }

  getRecipientData = () => {
    return this.selectedRecipient?.Email ?? '';
  };
  

  getCCData = () => {
    if (this.ccDataGrid === undefined) {
      return '';
    }
    var data = this.ccDataGrid.instance.getSelectedRowsData();

    if (data?.length === 0) {
      return '';
    }

    var emails = data.map((item: any) => item.Email);
    return emails.join('; ');
  };

  selectItemsForRecipient(items: any[]) {
    var item = items.filter((item: any) => {
      //convert string to string[] with delimiter ,
      var roleTypes = item.RoleType.split(',').map((x: string) => x);
      //check if roleTypes contains the name 'Account Address'
      return roleTypes.includes('Account Address');
    });

  //    this.onCheckboxChange(item[0], { value: true });
  }

  selectItemsForCC(items: any[]) {
    var rowIds = items
      .filter((item: any) => {
        //convert string to string[] with delimiter ,
        var roleTypes = item.RoleType.split(',').map((x: string) => x.trim());
        //check if roleTypes contains the name 'Account Address'
        return roleTypes.includes('Account Address Copy');
      })
      .map((item: any) => item.NameId);

    this.ccDataGrid.instance.selectRows(rowIds, false);
  }

  onConfirm() {
    this.confirmLoadingChange.next(true);
    var recipientId = this.selectedRecipient?.NameId;
    var ccIds = this.ccDataGrid.instance
      .getSelectedRowsData()
      .map((item: any) => item.NameId);

    this.confirm.emit({ recipientId, ccIds });
  }
}

@NgModule({
  declarations: [PrepareReminderContactsPopupComponent],
  exports: [PrepareReminderContactsPopupComponent],
  bootstrap: [PrepareReminderContactsPopupComponent],
  imports: [
    BrowserModule,
    DxDataGridModule,
    DxScrollViewModule,
    DxPopupModule,
    TitleBarModule,
    DxTabPanelModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxButtonModule,
    DxLoadPanelModule,
  ],
})
export class PrepareReminderContactsPopupModule {}

import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { DxScrollViewModule, DxTabPanelModule } from 'devextreme-angular';
import { TitleBarModule } from '../../../components/library/titlebar/titlebar.component';
import { FeeCalculatorWizardOrganizationModule } from './fee-calculator-wizard-organization/fee-calculator-wizard-organization.component';
import { FeeCalculatorWizardServicesModule } from './fee-calculator-wizard-services/fee-calculator-wizard-services.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';

@Component({
  selector: 'fee-calculator-wizard',
  templateUrl: './fee-calculator-wizard.component.html',
  styleUrls: ['./fee-calculator-wizard.component.scss'],
})
export class FeeCalculatorWizardComponent {
  organizationCompleted: boolean = false;
  servicesCompleted: boolean = false;

  quoteId: number | null = null;
  quote: any;

  constructor(
    private route: ActivatedRoute,
    private feeCalcSvc: FeeCalculatorService
  ) {
    this.feeCalcSvc.GetQuote(this.route.snapshot.params['id']).subscribe({
      next: (x) => {
        this.quote = x;

        if (this.quote.ClientId) {
          this.organizationCompleted = true;
        }
      },
    });
  }

  organizationStepCompleted = (completed: boolean) => {
    this.organizationCompleted = completed;
  };

  serviceStepCompleted = (completed: boolean) => {
    this.servicesCompleted = completed;
  };

  onClickGoBack = (e) => {
    if (e.itemIndex == 0) {
      window.location.href = '/#/fee-calculator';
    }
  };
}

@NgModule({
  exports: [],
  declarations: [FeeCalculatorWizardComponent],
  providers: [],
  imports: [
    CommonModule,
    DxTabPanelModule,
    TitleBarModule,
    FeeCalculatorWizardOrganizationModule,
    FeeCalculatorWizardServicesModule,
    DxScrollViewModule,
  ],
})
export class FeeCalculatorWizardModule {}

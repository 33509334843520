<dx-data-grid
  [(dataSource)]="dataSource"
  (onRowUpdated)="onRowUpdated($event)"
  >
  <dxo-editing
  mode="popup"
  [allowAdding]="true"
  [allowUpdating]="true"
  [allowDeleting]="true"
>
</dxo-editing>
  <dxi-column dataField="InstructionTypeId" caption="Instruction" [allowEditing]="false">
    <dxo-lookup
      [dataSource]="InstructionTypesDataSource.store()"
      valueExpr="InstructionId"
      displayExpr="InstructionLabel"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="NextEventSchemeId" caption="Next WF Step"></dxi-column>
</dx-data-grid>

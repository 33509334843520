<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Credit & collect" />
    <div class="separator"></div>
    <!-- Tabs for Credit Collect -->
    <dx-tab-panel [(selectedIndex)]="selectedIndex" style="height: 100%">
      <!-- Tab 1 -->
      <dxi-item title="Invoices In">
        <div class="content" style="height: 80vh">
          <finance-creditcollect-topay />
        </div>
      </dxi-item>

      <!-- Tab 2 -->
      <dxi-item title="Invoices Out">
        <div class="content" style="height: 80vh">
          <finance-creditcollect-amountdue />
        </div>
      </dxi-item>

      <dxi-item title="Reminder History">
        <div class="content" style="height: 80vh">
          <finance-creditcollect-reminder-history />
        </div>
      </dxi-item>
    </dx-tab-panel>
  </div>
</dx-scroll-view>

import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { TitleBarModule } from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';
import { FinanceReportingAmadeusInvoiceOverviewDetailGridModule } from 'src/app/components/finance/finance-reporting/amadeus-invoice-overview/details/finance-reporting-amadeus-overview-grid-details.component';

@Component({
  selector: 'finance-reporting-amadeus-invoice-overview',
  templateUrl: './finance-reporting-amadeus-invoice-overview.component.html',
  styleUrls: ['./finance-reporting-amadeus-invoice-overview.component.scss'],
})
export class FinanceReportingAmadeusInvoiceOverviewComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: DataSource;

  hasSelectedRows = true;

  url: string;

  constructor(private invoiceService: CalystaAPIInvoiceService) {
    this.url = invoiceService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/amadeusinvoiceoverview`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Amadeus Invoices');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'AmadeusInvoices.xlsx'
        );
      });
    });
  }

  getSummaryOfSelectedRows = () => {
    this.dataGrid.instance.beginCustomLoading('Downloading...');
    const selectedRowsIds = this.dataGrid.instance.getSelectedRowKeys();

    this.invoiceService
      .getAmadeusInvoicesSummary(selectedRowsIds.map((x) => x.toString()))
      .pipe(
        finalize(() => {
          this.dataGrid.instance.endCustomLoading();
        })
      )
      .subscribe({
        next: (x) => {
          //download file
          saveAs(
            new Blob([x], { type: 'application/octet-stream' }),
            'Amadeus Invoices.xlsx'
          );
        },
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
      });
  };

  onSelectionChanged(e: SelectionChangedEvent) {
    this.hasSelectedRows = !(e.selectedRowKeys.length > 0);
  }

  clearSelection = () => {
    this.dataGrid.instance.clearSelection();
  };
}

@NgModule({
  declarations: [FinanceReportingAmadeusInvoiceOverviewComponent],
  exports: [],
  providers: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    DxCheckBoxModule,
    TitleBarModule,
    DxScrollViewModule,
    FinanceReportingAmadeusInvoiceOverviewDetailGridModule,
  ],
})
export class TimeRegistrationRegisterModule {}

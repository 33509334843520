import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import { CrmProspectDetailsFormModule } from '../crm-prospect-details/crm-prospect-details.component';
import { CrmToDoMarkAsDoneModule } from '../crm-todo-mark-as-done-form/crm-todo-mark-as-done-form.component';
import { CRMService } from 'src/app/services/prospection/crm.service';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'crm-prospect-todo',
  templateUrl: './crm-prospect-todo.component.html',
  styleUrls: ['./crm-prospect-todo.component.scss'],
})
export class CrmProspectTodoComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() titleText = '';

  @Input() visible = false;

  @Input() prospectId: number;

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  includeDone: boolean = false;

  todoToMarkAsDone: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  dataSource: DataSource;

  prospects: DataSource;

  actions: DataSource;

  employees: DataSource;

  isMarkAsDonePopupOpened: boolean = false;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(private crmService: CRMService) {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.prospects = new DataSource({
      store: AspNetData.createStore({
        key: 'ProspectId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Prospection/CRM/Prospect/Lookup`,
      }),
      sort: 'ProspectName',
    });

    this.actions = new DataSource({
      store: AspNetData.createStore({
        key: 'ActionId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Prospection/Action/Lookup`,
      }),
      sort: 'Name',
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Employee/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });
  }

  onHide() {
    this.dataSource = null;

    this.visibleChange.emit(false);
  }

  onShow() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProspectActionId',
        loadUrl: `${this.url}Prospection/Todo/${this.prospectId}`,
        insertUrl: `${this.url}Prospection/Todo`,
        updateUrl: `${this.url}Prospection/Todo`,
        deleteUrl: `${this.url}Prospection/Todo`,
        onInserting: (values) => {
          //set prospectId to this.prospectId
          values.prospectId = this.prospectId;
        },
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
            if (this.includeDone) {
              ajaxOptions.data.includeDone = this.includeDone;
            }
          }
        },
      }),
    });
  }

  markAsDone = (e) => {
    this.todoToMarkAsDone = { ...e.row.data };
    this.isMarkAsDonePopupOpened = e;
  };

  isMarkableAsDone = (e) => {
    return e.row.data.Done === false;
  };

  onTodoMarkAsDoneComplete = (e) => {
    this.crmService
      .markTodoAsDone(
        e.ProspectActionId,
        e.IsOutcomePositive,
        e.OutcomeComments,
        e.DoneDate
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
  };

  onCloseMarkAsDone = (e: boolean) => {
    this.isMarkAsDonePopupOpened = e;
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  includeDoneChanged = (e) => {
    this.includeDone = e.value;
    this.dataGrid.instance.getDataSource().reload();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.parentType === 'dataRow' && e.dataField === 'Comments') {
      e.editorName = 'dxTextArea';
    }
  }
}

@NgModule({
  declarations: [CrmProspectTodoComponent],
  exports: [CrmProspectTodoComponent],
  bootstrap: [CrmProspectTodoComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    DxScrollViewModule,
    DxDataGridModule,
    CrmToDoMarkAsDoneModule,
    CrmProspectDetailsFormModule,
    DxButtonModule,
  ],
})
export class CrmProspectTodoModule {}

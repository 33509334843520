import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { RenewalPavisConfigurationEventEditModule } from 'src/app/components/renewal/renewal-pavis/renewal-pavis-configuration-event-edit/renewal-pavis-configuration-event-edit.component';
import { RenewalPavisConfigurationEventNewModule } from 'src/app/components/renewal/renewal-pavis/renewal-pavis-configuration-event-new/renewal-pavis-configuration-event-new.component';
import { RenewalService } from 'src/app/services/renewal/renewal.service';
import { environment } from 'src/environments/environment';
import { InstructionGridModule } from './instruction-grid/instruction-grid.component';
import { EditingStartEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'renewal-configuration-event',
  templateUrl: './renewal-configuration-event.component.html',
  styleUrls: ['./renewal-configuration-event.component.scss'],
})
export class RenewalConfigurationEventComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  instructionDataSource: DataSource;

  dataSource: any;
  caseTypes: any;

  url: string;
  apiUrl = environment.CalystaApiBaseURL + 'api/';

  isAddPopupOpened: boolean = false;

  isEditPopupOpened: boolean = false;

  editedRow: any;

  constructor(private renewalService: RenewalService) {
    this.url = this.renewalService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'EventConfigurationId',
        loadUrl: `${this.url}/EventConfiguration`,
        insertUrl: `${this.url}/EventConfiguration`,
        updateUrl: `${this.url}/EventConfiguration`,
        deleteUrl: `${this.url}/EventConfiguration`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.caseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${this.apiUrl}Case/CaseType/Lookup`,
      }),
      sort: 'CaseTypeLabel',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onEditingStart($event: EditingStartEvent) {
    this.editedRow = { ...$event.data };
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onSaveClickNew(e: any): void {
    this.dataSource.store().insert(e);
  }

  onSaveClickEdit(e: any): void {
    this.dataSource.store().update(e.EventConfigurationId, this.editedRow);

    var rowIndex = this.dataGrid.instance.getRowIndexByKey(
      e.EventConfigurationId
    );
    var row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
    if (!row) return;
    Object.assign(row, this.editedRow);
    this.dataGrid.instance.repaintRows([rowIndex]);
  }

  addNew() {
    this.isAddPopupOpened = true;
  }

  onEdit = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isEditPopupOpened = true;
  };

  onCloseAdd = (e: boolean) => {
    this.isAddPopupOpened = e;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupOpened = e;
  };

  onInstructionsUpdated(instructions: DataSource) {
    this.instructionDataSource = instructions;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    RenewalPavisConfigurationEventNewModule,
    RenewalPavisConfigurationEventEditModule,
    InstructionGridModule,
  ],
  declarations: [RenewalConfigurationEventComponent],
  exports: [],
})
export class RenewalConfigurationEventModule {}

<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="RW Follow Up" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onRowPrepared)="onRowPrepared($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'RW Follow Up'"
          height="auto"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item
            dataField="CaseId"
            [colSpan]="2"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: cases,
              displayExpr: 'CaseNumber',
              valueExpr: 'CaseId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="Case" />
          </dxi-item>
          <dxi-item
            dataField="InvoiceHeaderId"
            [colSpan]="2"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: invoiceHeaders,
              displayExpr: 'InvoiceNumber',
              valueExpr: 'InvoiceHeaderId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="Invoice Header" />
          </dxi-item>
          <dxi-item
            dataField="BudgetedAmount"
            [colSpan]="2"
            [editorOptions]="{
              format: {
                type: 'currency',
                precision: 2
              }
            }"
          >
            <dxo-label text="Budgeted amount" />
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'RW Follow Up'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new follow up"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>

        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="never" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="CaseId" caption="File Number" width="15%">
        <dxo-lookup
          [dataSource]="cases.store()"
          displayExpr="CaseNumber"
          valueExpr="CaseId"
          searchEnabled="true"
          validationMessageMode="always"
        />
      </dxi-column>
      <dxi-column dataField="RwReference" caption="Réf RW" width="10%" />
      <dxi-column
        dataField="RwAccordDePres"
        caption="Accord De Prestation"
        dataType="date"
        width="10%"
        [sortIndex]="3"
        sortOrder="desc"
      />
      <dxi-column dataField="Client" caption="Client" alignment="left" />
      <dxi-column dataField="InvoiceHeaderId" caption="Invoice NR" width="10%">
        <dxo-lookup
          [dataSource]="invoiceHeaders.store()"
          displayExpr="InvoiceNumber"
          valueExpr="InvoiceHeaderId"
          searchEnabled="true"
          validationMessageMode="always"
        />
      </dxi-column>
      <dxi-column
        dataField="BudgetedAmount"
        caption="Budgeted Amount"
        width="7%"
      >
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="AmountInvoiced"
        caption="Amount Invoice"
        width="7%"
        [sortIndex]="4"
        sortOrder="asc"
      >
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="VatAmountInvoiced"
        caption="Vat Amount Invoiced"
        width="7%"
      >
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="InvoiceIsPayed"
        caption="Invoice is payed"
        [sortIndex]="1"
        sortOrder="asc"
        [visible]="false"
      />
      <dxi-column
        dataField="VatIsPayed"
        caption="Vat is payed"
        [sortIndex]="2"
        sortOrder="asc"
        [visible]="false"
      />
      <dxi-column
        dataField="IsInvoiced"
        caption="Is Invoiced"
        [sortIndex]="0"
        sortOrder="asc"
        [visible]="false"
      />
      <dxi-column
        dataField="InvoiceHeaderId"
        caption="InvoiceHeader Id"
        [visible]="false"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 150]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceRwFollowUp"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>

<dx-data-grid
  [dataSource]="detailDataSource"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowUpdated)="onRowUpdated($event)"
  (onRowRemoved)="onRowRemoved($event)"
  (onRowInserted)="onRowInserted($event)"
  (onRowInserting)="onRowInserting($event)"
  (onInitNewRow)="onInitNewRow($event)"
  [columnAutoWidth]="true"
  [remoteOperations]="false"
>
  <dxo-scrolling mode="virtual" />
  <dxo-paging [pageSize]="500" />
  <dxo-editing
    mode="popup"
    [allowUpdating]="true"
    [allowAdding]="true"
    [allowDeleting]="true"
  >
    <dxo-form [colCount]="2">
      <dxi-item itemType="group" caption="Parameters" colSpan="2" colCount="2">
        <dxi-item dataField="CostLineId" [visible]="false" />
        <dxi-item
          dataField="CaseId"
          editorType="dxLookup"
          [colSpan]="1"
          [editorOptions]="{
            dataSource: caseLookup,
            displayExpr: 'CaseDetailedDescription',
            valueExpr: 'CaseId',
            searchMode: 'contains',
            searchExpr: 'CaseDetailedDescription',
            minsSearchLength: 2,
            showDataBeforeSearch: false,
            showClearButton: true,
            searchEnabled: true,
            validationMessageMode: 'always'
          }"
        >
          <dxo-label text="Case" />
        </dxi-item>
        <dxi-item
          dataField="EmployeeId"
          [editorOptions]="{
            dataSource: employeeLookup,
            displayExpr: 'FullName',
            valueExpr: 'EmployeeId',
            searchMode: 'contains',
            searchExpr: 'FullName',
            minsSearchLength: 2,
            showDataBeforeSearch: true,
            searchEnabled: true,
            validationMessageMode: 'always'
          }"
        />
        <dxi-item
          dataField="CostItemId"
          editorType="dxLookup"
          [colSpan]="1"
          [editorOptions]="{
            dataSource: costItemCategoryLookup,
            displayExpr: 'Label',
            valueExpr: 'CostItemId',
            searchMode: 'contains',
            searchExpr: 'Label',
            minsSearchLength: 2,
            showDataBeforeSearch: false,
            searchEnabled: true,
            validationMessageMode: 'always',
          }"
        >
          <dxo-label text="Cost Item" />
        </dxi-item>
        <dxi-item
          dataField="BudgetPeriodStart"
          editorType="dxDateBox"
          [editorOptions]="{
            type: 'date',
            dateSerializationFormat: 'yyyy-MM-dd',
            displayFormat: 'dd/MM/yyyy',
            validationMessageMode: 'always',
            acceptCustomValue: false,
            openOnFieldClick: true
          }"
        >
          <dxo-label text="Budget From" />
        </dxi-item>
        <dxi-item
          dataField="WorkCodeId"
          editorType="dxLookup"
          [colSpan]="1"
          [editorOptions]="{
            dataSource: workcodeLookup,
            displayExpr: 'WorkCodeFullDescription',
            valueExpr: 'WorkCodeId',
            searchMode: 'contains',
            searchExpr: 'WorkCodeFullDescription',
            minsSearchLength: 2,
            showDataBeforeSearch: false,
            showClearButton: true,
            searchEnabled: true,
            validationMessageMode: 'always'
          }"
        >
          <dxo-label text="WorkCode" />
        </dxi-item>
        <dxi-item
          dataField="BudgetPeriodEnd"
          editorType="dxDateBox"
          [editorOptions]="{
            type: 'date',
            dateSerializationFormat: 'yyyy-MM-dd',
            displayFormat: 'dd/MM/yyyy',
            validationMessageMode: 'always',
            acceptCustomValue: false,
            openOnFieldClick: true
          }"
        >
          <dxo-label text="Budget Until" />
        </dxi-item>
      </dxi-item>

      <dxi-item
        itemType="group"
        colCount="2"
        colSpan="2"
        caption="Financial Details"
      >
        <dxi-item dataField="LineDescription" [colSpan]="2">
          <dxo-label text="Description" />
        </dxi-item>
        <dxi-item
          dataField="NonVatAmount"
          editorType="dxNumberBox"
          [editorOptions]="{
            validationMessageMode: 'always',
            format: { precision: 2, type: 'fixedPoint' }
          }"
        >
          <dxo-label text="Amount" />
        </dxi-item>
        <dxi-item
          dataField="VatRate"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: vatSelectList,
          }"
        >
          <dxo-label text="VAT (%)" />
          <dxi-validation-rule type="required" message="VAT (%) is required" />
        </dxi-item>
        <dxi-item
          dataField="VatAmount"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            format: { precision: 2, type: 'fixedPoint' }
          }"
        >
          <dxo-label text="VAT" />
        </dxi-item>
        <dxi-item
          dataField="Amount"
          editorType="dxNumberBox"
          [editorOptions]="{
            validationMessageMode: 'always',
            format: { precision: 2, type: 'fixedPoint' }
          }"
        >
          <dxo-label text="Amount (incl. VAT)" />
        </dxi-item>
      </dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxi-column dataField="CostLineId" caption="ID" [visible]="false">
    <dxo-form-item [visible]="false" />
  </dxi-column>
  <dxi-column
    dataField="EmployeeId"
    caption="Employee"
    calculateDisplayValue="EmployeeName"
  >
    <dxo-lookup
      [dataSource]="employeeLookup"
      displayExpr="FullName"
      valueExpr="EmployeeId"
      searchEnabled="true"
    />
  </dxi-column>
  <dxi-column
    dataField="CaseId"
    caption="Case"
    [setCellValue]="onCaseChanged"
    calculateDisplayValue="CaseNumber"
  />
  <dxi-column
    dataField="WorkCodeId"
    caption="Workcode"
    [setCellValue]="onWorkcodeChanged"
    calculateDisplayValue="WorkCodeLabel"
  />
  <dxi-column dataField="CostItemId" [visible]="false" caption="Cost Item" />
  <dxi-column
    dataField="LineDescription"
    caption="Description"
    dataType="text"
  />
  <dxi-column
    dataField="NonVatAmount"
    caption="excl.VAT"
    [setCellValue]="onNonVatAmountChanged"
  >
    <dxo-format type="fixedPoint" [precision]="2" />
  </dxi-column>
  <dxi-column dataField="VatAmount" caption="VAT">
    <dxo-format type="fixedPoint" [precision]="2" />
  </dxi-column>
  <dxi-column dataField="Amount" caption="Total">
    <dxo-format type="fixedPoint" [precision]="2" />
  </dxi-column>
  <dxi-column
    dataField="VatRate"
    caption="VAT(%)"
    [setCellValue]="onVatRateChanged"
  />
  <dxi-column
    dataField="BudgetPeriodStart"
    caption="Budget From"
    dataType="date"
    [visible]="false"
  />
  <dxi-column
    dataField="BudgetPeriodEnd"
    caption="Budget Until"
    dataType="date"
    [visible]="false"
  />

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridInvoiceInDetailsForm"
  />
</dx-data-grid>
